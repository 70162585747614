import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import HelperClients from '../../helper/Clients';
import Cal from '../../helper/Calendar';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';
import HelperEmpty from '../../helper/Empty';
import Navigation from '../../components/Navigation';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import InputText from '../../components/Form/input-text';
import InputInvited from '../../components/Form/invited';
import InfoData from '../../components/Form/info';
import ModalClients from '../../components/Modals/clients';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsGroupsNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: lang.t('clients:page.clientgroup'),
      back: global.currentPage,
      name: '',
      value: '',
      invited: [],
      error: [false, false],
      sessions: global.sessions,
      recurring: global.recurring,
      id: '',
      item: null,
      editing: false,
      exp1: false,
      exp2: false,
      h1: 20,
      h2: 20,
      showModalDelete: false,
      showModalClients: false,
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Client Group - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('recurringLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      if(arr[arr.length-1] !== 'new') {
        var item = null
        for(var group of global.clientGroups) {
          if(group.id === arr[arr.length-1]) {
            item = group
          }
        }
        var back = global.currentPage
        if(global.linkCli !== '' && global.linkCli.indexOf('group') === -1) {
          back = global.linkCli
        }
        this.setState({
          id: arr[arr.length-1],
          item: item,
          title: lang.t('clients:page.clientgroup'),
          back: back,
        })
        this.setupExisting(arr[arr.length-1])
      }
    }
  }


  setupExisting(id) {
    var name = lang.t('clients:page.clientgroup')
    var ids = []
    var sessions = []
    var rec = []
    for(var item of global.clientGroups) {
      if(item.id === id) {
        name = item.data.name
        for(var cl of item.data.clients) {
          ids.push(cl)
        }
      }
    }
    for(var item2 of global.sessions) {
      if((item2.data.invitees === undefined || item2.data.invitees.length === 0) && item2.data.group) {
        if(item2.data.group && Moment(item2.data.date, 'DD/MM/YYYY HH:mm') > Moment() && Moment(item2.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(15, 'days')) {
          if(item2.data.groups === undefined) {
            //sessions.push(item2)
          } else {
            if(item2.data.groups.length > 0) {
              for(var gr of item2.data.groups) {
                if(gr === id) {
                  sessions.push(item2)
                }
              }
            } else {
              sessions.push(item2)
            }
          }
        }
      }
    }
    for(var item3 of global.recurring) {
      if((item3.data.invitees === undefined || item3.data.invitees.length === 0) && item3.data.group) {   
        if(item3.data.groups === undefined) {
          //rec.push(item3)
        } else {
          if(item3.data.groups.length > 0) {
            for(var gr2 of item3.data.groups) {
              if(gr2 === id) {
                rec.push(item3)
              }
            }
          } else {
            rec.push(item3)
          }
        }
      }
    }
    global.linkCli = '/clients/groups/group/'+id
    this.setState({
      name: name,
      invited: ids,
      value: name,
      sessions: sessions,
      recurring: rec,
    })
    
  }


  toggleAcc1() {
    var height = 20
    if(!this.state.exp1) {
      height = this.el1.clientHeight+30
    }
    this.setState({
      h1: height,
      exp1: !this.state.exp1,
    })
  }


  toggleAcc2() {
    var height = 20
    if(!this.state.exp2) {
      height = this.el2.clientHeight+30
    }
    this.setState({
      h2: height,
      exp2: !this.state.exp2,
    })
  }



  // Client update stuff ------------------------------------------------------------



  checkCreate() {
    this.setState({error: [false, false]})
    var tmp = [false, false]
    if(this.state.value === '') {
      tmp[0] = true
    }
    if(this.state.invited.length === 0) {
      tmp[1] = true
    }
    this.setState({error: tmp})
    if(!tmp[0] && !tmp[1]) {
      this.createClientGroup()
    }
  }


  createClientGroup() {
    if(this.state.id === '') {
      Firebase.database().ref('/groups/'+global.uid).push({
        name: this.state.value,
        clients: this.state.invited,
      }).then(() => {
        global.message = lang.t('messaging:message.clientgroupcreated')
        this.props.history.push(ROUTES.CLIENTSGROUPS)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/groups/'+global.uid+'/'+this.state.id).update({
        name: this.state.value,
        clients: this.state.invited,
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.clientgroupupdated'));
        this.setState({
          editing: false
        })
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  getClientName(id) {
    var label = lang.t('common:label.deletedclient')
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name+' ('+lang.t('clients:label.inactive')+')'
      }
    }
    return label
  }


  getClient(id) {
    var client = null
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        client = item2
      }
    }
    return client
  }


  removeClient(item) {
    var tmp = this.state.invited
    for(var i=0; i<this.state.invited.length; i++) {
      if(tmp[i] === item) {
        tmp.splice(i, 1)
      }
    }
    this.setState({
      invited: tmp
    })
  }


  cancelEdit() {
    this.setState({
      editing: false,
      value: this.state.item.data.name,
      invited: this.state.item.data.clients
    })
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalClients: false,
    })
  }


  selectClients(items) {
    this.hideModals()
    this.setState({
      invited: items,
    })
  }


  deleteGroup() {
    this.hideModals()
    Firebase.database().ref('/groups/'+global.uid+'/'+this.state.id).remove()
    for(var item1 of global.sessions) {
      if(item1.data.groups !== undefined && Moment(item1.data.timestamp, 'X') > Moment()) {
        if(item1.data.groups.length > 0) {
          for(var i=0; i<item1.data.groups.length; i++) {
            if(item1.data.groups[i] === this.state.id) {
              item1.data.groups.splice(i, 1)
            }
          }
          Firebase.database().ref('/sessions/'+global.uid+'/'+item1.id).update({
            groups: item1.data.groups
          })
        }
      }
    }
    for(var item2 of global.recurring) {
      if(item2.data.groups !== undefined) {
        if(item2.data.groups.length > 0) {
          for(var i2=0; i2<item2.data.groups.length; i2++) {
            if(item2.data.groups[i2] === this.state.id) {
              item2.data.groups.splice(i2, 1)
            }
          }
          Firebase.database().ref('/recurring/'+global.uid+'/'+item2.id).update({
            groups: item2.data.groups
          })
        }
      }
    }
    global.message = lang.t('messaging:message.clientgroupdeleted')
    this.props.history.push(ROUTES.CLIENTSGROUPS)
  }



  // Display stuff ------------------------------------------------------------



  getSessionName(item) {
    var label = lang.t('modals:public1.groupsession')
    if(item.data.client !== '') {
      label = item.data.client
    }
    return label
  }


  renderSessions() {
    if(this.state.sessions.length === 0) {
      return (
        <div className="empty small mt-20" style={{height: 160}} ref={(el1) => {this.el1 = el1}}>
          {HelperEmpty.renderImage('calendar')}
          <h4 style={{whiteSpace: 'pre-wrap'}}>{lang.t('clients:empty.nosessions14days')}</h4>
        </div>
      )
    } else {
      var list = this.state.sessions
      list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div className="mt-10" ref={(el1) => {this.el1 = el1}}>
          {list.map(item => (
            <div className="list bare" key={item.id}>
              <Link to={'/calendar/group-session/'+item.id}>
                <div className="icon primary">
                  <div className="inner group"></div>
                </div>
                <div className="main">
                  <h4>{this.getSessionName(item)}</h4>
                  <p>{Cal.getSpecialDate(item)}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderRecurring() {
    if(this.state.recurring.length === 0) {
      return (
        <div className="empty small mt-20" style={{height: 160}} ref={(el2) => {this.el2 = el2}}>
          {HelperEmpty.renderImage('calendar')}
          <h4>{lang.t('clients:empty.norecurringsessionsyet')}</h4>
        </div>
      )
    } else {
      var list = this.state.recurring
      return (
        <div className="mt-10" ref={(el2) => {this.el2 = el2}}>
          {list.map(item => (
            <div className="list bare" key={item.id}>
              <Link to={'/calendar/recurring-templates/group/'+item.id}>
                <div className="icon primary">
                  <div className="inner group"></div>
                </div>
                <div className="main">
                  <h4>{this.getSessionName(item)}</h4>
                  <p>{Moment(item.data.timestamp, 'X').locale(lang.language).format('dddd')}, {Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderContent() {
    if(this.state.id !== '' && this.state.item !== null) {
      if(this.state.editing) {
        return (
          <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                <InputText label={lang.t('clients:form.groupname')+"*"} value={this.state.value} error={this.state.error[0]} errormsg={lang.t('clients:error.groupname')} onChange={(event) => this.setState({value: event})}/>
                <InputInvited type={'private'} clients={this.state.invited} error={this.state.error[1]} clickClients={() => this.setState({showModalClients: true})} clickRemove={(event) => this.removeClient(event)}/>
                <div className="sv-30"></div>
                <button className="btn primary mb-20" onClick={() => this.checkCreate()}>{lang.t('clients:button.updateclientgroup')}</button>
                <button className="btn tertiary close width-12" onClick={() => this.cancelEdit()}>{lang.t('common:button.cancel')}</button>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id='editclientgroup'/>
              </div>
            </div>
          </div>
          <Header title={lang.t('clients:page.clientgroup')} link={this.state.back}/>
          <Navigation active='clients' />
          <ModalClients show={this.state.showModalClients} title={lang.t('modals:clients.title.selectclients')} button={lang.t('modals:clients.button.confirmselection')} multiple={true} selected={this.state.invited} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectClients(event)}/>
        </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-sidebar">
              <div className="col-6 mt-20">
                <div className="box">
                  <InfoData label={lang.t('clients:label.name')} value={this.state.item.data.name}/>
                  <div className="attendance clientlist">
                    <label>{lang.t('clients:label.clients')}</label>
                    {this.state.item.data.clients.map(item => (
                      <div className="client small static" key={item}>
                        {HelperClients.getImage(this.getClient(item))}
                        <p>{this.getClientName(item)}</p>
                        <div className="clear"></div>
                      </div>
                    ))}
                  </div>
                  <div className="sv-30"></div>
                  <button className="btn primary mb-20" onClick={() => this.setState({editing: true})}>{lang.t('clients:button.editclientgroup')}</button>
                  <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>{lang.t('clients:button.deletethisclientgroup')}</button>
                </div>
              </div>
              <div className="col-6 mt-20">
                <div className="sidebar">
                  <div className={this.state.exp1 ? 'accordion expanded mb-50' : 'accordion mb-50 a1'} style={{height: this.state.h1}}>
                    <h3 className="handle" onClick={() => this.toggleAcc1()}>{lang.t('clients:label.exclusivesessions')}</h3>
                    {this.renderSessions()}
                  </div>
                  <div className={this.state.exp2 ? 'accordion expanded mb-50' : 'accordion mb-50'} style={{height: this.state.h2}}>
                    <h3 className="handle" onClick={() => this.toggleAcc2()}>{lang.t('clients:label.exclusiverecurringsessions')}</h3>
                    {this.renderRecurring()}
                  </div>
                  
                </div>
              </div>
              <div className="clear sv-80"></div>
            </div>
            <Header title={lang.t('clients:page.clientgroup')} link={this.state.back}/>
            <Navigation active='clients' />
            <ModalMessageSimple type='deleteclientgroup' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteGroup()}/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
                <div className="box">
                <InputText label='Group name*' value={this.state.value} error={this.state.error[0]} errormsg={lang.t('clients:error.groupname')} onChange={(event) => this.setState({value: event})}/>
                <InputInvited type={'private'} clients={this.state.invited} error={this.state.error[1]} clickClients={() => this.setState({showModalClients: true})} clickRemove={(event) => this.removeClient(event)}/>
                <div className="sv-30"></div>
                <button className="btn primary" onClick={() => this.checkCreate()}>{lang.t('clients:button.createclientgroup')}</button>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id='newclientgroup'/>
              </div>
            </div>
            <div className="clear sv-80"></div>
          </div>
          <Header title={lang.t('clients:page.newclientgroup')} link={this.state.back}/>
          <Navigation active='clients' />
          <ModalClients show={this.state.showModalClients} title={lang.t('modals:clients.title.selectclients')} button={lang.t('modals:clients.button.confirmselection')} multiple={true} selected={this.state.invited} limit={false} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectClients(event)}/>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div>
          {this.renderContent()}
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='clientgroups-locked' type='simple'/>
          </div>
          <Header title={lang.t('clients:page.clientgroup')} link={ROUTES.CLIENTS}/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','modals','messaging'])(withRouter(withAuthorization(condition)(ClientsGroupsNewPage)));