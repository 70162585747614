import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import Connector from '../../data/Connector';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperBusiness from '../../helper/Business';
import Navigation from '../../components/Navigation';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import InputDate from '../../components/Form/input-date';
import InputToggle from '../../components/Form/toggle';
import InputRadio from '../../components/Form/radio';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import HeaderForm from '../../components/Headers/form';
import HeaderFormTabs from '../../components/Headers/form-tabs';
import ListEmpty from '../../components/List/empty';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalMessageDouble from '../../components/Modals/message-double';
import ModalAvatar from '../../components/Modals/avatar';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: ['', '', '', '', '', ''],
      file: null,
      fileDisplay: null,
      error: false,
      mode: 'invite',
      invite: 'form',
      showMore: false,
      create: true,
      email: '',
      errorEmail: false,
      user: '',
      showModalCreate: false,
      showModalRestricted: false,
      showModalAvatar: false,
      valid: false,
      uid: global.uid,
      imageType: 'avatar',
      avatar: '',
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'New Client - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    if(global.adminClients.length === 0) {
      Connector.loadAdminClients()
    }

    var rand = parseInt(Math.random()*global.avatars.length)
    this.setState({
      avatar: global.avatars[rand]
    })

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
  }


  configureData() {
    this.setState({
      uid: global.uid
    })
  }



  // Client update stuff ------------------------------------------------------------



  updateValues(val, pos) {
    var tmp = this.state.values
    tmp[pos] = val
    this.setState({
      values: tmp,
    });
  }


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay: URL.createObjectURL(file)
    })
  }


  setAvatar(item) {
    this.setState({
      avatar: item
    })
    this.hideModals()
  }


  checkCreate() {
    this.setState({error: false})
    var tmp = false
    var nocreate = false
    if(this.state.values[1] === '' || this.state.values[1].indexOf('@') === -1 || this.state.values[1].indexOf('.') === -1) {
      nocreate = true
    }
    if(this.state.values[0] === '') {
      tmp = true
    }
    if((this.state.values[2] === '' || this.state.values[2] === '0412345678') && this.state.values[1] === '') {
      nocreate = true
    }
    this.setState({error: tmp})
    if(!tmp) {
      if(this.state.create && nocreate) {
        this.setState({showModalCreate: true})
      } else {
        this.createClient()
      }
    }
  }


  createClient() {
    global.badgeLocked = true
    var obj = Firebase.database().ref('/clients/'+global.uid).push()
    var birth = '01/01/1900'
    var height = 0
    var restricted = false
    if(this.state.values[3] !== '' && this.state.values[3] !== 'Invalid Date' && this.state.valid) {
      birth = this.state.values[3]
      if(Moment(birth, 'DD/MM/YYYY').isAfter(Moment().add(-18, 'years'))) {
        restricted = true
      }
    }
    if(this.state.values[4] !== '') {
      height = parseInt(this.state.values[4])
      if(global.userLbs) {
        var ar = this.state.values[4].split(".")
        var h1 = parseInt((ar[0])*12)*2.54
        var h2 = 0
        if(ar.length > 1) {
          h2 = parseInt(ar[1])*2.54
        }
        height = parseInt(h1+h2)
      }
    }

    var email = this.state.values[1].replace(' ', '')
    var phone = this.state.values[2].replace('.' ,'').replace('-' ,'').replace(' ' ,'').replace('e' ,'')

    if(this.state.create && !restricted) {
      var business = global.userBusiness
      var name = global.userName
      if(global.userBusiness === "") {
        business = global.userName
      }
      if(global.userBusiness !== "" && global.userBusiness !== global.userName) {
        var ar2 = global.userName.split(' ')
        name = ar2[0]+" "+lang.t('clients:label.from')+" "+global.userBusiness
      }
      var callFunction1 = Firebase.functions().httpsCallable('sendClientInvite');
      callFunction1({email: this.state.values[1], name: name, user: global.uidUser, business: business, phone: global.spacePin, link: global.uid}).then(function(result) {})

      Firebase.database().ref('/activity/'+global.uid).push({
        type: 'invite',
        data: this.state.values[1],
        date: Moment().format('DD/MM/YYYY HH:mm')
      })
    }

    if(!restricted) {
      if((phone !== '' && phone !== '0412345678') || (this.state.values[1] !== '')) {
        Firebase.database().ref('/connect/'+obj.key).update({
          phone: phone,
          name: global.userName,
          client: obj.key,
          space: global.uid,
          email: email,
        })
      }
    }

    if(global.loc !== '') {
      for(var loc of global.locations) {
        if(loc.id === global.loc) {
          var tmp = []
          if(loc.data.clients !== undefined) {
            tmp = loc.data.clients
          }
          tmp.push(obj.key)
          Firebase.database().ref('/locations/'+global.uid+'/'+loc.id).update({
            clients: tmp
          })
        }
      }
    }

    var avatar = this.state.avatar
    if(this.state.imageType !== 'avatar') {
      avatar = ''
    }

    obj.set({
      name: this.state.values[0],
      email: email,
      phone: phone,
      birth: birth,
      height: height,
      goal: this.state.values[5],
      uid: '',
      image: '',
      avatar: avatar,
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
    }).then(() => {
      global.message = lang.t('messaging:message.clientcreated')
      window.Intercom("update", {"Clients": global.clients.length})
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'client');
      }, 1000);
      if(!restricted) {
        this.props.history.push(ROUTES.CLIENTS)
      } else {
        this.setState({ showModalRestricted: true })
      }
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(this.state.imageType === 'image') {
      if(this.state.file !== null) {
        const sr = Firebase.storage().ref()
        const fr = sr.child('images/clients/'+global.uid+'/'+obj.key+'.jpg')
        fr.put(this.state.file).then(() => {
          Firebase.database().ref('/clients/'+global.uid+'/'+obj.key).update({
            image: 'images/clients/'+global.uid+'/'+obj.key+'.jpg',
            imageDate: Moment().format('DD/MM/YYYY HH:mm')
          })
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
    }

    //var callFunction2 = Firebase.functions().httpsCallable('sendMailchimp');
    //callFunction2({email: "test@testi.test", fname: "Hans", lname: "Test", api: "355e92dcd676867834dfb1dfbc9f2998-us6", prefix: "us6", list: "6fde64ded8"}).then(function(result) {})
  }
  
  
  
  // Modals ------------------------------------------------------------



  renderModalCreate() {
    if(this.state.showModalCreate) {
      var phone = this.state.values[2].replace('.' ,'').replace('-' ,'').replace(' ' ,'').replace('e' ,'')
      var type = 'newclient1'
      if(this.state.values[1] === '' && (phone === '' || phone === '0412345678')) {
        type = 'newclient2'
      }
      return (
        <ModalMessageDouble type={type} show={this.state.showModalCreate} onHide={() => this.hideModals()} clickMainButton={() => this.continueCreate()} clickSecondaryButton={() => this.hideModals()}/>
      )
    }
  }


  continueCreate() {
    this.hideModals()
    this.createClient()
  }


  hideModals() {
    this.setState({
      showModalCreate: false,
      showModalAvatar: false,
    })
  }


  hideModalRestricted() {
    this.setState({
      showModalRestricted: false
    })
    this.props.history.push(ROUTES.CLIENTS)
  }



  // Display stuff ------------------------------------------------------------



  renderInfo() {
    if(this.state.create) {
      return (
        <div className="infobox mb-30">
          <h5>{lang.t('clients:form.info.title1')}</h5>
          <p>{lang.t('clients:form.info.text1a')}<br/>&nbsp;</p>
          <h5>{lang.t('clients:form.info.title2')}: {global.spacePin}</h5>
          <p>{lang.t('clients:form.info.text2a')} <Link className="link" to={ROUTES.ADMINSETTINGS}>{lang.t('clients:form.info.text2b')}</Link></p>
        </div>
      )
    }
  }


  renderMoreOptions() {
    if(this.state.showMore) {
      return (
        <div>
          <button className="btn tertiary small width-12 sub" onClick={() => this.setState({showMore: false})}>{lang.t('clients:button.hidemoreoptions')}</button>
          <div className="sv-20"></div>
          <InputDate label={lang.t('clients:form.dob')} value={Moment(this.state.values[3], 'DD/MM/YYYY').format('YYYY-MM-DD')} format='DD/MM/YYYY' onChange={(event) => this.updateValues(event, 3)}/>
          <InputNumber label={lang.t('clients:label.height')+" ("+(global.spaceLbs ? 'ft' : 'cm')+")"} value={this.state.values[4]} onChange={(event) => this.updateValues(event, 4)}/>
          <InputText label={lang.t('clients:form.clientstrainingfocus')} value={this.state.values[5]} onChange={(event) => this.updateValues(event, 5)}/>
        </div>
      )
    } else {
      return (
        <button className="btn tertiary small width-12 add" onClick={() => this.setState({showMore: true})}>{lang.t('clients:button.showmoreoptions')}</button>
      )
    }
  }


  renderImage() {
    if(this.state.file === null) {
      if(this.state.imageType === 'avatar') {
        return (
          <div className={'avatar '+this.state.avatar}></div>
        )
      } else if(this.state.imageType === '') {
        return <div className="avatar nophoto"></div>
      } else {
        return (
          <div className="avatar"><p>✎</p></div>
        )
      }
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}> </div>
      )
    }
  }


  renderImageSelect() {
    if(this.state.imageType === 'avatar') {
      return <button className="btn tertiary small mb-20" onClick={() => this.setState({showModalAvatar: true})}>{lang.t('clients:button.selectgalleryimage')}</button>
    } else if(this.state.imageType === 'image') {
      return (
        <div className="form-item">
          <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className={HelperBusiness.getRoleIncl('') ? 'content form-sidebar withtabs' : 'content form-sidebar'}>
            <div className="col-6 mt-20">
              <div className="box">
                <div className="avatar-container">
                  {this.renderImage()}
                </div>
                <InputRadio label='Image' value={this.state.imageType} values={[{name: lang.t('clients:form.gallery'), value: 'avatar'}, {name: lang.t('clients:form.photo'), value: 'image'}, {name: lang.t('clients:form.noimage'), value: ''}]} clickElement={(event) => this.setState({imageType: event})}/>
                {this.renderImageSelect()}
                <InputText label={lang.t('clients:label.name')+"*"} value={this.state.values[0]} error={this.state.error} errormsg={lang.t('clients:error.name')} onChange={(event) => this.updateValues(event, 0)}/>
                <InputNumber label={lang.t('clients:label.mobile')} value={this.state.values[2]} onChange={(event) => this.updateValues(event, 2)}/>
                <InputText label={lang.t('clients:label.email')} value={this.state.values[1]} onChange={(event) => this.updateValues(event, 1)}/>
                <div className="sv-10"></div>
                <InputToggle label={lang.t('clients:form.inviteyourclienttoconnect')} value={this.state.create} onChange={(event) => this.setState({create: event})} text={lang.t('clients:form.inviteyourclienttoconnect.text')}/>
                {this.renderMoreOptions()}
                <div className="clear sv-30"></div>
                <button className="btn primary edit" onClick={() => this.checkCreate()}>{lang.t('clients:button.addclient')}</button>
              </div>
            </div>

            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id={this.state.create ? 'newclient' : 'newclient-noemail'}/>
                {this.renderInfo()}
                <p className="small mb-20">{lang.t('clients:label.directlink')}:<br/>ptmate.me/{global.uid}/signup</p>
                <p className="small"><Link to={'/clients/qrcode'} className="btn tertiary small" style={{float:'left'}}>View your QR Code</Link></p>
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <div className="btn-news" onClick={() => window.Intercom('startTour', '320210')}>Show me around</div>
          {HelperBusiness.getRoleIncl('') ? <HeaderFormTabs title={lang.t('clients:title.newclient')} link={global.currentPage} type='clients' active='add'/> : <HeaderForm title={lang.t('clients:title.newclient')} link={global.currentPage}/>}
          <Navigation active='clients' />
          {this.renderModalCreate()}
          <ModalMessageSimple type='clientrestricted' show={this.state.showModalRestricted} onHide={() => this.hideModalRestricted()} clickMainButton={() => this.hideModalRestricted()}/>
          <ModalAvatar show={this.state.showModalAvatar} onHide={() => this.hideModals()} clickElement={(event) => this.setAvatar(event)}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='newclient-locked' type='simple'/>
          </div>
          <HeaderForm title={lang.t('clients:title.newclient')} link={global.currentPage}/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','messaging'])(withRouter(withAuthorization(condition)(ClientsNewPage)));