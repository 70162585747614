import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import HelperClients from '../../helper/Clients';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form-tabs';
import ListEmpty from '../../components/List/empty';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import InputDate from '../../components/Form/input-date';
import InputDatetime from '../../components/Form/datetime';
import InputRadio from '../../components/Form/radio';
import InputToggle from '../../components/Form/toggle';
import Select from '../../components/Form/select';
import InputDuration from '../../components/Form/duration';
import InputInvited from '../../components/Form/invited';
import DataInfo from '../../components/Form/info';
import Loader from '../../components/Form/loader';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import ModalClients from '../../components/Modals/clients';
import ModalItems from '../../components/Modals/items';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewRecGroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      clients: [],
      dur: 30,
      preview: true,
      name: '',
      date: Moment().startOf('week').format('DD/MM/YYYY HH:mm'),
      hour: '00',
      mins: '00',
      cdur: 75,
      showMax: false,
      max: 0,
      error: [false, false],
      interval: 7,
      weekday: 0,
      start: 0,
      loading: false,
      loadingRemove: false,
      maxNum: 0,
      private: '',
      selected: [],
      invited: [],
      group: [],
      memberships: [],
      showBook: false,
      dayBook: 1,
      dayBookCust: '',
      showBookCancel: false,
      hourBookCancel: 1,
      hourBookCancelCust: '',
      showMore: false,
      trainerId: global.uidUser,
      trainerName: global.userName,
      location: global.loc,
      link: '',
      desc: '',
      avail: false,
      showEnd: false,
      dateEnd: Moment().add(1, 'months').format('DD/MM/YYYY'),
      staff: global.userStaff,
      trainers: [],
      locations: [],
      editing: false,
      showModalClients: false,
      showModalGroups: false,
      showModalMemberships: false,
      showModalDelete: false,
      showModalUpdate: false,
      showModalRemove: false,
      remove: '',
      startday: 0,
      cavail: [],
      products: [],
    };
  }


  componentDidMount() {
    Connector.setUser()
    window.scrollTo(0, 0)
    this._isMounted = true

    global.currentClient = '';
    global.currentSession = '';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('recurringLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())

    var avail = false
    if(this.props.location.pathname.indexOf('availability') !== -1) {
      avail = true
      document.title = 'Recurring Available 1:1 Session - PT Mate'
      window.Intercom("update")
    } else {
      document.title = 'Recurring Group Session - PT Mate'
      window.Intercom("update")
    }

    Moment.updateLocale("en", { week: {
      dow: 1, // First day of week is Monday
    }});

    this.setState({
      avail: avail
    })
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false,
      loadingRemove: false,
    })
  }


  configureData() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      var tmp22 = []
      var tmp23 = []
      var tmpp = []
      for(var item1 of global.clients) {
        tmp1.push(item1)
      }
      for(var item2 of global.userStaff) {
        if((item2.data.role === 'assistant' || item2.data.role === 'assistant2' || item2.data.role === 'trainer') && item2.data.status === 'active') {
          tmp2.push(item2)
        }
      }
      if(tmp2.length > 0) {
        tmp22 = [{value: '', name: '- '+lang.t('calendar:form.notassigned')+' -'}, {value: global.uidUser, name: lang.t('common:label.yourself')}]
        for(var t of tmp2) {
          tmp22.push({value: t.id, name: t.data.name})
        }
      }
      if(global.locations.length > 0) {
        tmp23 = [{value: '', name: '- '+lang.t('calendar:form.notassigned')+' -'}]
        for(var l of global.locations) {
          tmp23.push({value: l.id, name: l.data.name})
        }
      }
      for(var item3 of global.products) {
        if(item3.data.type !== 'other') {
          tmpp.push(item3)
        }
      }

      this.setState({
        clients: tmp1,
        clientsList: tmp1,
        groups: global.clientGroups,
        staff: tmp2,
        trainers: tmp22,
        locations: tmp23,
        products: tmpp
      })
      if(this.props.location.pathname.indexOf('recurring-templates/') !== -1) {
        this.configureTemplate()
      }
    }
  }


  configureTemplate() {
    if(!this.state.loading) {
      if(this.props.location.pathname.indexOf('availability') !== -1) {
        document.title = lang.t('calendar:page.recurringavailable11session')+' - PT Mate'
      } else {
        document.title = lang.t('calendar:page.recurringgroupsession')+' - PT Mate'
      }
      window.Intercom("update")

      var arr = this.props.location.pathname.split('/')
      var id = arr[arr.length-1]
      var item = null
      var dur = 30
      var day = ''
      var hrs = ''
      var min = ''
      var preview = true
      var weekday = 0
      var interval = 7
      var max = 0
      var show = false
      var name = ''
      var priv = ''
      var invited = []
      var showBook = false
      var dayBook = 1
      var dayBookCust = ''
      var showCancel = false
      var hrCancel = 1
      var hrCancelCust = ''
      var group = []
      var memberships = []
      var tid = global.uidUser
      var tname = global.userName
      var location = ''
      var link = ''
      var desc = ''
      var avail = false
      var title = 'Recurring Group'
      var end = Moment().add(1, 'months').format('DD/MM/YYYY')
      var showEnd = false
      var cavail = []

      for(var session of global.recurring) {
        if(session.id === id) {
          item = session
          dur = session.data.duration
          interval = session.data.interval
          day = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
          hrs = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('HH')
          min = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('mm')
          weekday = parseInt(Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('d'))
          name = session.data.client
          if(session.data.unlocked !== undefined && session.data.unlocked !== 9999) {
            showBook = true
            if(session.data.unlocked > 0 && session.data.unlocked < 7) {
              dayBook = session.data.unlocked
            } else {
              dayBook = 99
              dayBookCust = session.data.unlocked
            }
          }
          if(!session.data.preview && session.data.preview !== undefined) {
            preview = false
          }
          if(session.data.max !== undefined && session.data.max !== 0) {
            max = session.data.max
            show = true
          }
          if(session.data.invitees !== undefined && session.data.invitees.length > 0) {
            priv = 'private'
            invited = session.data.invitees
          }
          if(session.data.groups !== undefined && session.data.groups.length > 0) {
            priv = 'group'
            for(var gr of global.clientGroups) {
              if(session.data.groups.indexOf(gr.id) !== -1) {
                group.push(gr)
              }
            }
            //group = session.data.groups
          }
          if(session.data.memberships !== undefined && session.data.memberships.length > 0) {
            priv = 'group'
            for(var pr of global.products) {
              if(session.data.memberships.indexOf(pr.id) !== -1) {
                memberships.push(pr)
              }
            }
          }
          if(session.data.locked !== undefined && session.data.locked !== 10000 && session.data.locked !== -10000) {
            showCancel = true
            if(session.data.locked === -0.5 || session.data.locked === -1 || session.data.locked === -2 || session.data.locked === -6 || session.data.locked === -12 || session.data.locked === -24) {
              hrCancel = -session.data.locked
              hrCancelCust = ''
            } else {
              hrCancel = 99
              hrCancelCust = -session.data.locked
            }
          }
          if(session.data.link !== undefined) {
            link = session.data.link
          }
          if(session.data.desc !== undefined) {
            desc = session.data.desc
          }
          if(session.data.trainerId !== undefined) {
            tid = session.data.trainerId
            tname = session.data.trainerName
          }
          if(session.data.location !== undefined) {
            location = session.data.location
          }
          if(session.data.dateEnd !== undefined) {
            if(session.data.dateEnd < 32503640400) {
              end = Moment(session.data.dateEnd, 'X').format('DD/MM/YYYY')
              showEnd = true
            }
          }
          if(session.data.clients !== undefined) {
            for(var cl of global.clients) {
              if(session.data.clients.indexOf(cl.id) === -1) {
                cavail.push(cl)
              }
            }
          } else {
            cavail = global.clients
          }
        }
      }

      var tmps = []
      if(item !== null) {
        if(item.data.availability) {
          title = lang.t('calendar:page.recurringavailable11session')
          avail = true
        }
        for(var items of global.userStaff) {
          if((items.data.role === 'assistant' || items.data.role === 'assistant2' || items.data.role === 'trainer') && items.data.status === 'active') {
            tmps.push(items)
          }
        }
      }

      var cdur = 75
      if(dur !== 30 && dur !== 40 && dur !== 45 && dur !== 60 && dur !== 90) {
        cdur = dur
        dur = 99
      }

      this.setState({
        sessions: global.sessions,
        item: item,
        name: name,
        id: id,
        dur: dur,
        cdur: cdur,
        date: Moment(day, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm'),
        hour: hrs,
        mins: min,
        max: max,
        showMax: show,
        preview: preview,
        weekday: weekday-1,
        startday: weekday-1,
        interval: interval,
        private: priv,
        invited: invited,
        group: group,
        memberships: memberships,
        showBook: showBook,
        dayBook: dayBook,
        dayBookCust: dayBookCust,
        showBookCancel: showCancel,
        hourBookCancel: hrCancel,
        hourBookCancelCust: hrCancelCust,
        trainerId: tid,
        trainerName: tname,
        location: location,
        link: link,
        desc: desc,
        avail: avail,
        title: title,
        showEnd: showEnd,
        dateEnd: end,
        staff: tmps,
        cavail: cavail,
        editing: true
      })

    }
  }


  getClientName(id) {
    var label = lang.t('common:label.deletedclient')
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    return label
  }


  getClient(id) {
    var client = null
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        client = item2
      }
    }
    return client
  }



  // Interactive stuff ------------------------------------------------------------



  removeItem(item) {
    if(this.state.private === 'private') {
      var tmp1 = this.state.invited
      if(tmp1.length > 0) {
        for(var i=0; i<tmp1.length; i++) {
          if(tmp1[i] === item) {
            tmp1.splice(i, 1)
          }
        }
      }
      this.setState({
        invited: tmp1
      })
    } else {
      var tmp2 = this.state.group
      if(tmp2.length > 0) {
        for(var j=0; j<tmp2.length; j++) {
          if(tmp2[j] === item) {
            tmp2.splice(j, 1)
          }
        }
      }
      var tmp3 = this.state.memberships
      if(tmp3.length > 0) {
        for(var k=0; k<tmp3.length; k++) {
          if(tmp3[k] === item) {
            tmp3.splice(k, 1)
          }
        }
      }
      this.setState({
        group: tmp2,
        memberships: tmp3,
      })
    }
  }


  setWeekday(event) {
    var date = Moment().startOf('week').add(event, 'days').add(this.state.start, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins
    if(this.state.editing) {
      var diff = event-this.state.startday
      date = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(diff, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins
    }
    this.setState({
      weekday: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    })
  }


  setStart(event) {
    var date = Moment().startOf('week').add(this.state.weekday, 'days').add(event, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins
    this.setState({
      start: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    })
  }


  setHour(event) {
    var date = Moment().startOf('week').add(this.state.weekday, 'days').add(this.state.start, 'days').format('DD/MM/YYYY')+' '+event+':'+this.state.mins
    if(this.state.editing) {
      date = Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')+' '+event+':'+this.state.mins
    }
    this.setState({
      hour: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    });
  }


  setMins(event) {
    var date = Moment().startOf('week').add(this.state.weekday, 'days').add(this.state.start, 'days').format('DD/MM/YYYY')+' '+this.state.hour+':'+event
    if(this.state.editing) {
      date = Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')+' '+this.state.hour+':'+event
    }
    this.setState({
      mins: event,
      date: Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')
    });
  }


  setTrainer(item) {
    var name = ''
    for(var trainer of global.userStaff) {
      if(trainer.id === item) {
        name = trainer.data.name
      }
    }
    if(item === global.uidUser) {
      name = global.userName
    }
    this.setState({
      trainerId: item,
      trainerName: name
    })
  }


  onChangeBook(event) {
    var val = 99
    if(event === '') {
      val = 1
    }
    this.setState({
      dayBook: val,
      dayBookCust: event
    });
  };


  onChangeBookCancel(event) {
    var val = 99
    if(event === '') {
      val = 1
    }
    this.setState({
      hourBookCancel: val,
      hourBookCancelCust: event
    });
  };


  checkCreateTemplate() {
    var passed = true
    var tmp = [false, false, false]
    this.setState({error: tmp})
    if(this.state.invited.length === 0 && this.state.private === 'private') {
      passed = false
      tmp[1] = true
    }
    if(this.state.group.length === 0 && this.state.memberships.length === 0 && this.state.private === 'group') {
      passed = false
      tmp[1] = true
    }

    this.setState({
      error: tmp,
      loading: passed
    }, () => {
      if(passed) {
        this.configureCreateTemplate()
      }
    })
  }


  configureCreateTemplate() {
    var duration = parseInt(this.state.dur)
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }

    this.setState({loading: true})
    global.updatingRec = true

    var max = 0
    if(this.state.showMax) {
      max = parseInt(this.state.max)
    }

    var obj = Firebase.database().ref('/recurring/'+global.uid).push()
    this.prepareSessions(0, duration, obj.key, max)
  }


  prepareSessions(num, duration, id, max) {
    var count = 0

    var invitees = []
    var groups = []
    var memberships = []
    if(this.state.invited.length > 0 && this.state.private === 'private') {
      invitees = this.state.invited
    }
    if(this.state.group.length > 0 && this.state.private === 'group') {
      for(var gr of this.state.group) {
        groups.push(gr.id)
      }
    }
    if(this.state.memberships.length > 0 && this.state.private === 'group') {
      for(var pr of this.state.memberships) {
        memberships.push(pr.id)
      }
    }

    var days = 9999
    if(this.state.showBook) {
      if(this.state.dayBookCust !== '') {
        days = parseInt(this.state.dayBookCust)
      } else {
        days = parseInt(this.state.dayBook)
      }
    }

    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }

    var end = parseInt(Moment('01/01/3000', 'DD/MM/YYYY').format('X')) //32503640400
    if(this.state.showEnd) {
      end = parseInt(Moment(this.state.dateEnd, 'DD/MM/YYYY').format('X'))
    }
    if(end < this.state.date) {
      end = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((this.state.interval), 'days').format('X'))
    }

    var endDate = parseInt(Moment().add(90, 'days').format('X'))
    if(endDate > end) {
      endDate = end
    }

    var lock = 10000;
    if(this.state.showBookCancel) {
      lock = parseInt(this.state.hourBookCancel)*-1
      if(this.state.hourBookCancel === 0.5) {
        lock = parseFloat(this.state.hourBookCancel)*-1
      }
      if(this.state.hourBookCancel === 99) {
        lock = parseInt(this.state.hourBookCancelCust)*-1
        if(String(this.state.hourBookCancelCust).indexOf('.') !== -1) {
          lock = parseFloat(this.state.hourBookCancelCust)*-1
        }
      }
    }
    for(var i=0; i<18; i++) { 
      var date = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((i*this.state.interval), 'days').format('X'))
      if(date < endDate && date > parseInt(Moment().format('X'))) {
        var locked = parseInt(Moment(date, 'X').add(lock, 'hours').format('X'))
        //this.createSession(i, duration, id, max, invitees, days, locked, groups)
        count++
      }
    }
    if(this.state.avail) {
      this.createTemplateAvailability(count, duration, id, days, lock, end, location, locationName)
      setTimeout(() => {
        for(var j=0; j<18; j++) { 
          var date = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((j*this.state.interval), 'days').format('X'))
          if(date < endDate && date > parseInt(Moment().format('X'))) {
            this.createSessionAvailability(j, duration, id, max, invitees, days, locked, groups, location, locationName)
          }
        }
      }, 1000);
    } else {
      this.createTemplate(count, duration, id, max, invitees, days, lock, groups, memberships, end, location, locationName)
      setTimeout(() => {
        for(var j=0; j<18; j++) { 
          var date = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((j*this.state.interval), 'days').format('X'))
          if(date < endDate && date > parseInt(Moment().format('X'))) {
            this.createSession(j, duration, id, max, invitees, days, lock, groups, memberships, end, location, locationName)
          }
        }
      }, 1000);
    }
    this.setState({
      maxNum: count
    })
  }


  createTemplate(num, duration, id, max, invitees, days, lock, groups, memberships, end, location, locationName) {
    global.badgeLocked = true
    Firebase.database().ref('/recurring/'+global.uid+'/'+id).set({
      client: this.state.name,
      group: true,
      plan: true,
      duration: duration,
      preview: this.state.preview,
      date: this.state.date,
      timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')),
      dateEnd: end,
      interval: this.state.interval,
      current: num,
      max: max,
      invitees: invitees,
      groups: groups,
      memberships: memberships,
      uid: global.uid,
      unlocked: days,
      locked: lock,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
      desc: this.state.desc,
    }).then(() => {
      //this.createSessions(0, plan, duration, obj.key, lock)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  createSession(num, duration, id, max, invitees, days, lock, groups, memberships, end, location, locationName) {
    var date = Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days');
    var locked = parseInt(Moment(date).add(lock, 'hours').format('X'))
    Firebase.database().ref('/sessions/'+global.uid).push({
      client: this.state.name,
      group: true,
      plan: true,
      duration: duration,
      preview: this.state.preview,
      date: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days').format('DD/MM/YYYY HH:mm'),
      timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days').format('X')),
      notes: '',
      max: max,
      uid: global.uid,
      attendance: 2,
      template: id,
      templateSeq: num,
      invitees: invitees,
      groups: groups,
      memberships: memberships,
      unlocked: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval)-days, 'days').format('DD/MM/YYYY HH:mm'),
      locked: locked,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
      desc: this.state.desc,
    }).then(() => {
      this.checkFinished(num)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  createTemplateAvailability(num, duration, id, days, lock, end, location, locationName) {
    global.badgeLocked = true
    Firebase.database().ref('/recurring/'+global.uid+'/'+id).set({
      client: '',
      availability: true,
      group: true,
      plan: true,
      duration: duration,
      preview: this.state.preview,
      date: this.state.date,
      timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')),
      dateEnd: end,
      interval: this.state.interval,
      current: num,
      max: 1,
      invitees: [],
      groups: [],
      uid: global.uid,
      unlocked: days,
      locked: lock,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
    }).then(() => {
      //this.createSessions(0, plan, duration, obj.key, lock)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  createSessionAvailability(num, duration, id, max, invitees, days, lock, groups, location, locationName) {
    var date = Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days');
    var locked = parseInt(Moment(date).add(lock, 'hours').format('X'))
    Firebase.database().ref('/sessions/'+global.uid).push({
      client: '',
      availability: true,
      group: true,
      plan: true,
      duration: duration,
      preview: this.state.preview,
      date: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days').format('DD/MM/YYYY HH:mm'),
      timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval), 'days').format('X')),
      notes: '',
      max: 1,
      uid: global.uid,
      attendance: 2,
      template: id,
      templateSeq: num,
      invitees: [],
      groups: [],
      unlocked: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((num*this.state.interval)-days, 'days').format('DD/MM/YYYY HH:mm'),
      locked: locked,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
    }).then(() => {
      this.checkFinished(num)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  checkFinished(num) {
    if(num === this.state.maxNum-1) {
      global.message = lang.t('messaging:message.templatecreated')
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'recurring');
      }, 1000);
      this.props.history.push(ROUTES.RECURRING)
    }
  }


  addBooked(event) {
    var tmp = []
    if(this.state.item.data.clients !== undefined) {
      tmp = this.state.item.data.clients
    }
    tmp.push(event.target.value)
    var name = lang.t('messaging:push.groupsession')
    if(this.state.item.data.client !== '') {
      name = this.state.item.data.client
    }
    var tokens = []
    for(var item of global.clients) {
      if(item.id === event.target.value && item.data.pushToken !== undefined && item.data.pushToken !== '') {
        tokens.push(item.data.pushToken)
      }
    }
    if(tokens.length > 0) {
      Connector.sendPushNotification('', lang.t('messaging:push.bookedin.title'), global.userName+lang.t('messaging:push.bookedin.text2')+name+' '+Cal.getPushDate(this.state.item.data.date), 'session', this.state.item.id, tokens)
    }
    // Sessions
    for(var item of global.sessions) {
      var bookings = []
      if(item.data.template === this.state.item.id && this.state.item.data.bookings !== undefined) {
        bookings = item.data.bookings
        bookings.push(event.target.value+"||recurring||trainer||"+Moment().format('X'))
      } else if(item.data.template === this.state.item.id && this.state.item.data.bookings === undefined) {
        bookings = [event.target.value+"||recurring||trainer||"+Moment().format('X')]
      }
      if(item.data.template === this.state.item.id && item.data.clients !== undefined) {
        if(item.data.clients.indexOf(event.target.value) === -1 && Moment(item.data.timestamp, 'X') > Moment()) {
          var tmp = item.data.clients
          tmp.push(event.target.value)
          Firebase.database().ref('/sessions/'+global.uid+'/'+item.id).update({
            clients: tmp,
            bookings: bookings,
          })
        }

      } else if(item.data.template === this.state.item.id && item.data.clients === undefined) {
        Firebase.database().ref('/sessions/'+global.uid+'/'+item.id).update({
          clients: [event.target.value],
          bookings: bookings
        })
      }
    }
    // Template
    Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.clientbookedin'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    this.setState({
      bookedState: 'button'
    })
  }



  // Update template ------------------------------------------------------------



  checkUpdateTemplate() {
    var passed = true
    var tmp = [false, false]
    this.setState({error: tmp})
    if(this.state.invited.length === 0 && this.state.private === 'private') {
      passed = false
      tmp[1] = true
    }
    if(this.state.group.length === 0 && this.state.memberships.length === 0 && this.state.private === 'group') {
      passed = false
      tmp[1] = true
    }
    this.setState({error: tmp})
    if(passed) {
      this.setState({showModalUpdate: true})
    }
  }


  updateTemplate() {
    this.hideModals()
    var duration = parseInt(this.state.dur)
    if(this.state.dur === 99) {
      duration = parseInt(this.state.cdur)
    }
    var max = 0
    if(this.state.showMax) {
      max = parseInt(this.state.max)
    }
    if(this.state.avail) {
      max = 1
    }
    var invitees = []
    var groups = []
    var memberships = []
    if(this.state.invited.length > 0 && this.state.private === 'private') {
      invitees = this.state.invited
    }
    if(this.state.group.length > 0 && this.state.private === 'group') {
      for(var gr of this.state.group) {
        groups.push(gr.id)
      }
    }
    if(this.state.memberships.length > 0 && this.state.private === 'group') {
      for(var pr of this.state.memberships) {
        memberships.push(pr.id)
      }
    }

    var days = 9999
    if(this.state.showBook) {
      if(this.state.dayBookCust !== '') {
        days = parseInt(this.state.dayBookCust)
      } else {
        days = parseInt(this.state.dayBook)
      }
    }
    if(days === -10000) {
      days = 10000
    }

    var lock = 10000;
    if(this.state.showBookCancel) {
      lock = parseInt(this.state.hourBookCancel)*-1
      if(this.state.hourBookCancel === 0.5) {
        lock = parseFloat(this.state.hourBookCancel)*-1
      }
      if(this.state.hourBookCancel === 99) {
        lock = parseInt(this.state.hourBookCancelCust)*-1
        if(String(this.state.hourBookCancelCust).indexOf('.') !== -1) {
          lock = parseFloat(this.state.hourBookCancelCust)*-1
        }
      }
    }

    var end = parseInt(Moment('01/01/3000', 'DD/MM/YYYY').format('X')) //32503640400
    if(this.state.showEnd) {
      end = parseInt(Moment(this.state.dateEnd, 'DD/MM/YYYY').format('X'))
    }
    if(end < this.state.date) {
      end = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((this.state.interval), 'days').format('X'))
    }

    var location = null
    var locationName = null
    if(global.locations.length > 0) {
      location = this.state.location
      locationName = ""
      for(var loc of global.locations) {
        if(loc.id === this.state.location) {
          locationName = loc.data.name
        }
      }
    }

    Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.id).update({
      duration: duration,
      preview: this.state.preview,
      date: this.state.date,
      dateEnd: end,
      timestamp: parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').format('X')),
      max: max,
      client: this.state.name,
      invitees: invitees,
      groups: groups,
      memberships: memberships,
      unlocked: days,
      locked: lock,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
      desc: this.state.desc,
    }).then(() => {
      this.updateSessions(max, duration, invitees, days, lock, groups, memberships, end, location, locationName)
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.templateupdated'))
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  updateSessions(max, duration, invitees, days, lock, groups, memberships, end, location, locationName) {
    var sess = []
    var del = []
    var date = parseInt(Moment().format('X'))
    var num = 0
    for(var session of this.state.sessions) {
      var sdate = parseInt(Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X'))
      if(session.data.template === this.state.item.id && sdate > date) {
        var nd = session.data.templateSeq*this.state.item.data.interval
        if(sdate > end) {
          del.push(session.id)
        } else {
          if(session.data.templateSeq > 0) {
            num = session.data.templateSeq
          }
          sess.push({
            id: session.id,
            date: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add(nd, 'days').format( 'DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins,
            unlocked: Moment(this.state.date, 'DD/MM/YYYY HH:mm').add(nd-days, 'days').format( 'DD/MM/YYYY')+' '+this.state.hour+':'+this.state.mins,
            trainerId: this.state.trainerId,
            trainerName: this.state.trainerName,
            location: location,
            locationName: locationName,
          })
        }
      }
    }
    for(var item of sess) {
      var ts = parseInt(Moment(item.date, 'DD/MM/YYYY HH:mm').format('X'))
      if(item.timestamp !== undefined) {
        ts = parseInt(Moment(item.timestamp, 'X').format('X'))
      }
      var locked = parseInt(Moment(ts, 'X').add(lock, 'hours').format('X'))
      Firebase.database().ref('/sessions/'+global.uid+'/'+item.id).update({
        duration: duration,
        preview: this.state.preview,
        date: item.date,
        timestamp: ts,
        client: this.state.name,
        max: max,
        invitees: invitees,
        groups: groups,
        memberships: memberships,
        unlocked: Moment(ts, 'X').add(-days, 'days').format('DD/MM/YYYY HH:mm'),
        locked: locked,
        trainerId: this.state.trainerId,
        trainerName: this.state.trainerName,
        location: location,
        locationName: locationName,
        link: this.state.link,
        desc: this.state.desc,
      })
    }
    for(var item2 of del) {
      Firebase.database().ref('/sessions/'+global.uid+'/'+item2).remove()
    }

    //Create additional sessions
    num++
    var endDate = parseInt(Moment().add(90, 'days').format('X'))
    if(endDate > end) {
      endDate = end
    }
    for(var j=num; j<(num+18); j++) {
      var nsdate = parseInt(Moment(this.state.date, 'DD/MM/YYYY HH:mm').add((j*this.state.item.data.interval), 'days').format('X'))
      if(nsdate < endDate) {
        if(this.state.item.data.availability) {
          this.createAdditionalSessionsAvail(nsdate, num, duration, locked, days, location, locationName)
        } else {
          this.createAdditionalSessions(nsdate, num, duration, max, invitees, groups, memberships, locked, days, location, locationName)
        }
        
      }
    }
  }


  createAdditionalSessions(date, num, duration, max, invitees, groups, memberships, locked, days, location, locationName) {
    Firebase.database().ref('/sessions/'+global.uid).push({
      client: this.state.name,
      group: true,
      plan: true,
      duration: duration,
      preview: this.state.preview,
      date: Moment(date, 'X').format('DD/MM/YYYY HH:mm'),
      timestamp: parseInt(date),
      notes: '',
      max: max,
      uid: global.uid,
      attendance: 2,
      template: this.state.item.id,
      templateSeq: num,
      invitees: invitees,
      groups: groups,
      memberships: memberships,
      unlocked: Moment(date, 'X').add(-days, 'days').format('DD/MM/YYYY HH:mm'),
      locked: locked,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
    })
  }


  createAdditionalSessionsAvail(date, num, duration, locked, days, location, locationName) {
    Firebase.database().ref('/sessions/'+global.uid).push({
      client: '',
      availability: true,
      group: true,
      plan: true,
      duration: duration,
      preview: this.state.preview,
      date: Moment(date, 'X').format('DD/MM/YYYY HH:mm'),
      timestamp: parseInt(date),
      notes: '',
      max: 1,
      uid: global.uid,
      attendance: 2,
      template: this.state.item.id,
      templateSeq: num,
      invitees: [],
      groups: [],
      unlocked: Moment(date, 'X').add(-days, 'days').format('DD/MM/YYYY HH:mm'),
      locked: locked,
      trainerId: this.state.trainerId,
      trainerName: this.state.trainerName,
      location: location,
      locationName: locationName,
      link: this.state.link,
    })
  }



  // Modals ------------------------------------------------------------



  showModalRemove(item) {
    this.setState({
      showModalRemove: true,
      remove: item
    })
  }


  hideModals() {
    this.setState({
      showModalClients: false,
      showModalGroups: false,
      showModalMemberships: false,
      showModalDelete: false,
      showModalUpdate: false,
      showModalRemove: false,
    })
  }


  selectClients(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item)
    }
    this.setState({
      invited: tmp,
    })
  }


  selectGroups(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item)
    }
    this.setState({
      group: tmp,
    })
  }


  selectMemberships(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item)
    }
    this.setState({
      memberships: tmp,
    })
  }


  deleteTemplate() {
    this.hideModals()
    this.setState({
      loading: true,
    }, () => {
      var ids = []
      var date = parseInt(Moment().format('X'))
      
      setTimeout(() => {
        for(var session of this.state.sessions) {
          var sdate = parseInt(Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X'))
          if(session.data.template === this.state.item.id && sdate > date) {
            ids.push(session.id)
          }
        }
        Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.id).remove().then(() => {
          for(var item of ids) {
            Firebase.database().ref('/sessions/'+global.uid+'/'+item).remove()
          }
          global.message = lang.t('messaging:message.templatedeleted')
          this.props.history.push(ROUTES.RECURRING)
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
          this.setState({loading: false})
        })
      }, 1000);
    })
  }


  removeClientSessions() {
    this.hideModals()
    this.setState({
      loadingRemove: true
    })
    for(var item of global.sessions) {
      if(item.data.template === this.state.item.id && item.data.clients !== undefined) {
        if(item.data.clients.indexOf(this.state.remove) !== -1 && Moment(item.data.timestamp, 'X') > Moment()) {
          for(var i=0; i<item.data.clients.length; i++) {
            if(item.data.clients[i] === this.state.remove) {
              item.data.clients.splice(i,1)
            }
          }
          var bookings = []
          if(item.data.template === this.state.item.id && this.state.item.data.bookings !== undefined) {
            bookings = item.data.bookings
            bookings.push(this.state.remove+"||cancellationrec||trainer||"+Moment().format('X'))
          } else if(item.data.template === this.state.item.id && this.state.item.data.bookings === undefined) {
            bookings = [this.state.remove+"||cancellationrec||trainer||"+Moment().format('X')]
          }
          Firebase.database().ref('/sessions/'+global.uid+'/'+item.id).update({
            clients: item.data.clients,
            bookings: bookings,
          })
        }

      }
    }
    var clients = this.state.item.data.clients
    for(var j=0; j<clients.length; j++) {
      if(clients[j] === this.state.remove) {
        clients.splice(j,1)
      }
    }
    Firebase.database().ref('/recurring/'+global.uid+'/'+this.state.item.id).update({
      clients: clients
    }).then(() => {
      setTimeout(() => {
        this.setState({
          loadingRemove: false
        });
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.clientremoved'));
      }, 1000);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderSelect() {
    if(!this.state.avail) {
      return (
        <div className="col-12">
          <InputInvited type={this.state.private} clients={this.state.invited} groups={this.state.group} memberships={this.state.memberships} recurring={true} error={this.state.error[1]} clickClients={() => this.setState({showModalClients: true})} clickGroups={() => this.setState({showModalGroups: true})} clickMemberships={() => this.setState({showModalMemberships: true})} clickRemove={(event) => this.removeItem(event)}/>
        </div>
      )
    }
  }


  renderMax() {
    if(this.state.showMax && !this.state.avail) {
      return <InputNumber label={lang.t('calendar:form.spots.field')} placeholder={lang.t('calendar:form.spots.placeholder')} value={this.state.max} onChange={(event) => this.setState({max: event})}/>
    }
  }


  renderBook() {
    if(this.state.showBook) {
      return (
        <div className="clear">
          <div className="col-9" style={{paddingLeft: 0}}>
            <InputRadio
              label={lang.t('calendar:form.openrec.field')}
              value={this.state.dayBook}
              clickElement={(event) => this.setState({dayBook: event, dayBookCust: ''})}
              values={[{name: '1', value: 1}, {name: '2', value: 2}, {name: '3', value: 3}, {name: '4', value: 4}, {name: '5', value: 5}, {name: '6', value: 6}]}
            />
          </div>
          <div className="col-3" style={{paddingRight: 0}}>
            <InputNumber label={lang.t('calendar:form.custom')} value={this.state.dayBookCust} onChange={(event) => this.onChangeBook(event)}/>
          </div>
        </div>
      )
    }
  }


  renderBookCancel() {
    if(this.state.showBookCancel) {
      return (
        <div className="clear">
          <div className="col-9" style={{paddingLeft: 0}}>
            <InputRadio
              label={lang.t('calendar:form.closerec.field')}
              value={this.state.hourBookCancel}
              clickElement={(event) => this.setState({hourBookCancel: event, hourBookCancelCust: ''})}
              values={[{name: '0.5', value: 0.5}, {name: '1', value: 1}, {name: '2', value: 2}, {name: '6', value: 6}, {name: '12', value: 12}, {name: '24', value: 24}]}
            />
          </div>
          <div className="col-3" style={{paddingRight: 0}}>
            <InputNumber label={lang.t('calendar:form.custom')} value={this.state.hourBookCancelCust} onChange={(event) => this.onChangeBookCancel(event)}/>
          </div>
        </div>
      )
    }
  }


  renderHeaderLink() {
    var label = global.currentPage
    if(global.currentPage === '/clients' && global.currentClient !== '') {
      label = '/clients/'+global.currentClient+'/sessions'
    }
    return label
  }


  renderLimit() {
    if(!this.state.avail) {
      return <InputToggle label={lang.t('calendar:form.spots.label')} value={this.state.showMax} onChange={() => this.setState({showMax: !this.state.showMax})} text={lang.t('calendar:form.spots.text')}/>
    }
  }


  renderEnd() {
    if(this.state.showEnd) {
      return <InputDate label="" value={Moment(this.state.dateEnd, 'DD/MM/YYYY').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().format('YYYY-MM-DD')} max={Moment().add(2, 'months').format('YYYY-MM-DD')} onChange={(event) => this.setState({dateEnd: Moment(event).format('DD/MM/YYYY')})}/>
    }
  }


  renderMoreOptions() {
    if(this.state.showMore) {
      return (
        <div className="clear">
          <div className="sv-10"></div>
          <button className="btn tertiary small width-12 sub" onClick={() => this.setState({showMore: false})}>{lang.t('calendar:button.hidemoreoptions')}</button>
          <div className="sv-20"></div>
          <InputText label={lang.t('calendar:form.sessiondescription')} value={this.state.desc} onChange={(event) => this.setState({desc: event})}/>
          <InputToggle label={lang.t('calendar:form.preview.label')} value={this.state.preview} onChange={() => this.setState({preview: !this.state.preview})} text={lang.t('calendar:form.preview.text')}/>
          {this.renderLimit()}
          {this.renderMax()}
          <InputToggle label={lang.t('calendar:form.open.label')} value={this.state.showBook} onChange={() => this.setState({showBook: !this.state.showBook})} text={lang.t('calendar:form.openrec.text')}/>
          {this.renderBook()}
          <InputToggle label={lang.t('calendar:form.close.label')} value={this.state.showBookCancel} onChange={() => this.setState({showBookCancel: !this.state.showBookCancel})} text={lang.t('calendar:form.closerec.text')}/>
          {this.renderBookCancel()}
          <InputToggle label={lang.t('calendar:form.enddate.label')} value={this.state.showEnd} onChange={() => this.setState({showEnd: !this.state.showEnd})} text={lang.t('calendar:form.enddate.text')}/>
          {this.renderEnd()}
        </div>
      )
    } else {
      return (
        <div className="clear">
          <div className="sv-10"></div>
          <button className="btn tertiary small width-12 add" onClick={() => this.setState({showMore: true})}>{lang.t('calendar:button.showmoreoptions')}</button>
        </div>
      )
    }
  }


  renderLocation() {
    if(global.locations.length > 0 && HelperBusiness.getRoleIncl('trainer,admin')) {
      return <Select label={lang.t('calendar:form.location')} value={this.state.location} values={this.state.locations} onChange={(event) => this.setState({location: event})}/>
    }
  }

  
  renderTrainer() {
    if(this.state.staff.length > 0 && HelperBusiness.getRoleIncl('')) {
      return <Select label={lang.t('calendar:form.trainer')} value={this.state.trainerId} values={this.state.trainers} onChange={(event) => this.setTrainer(event)}/>
    }
  }


  renderName() {
    if(!this.state.avail) {
      return <InputText label={lang.t('calendar:form.sessionname')} value={this.state.name} onChange={(event) => this.setState({name: event})}/>
    }
  }


  renderRepeats() {
    if(this.state.editing && this.state.item !== null) {
      return <DataInfo label={lang.t('calendar:form.repeats')} value={this.state.item.data.interval === 7 ? lang.t('calendar:label.week') : lang.t('calendar:label.fortnight')}/>
    } else {
      return (
        <InputRadio
          label={lang.t('calendar:form.repeats')}
          value={this.state.interval}
          clickElement={(event) => this.setState({interval: event})}
          values={[{name: lang.t('calendar:label.week'), value: 7}, {name: lang.t('calendar:label.fortnight'), value: 14}]}
        />
      )
    }
  }


  renderBookedSelect() {
    if(this.state.showAdd) {
      var list = this.state.cavail
      return (
        <div>
        <select onChange={event => this.addBooked(event)}>
          <option value="">{lang.t('calendar:form.selectclient')}</option>
          {list.map(item => (
            <option value={item.id}>{item.data.name}</option>
          ))}
        </select>
        <div className="sv-10"></div>
        </div>
      )
    }
  }


  renderBookedClients() {
    if(this.state.item.data.clients !== undefined) {
      if(this.state.item.data.clients.length > 0) {
        return (
          <div>
            {this.state.item.data.clients.map(item => (
              <div className="client small" key={item}>
                {HelperClients.getImage(this.getClient(item))}
                <p className="lft">{this.getClientName(item)}</p>        
                <button className="btn tertiary rgt small close rgt" style={{fontSize: 10, marginTop: 7}} onClick={() => this.showModalRemove(item)}>{lang.t('common:button.remove')}</button>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderBookedIn() {
    if(global.spaceAllowRecurring && this.state.editing && this.state.item !== null) {
      return (
        <div className="info-small">
          <h3 className="mb-20">{lang.t('calendar:label.clientswithrecurring')}</h3>
          {this.renderBookedClients()}
          <div className="sv-10"></div>
          {this.renderBookedSelect()}
          <button className={this.state.showAdd ? 'btn tertiary width-12 close small' : 'btn tertiary width-12 add small'} onClick={() => this.setState({showAdd: !this.state.showAdd})}>{this.state.showAdd ? lang.t('common:button.cancel') : lang.t('calendar:button.addaclient')}</button>
          
        </div>
      )
    }
  }


  renderComments() {
    var id = 'recavail'
    if(!this.state.avail) {
      id = 'recgroup1'
      if(this.state.private === 'group') {
        id = 'recgroup2'
      }
      if(this.state.private === 'private') {
        id = 'recgroup3'
      }
    }
    if(this.state.editing) {
      id += 'edit'
    }
    return <WidgetFormInfo id={id}/>
  }


  renderButtons() {
    if(this.state.editing) {
      if(HelperBusiness.getRoleIncl('') || (HelperBusiness.getRole('trainer') && this.state.item.data.trainerId === global.uidUser)) {
        return (
          <div>
            <button className="btn primary mb-20" onClick={() => this.checkUpdateTemplate()}>{lang.t('calendar:button.updatetemplate')}</button>
            <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>{lang.t('calendar:button.deletetemplate')}</button>
          </div>
        )
      } else {
        return (
          <div className="info-small yellow"><p>{lang.t('calendar:label.cantupdatetemplate')}</p></div>
        )
      }
    } else {
      return <button className="btn primary" onClick={() => this.checkCreateTemplate()}>{lang.t('calendar:button.createtemplate')}</button>
    }
  }


  renderTabs() {
    if(this.state.avail) {
      return (
        <div className="radios tabs">
          <button className="radio col-6" onClick={() => this.props.history.push(ROUTES.NEWRECSESSION)}>{lang.t('calendar:sessions.11')}</button>
          <button className="radio col-6 active">{lang.t('calendar:form.11availability')}</button>
        </div>
      )
    } else {
      return (
        <div>
          <div className="radios tabs">
            <button className={this.state.private === '' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({private: ''})}>{lang.t('calendar:form.public')}</button>
            <button className={this.state.private === 'group' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({private: 'group'})}>{lang.t('calendar:form.exclusive')}</button>
            <button className={this.state.private === 'private' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setState({private: 'private'})}>{lang.t('calendar:form.private')}</button>
          </div>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className={this.state.editing ? 'content form-sidebar' : 'content form-sidebar withtabs'}>
            <div className="col-6 mt-20">
              <div className="box withtabs">
                {this.renderTabs()}
                <div className="inner">
                  {this.renderSelect()}
                  <div className="sv-30 clear"></div>
                  <div className="col-12 clear">
                    {this.renderRepeats()}
                  </div>
                  <InputDatetime type={this.state.editing ? 'recurringedit' : 'recurring'} start={this.state.start} weekday={this.state.weekday} hour={this.state.hour} min={this.state.mins} setDay={(event) => this.setWeekday(event)} setHour={(event) => this.setHour(event)} setMin={(event) => this.setMins(event)} setStart={(event) => this.setStart(event)}/>
                  <div className="col-12 clear">
                    <InputDuration duration={this.state.dur} custom={this.state.cdur} setDuration={(event) => this.setState({dur: event})} setCustom={(event) => this.setState({cdur: event})}/>
                    <InputText label={lang.t('calendar:form.virtual')} placeholder='Zoom, Hangouts, Skype...' value={this.state.link} onChange={(event) => this.setState({link: event})}/>
                    {this.renderName()}
                    {this.renderTrainer()}
                    {this.renderLocation()}
                    {this.renderMoreOptions()}
                  </div>
                  <div className="clear sv-30"></div>
                  {this.renderButtons()}
                </div>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                {this.renderComments()}
                {this.renderBookedIn()}
              </div>
            </div>
            <div className="clear sv-40"></div>
  
          </div>
          <div className="btn-news" onClick={() => window.Intercom('startTour', '318182')}>{lang.t('common:label.showmearound')}</div>
          <Header title={this.state.editing ? lang.t('calendar:title.recurringtemplate') : lang.t('calendar:title.newrecurringtemplate')} link={this.renderHeaderLink()} type={this.state.editing ? 'simple' : 'recurring'} active={this.state.avail ? '11' : 'group'}/>
          <Navigation active='calendar' />
          <ModalClients show={this.state.showModalClients} title={lang.t('modals:clients.title.selectclients')} button={lang.t('modals:clients.button.confirmselection')}  multiple={true} selected={this.state.invited} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectClients(event)}/>
          <ModalItems show={this.state.showModalGroups} items={global.clientGroups} title={lang.t('modals:items.title.selectgroups')} button={lang.t('modals:clients.button.confirmselection')} multiple={true} selected={this.state.group} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectGroups(event)}/>
          <ModalItems show={this.state.showModalMemberships} items={this.state.products} type='memberships' empty={lang.t('modals:items.empty.nomemberships')} title={lang.t('modals:items.title.packsandmemberships')} button={lang.t('modals:clients.button.confirmselection')} multiple={true} selected={this.state.memberships} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectMemberships(event)}/>
          <ModalMessageSimple type='deleterec' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteTemplate()}/>
          <ModalMessageSimple type='updaterec' show={this.state.showModalUpdate} onHide={() => this.hideModals()} clickMainButton={() => this.updateTemplate()}/>
          <ModalMessageSimple type='removerec' show={this.state.showModalRemove} onHide={() => this.hideModals()} var={this.getClientName(this.state.remove)} clickMainButton={() => this.removeClientSessions()}/>
          <Loader show={this.state.loading} label={this.state.editing ? lang.t('messaging:loader.recurring1') : lang.t('messaging:loader.recurring2')}/>
          <Loader show={this.state.loadingRemove} label={lang.t('messaging:loader.recurring3')}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='recurring-locked' type='simple'/>
          </div>
          <Header title={lang.t('calendar:title.recurringtemplate')} link={ROUTES.RECURRING} type='simple'/>
          <Navigation active='calendar' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common','modals','messaging'])(withRouter(withAuthorization(condition)(NewRecGroupPage)));