import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Firebase from 'firebase';
import 'firebase/functions';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListHeader from '../../components/List/header';
import ListSimple from '../../components/List/simple';
import ListEmpty from '../../components/List/empty';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalBlacklist from '../../components/Modals/blacklist';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class AdminEmailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: global.emails,
      list: [],
      dateRange: global.dateRange,
      showModalMessage: false,
      showModalLocked: false,
      showModalWelcome: false,
      showModalBlacklist: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Emails - PT Mate', ROUTES.ADMINEMAILS)
    HelperPage.checkMessage()
    this._isMounted = true
    
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('emailsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('publicRequestsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  loadBlacklist() {
    let itemsRef = Firebase.database().ref('/public/'+global.uid);
    itemsRef.on("value", function(snapshot) {
      if (snapshot.exists()) {
        var data = snapshot.val();
        global.publicData = data.blacklist
      }
      EventEmitter.dispatch('publicListLoaded', 'loaded');
    });
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      if(global.publicData[0] !== undefined) {
        if(global.publicData[0].list !== undefined) {
          tmp = global.publicData[0].list
        }
      }
      this.setState({
        emails: global.emails,
        list: tmp
      })
    }
  }


  getStatus(item) {
    var label = ''
    if(item.data.status === 'draft') {
      label = lang.t('admin:label.draft')
    } else if(item.data.status === 'sent') {
      label = lang.t('admin:label.sent')+' '+Moment(item.data.date, 'X').locale(lang.language).format('llll')
    } else if(item.data.status === 'welcome') {
      label = <span className="pill green">{lang.t('admin:label.welcomeemail')}</span>
    }
    return label
  }


  getSent() {
    var label = 0
    for(var item of this.state.emails) {
      if(item.data.status === 'sent') {
        label += 1
      }
    }
    return label
  }


  getClientsSent() {
    var label = 0
    for(var item of this.state.emails) {
      if(item.data.status === 'sent' && item.data.clients !== undefined) {
        label += item.data.clients.length
      }
    }
    return label
  }


  clickNewList() {
    if(global.emails.length === 0) {
      this.setState({
        showModalBlacklist: true
      })
    } else {
      this.props.history.push(ROUTES.ADMINNEWEMAIL)
    }
  }



  // Modals ------------------------------------------------------------



  showModal() {
    if(global.userCard === '' || global.userCard === undefined) {
      this.setState({
        showModalLocked: true,
      })
    } else {
      this.setState({
        showModalMessage: true,
      })
    }
  }


  showModalWelcome() {
    if(global.userSubPlanId === 'price_1OOCAEAd6uNQtfqaqhBopmgh' || global.userSubPlanId === 'price_1OOCCzAd6uNQtfqalqT5jeD6' || global.userSubStatus === 'trialing') {
      this.props.history.push(ROUTES.ADMINWELCOMEEMAIL)
    } else {
      this.setState({
        showModalWelcome: true,
      })
    }
  }


  hideModals() {
    this.setState({
      showModalMessage: false,
      showModalLocked: false,
      showModalWelcome: false,
      showModalBlacklist: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderList() {
    var list = this.state.emails
    return (
      <div>
        {list.map(item => (
          <ListSimple
            key={item.id}
            link={'/admin/emails/email/'+item.id}
            button='edit'
            columns={[{text: item.data.name, width: 8}, {text: this.getStatus(item), width: 3}]}
          />
        ))}
        <button className="inline-add list mb-10" onClick={() => this.clickNewList()}>{lang.t('admin:button.createanotheremail')}</button>
      </div>
    )
  }


  renderEmails() {
    if(this.state.emails.length > 0) {
      return (
        <div className="col-12">
          <div className="listheader">
            <ListHeader id='emails' number={this.state.emails.length}/>
            <div className="clear"></div>
          </div>
          {this.renderList()}
        </div>
      )
    } else {
      return (
        <div className="col-12">
          <ListEmpty id='noemails' type='simple'/>
        </div>
      )
    }
  }


  renderActions() {
    var item1 = {id: 'newemail', link: ROUTES.ADMINNEWEMAIL}
    if(global.emails.length === 0) {
      item1 = {id: 'newemail', link: ''}
    }
    if(global.spaceWelcome === '') {
      return (
        <div className="col-3">
          <div className='action' onClick={() => this.showModal()}>{lang.t('actions:createnewemail')}</div>
          <div className='action' onClick={() => this.showModalWelcome()}>{lang.t('actions:createawelcomeemail')}</div>
          <div className='action' onClick={() => this.setState({showModalBlacklist: true})}>{lang.t('actions:managenosendlist')}</div>
          <Link to={ROUTES.EMAILUSAGE} className='action'>{lang.t('actions:viewmonthbilling')}</Link>
        </div>
      )
    } else {
      return (
        <WidgetActions
          item1={item1} clickItem1={() => this.showModal()}
          item2={{id: 'emailblacklist', link: ''}} clickItem2={() => this.setState({showModalBlacklist: true})}
          item3={{id: 'emailusage', link: ROUTES.EMAILUSAGE}}
        />
      )
    }
    
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <div className="col-9">
              <WidgetOverview
                item1={{id: 'emailstotal', value: this.state.emails.length, prev: ''}}
                item2={{id: 'emailssent', value: this.getSent(), prev: ''}}
                item3={{id: 'emailsclients', value: this.getClientsSent(), prev: ''}}
                item4={{id: '', value: ''}}
              />
            </div>
            {this.renderActions()}
            {this.renderEmails()}
            <div className="clear sv-80"></div>
          </div>
          <Header type='admin' active='emails'/>
          <Navigation type={lang.t('nav:main.admin')} active='admin' />
          <ModalMessageSimple type='emailinfo' show={this.state.showModalMessage} onHide={() => this.hideModals()} clickMainButton={() => this.props.history.push(ROUTES.ADMINNEWEMAIL)}/>
          <ModalMessageSimple type='emaillocked' show={this.state.showModalLocked} onHide={() => this.hideModals()} clickMainButton={() => this.props.history.push(ROUTES.ACCOUNT)}/>
          <ModalMessageSimple type='welcomelocked' show={this.state.showModalWelcome} onHide={() => this.hideModals()} clickMainButton={() => this.props.history.push(ROUTES.ACCOUNT)}/>
          <ModalBlacklist list={this.state.list} show={this.state.showModalBlacklist} onHide={() => this.hideModals()} clickMainButton={() => this.hideModals()}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='emails-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('nav:main.admin')}/>
          <Navigation active='admin' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['admin','common','header','nav','actions'])(withRouter(withAuthorization(condition)(AdminEmailsPage)));