import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Cal from '../../helper/Calendar';
import HelperClients from '../../helper/Clients';
import HelperBusiness from '../../helper/Business';
import HelperEmptyImg from '../../helper/EmptyLarge';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/clientdetails';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListIcon from '../../components/List/icon';
import ListEmpty from '../../components/List/empty';
import ModalForm from '../../components/Modals/form';
import WidgetChartGraph from '../../components/Widgets/chart-graph';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsSessionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      name: 'Client',
      sessions: global.sessions,
      allsessions: global.sessions,
      archive: global.archive,
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
      events: [],
      eventsFuture: [],
      dateRange: global.dateRange,
      show: 'all',
      value1: 0,
      value2: 0,
      month: Moment().format('MM/YYYY'),
      showModalSessions: false,
      data: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
    };
  }


  componentDidMount() {
    HelperPage.initialize('Sessions - Client Details - PT Mate', global.currentPage)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.configureData()
      if(this.state.id === '') {
        this.getEmpty()
      }
    })
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => {
      this.updateTraining();
      this.getEmpty()
    })
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    if(this.state.sessionsTraining.length === 0) {
      setTimeout(() => {
        this.updateTraining();
      }, 5000);
    }
    this.getEmpty()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getEmpty() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      global.currentClient = arr[arr.length-2]
      this.getClient()
    }
  }


  updateTraining() {
    this.setState({
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
    }, () => {
      if(this.state.sessionsTraining.length === 0) {
        setTimeout(() => {
          this.updateTraining();
        }, 5000);
      }
    })
  }


  getClient() {
    var tmp1 = 0
    var tmp2 = 0

    var id = ''
    var item = null
    var name = ''
    for(var client of global.clients) {
      if(client.id === global.currentClient) {
        if(client.data.prevSessions !== undefined) {
          tmp1 = client.data.prevSessions
        }
        if(client.data.prevSessionsGroup !== undefined) {
          tmp2 = client.data.prevSessionsGroup
        }
        id = client.id
        item = client
        name = client.data.name
      }
    }

    if(item !== null) {
      this.setState({
        id: id,
        item: item,
        name: name,
        value1: tmp1,
        value2: tmp2
      }, () => {
        this.configureData()
        global.linkCli = '/clients/'+id+'/sessions';
      });
    }
    
  }


  configureData() {
    if(this._isMounted) {
      var minDate = parseInt(Moment().add(-31, 'days').format('X'))
      if(this.state.dateRange === '7days') {
        minDate = parseInt(Moment().add(-8, 'days').format('X'))
      } else if(this.state.dateRange === 'month') {
        var subs = Moment().format('DD')
        minDate = parseInt(Moment().add(-parseInt(subs), 'days').format('X'))
      } else if(this.state.dateRange === '60days') {
        minDate = parseInt(Moment().add(-60, 'days').format('X'))
      } else if(this.state.dateRange === 'week') {
        minDate = parseInt(Moment().startOf('week').format('X'))
      } else if(this.state.dateRange === 'all') {
        minDate = parseInt(Moment().add(-10, 'years').format('X'))
      }

      var tmp = []
      var tmp2 = []
      var tmp3 = []
      var isGroup = false

      // Sessions with trainer
      for(var item of global.sessions) {
        isGroup = false
        if(item.data.group && item.data.clients !== undefined) {
          if(item.data.clients.indexOf(this.state.id) !== -1) {
            isGroup = true
          }
        }
        if(parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X')) && parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')) > minDate) {
          if(item.data.client === this.state.id && (this.state.show === 'all' || this.state.show === 'sessions')) {
            tmp.push(item)
          }
          if(isGroup && (this.state.show === 'all' || this.state.show === 'group')) {
            tmp.push(item)
          }
        }
      }
      for(var item2 of global.archive) {
        isGroup = false
        if(item2.data.group && item2.data.clients !== undefined) {
          
          if(item2.data.clients.indexOf(this.state.id) !== -1) {
            isGroup = true
          }
        }

        if(parseInt(Moment(item2.data.date, 'DD/MM/YYYY HH:mm').format('X')) > minDate) {
          if(item2.data.client === this.state.id && (this.state.show === 'all' || this.state.show === 'sessions')) {
            tmp.push(item2)
          }
          if(isGroup && (this.state.show === 'all' || this.state.show === 'group')) {
            tmp.push(item2)
          }
        }
      }

      // Training sessions
      if(this.state.item !== null && (this.state.show === 'all' || this.state.show === 'training')) {
        for(var item3 of global.sessionsTraining) {
          if(parseInt(Moment(item3.data.date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X')) && parseInt(Moment(item3.data.date, 'DD/MM/YYYY HH:mm').format('X')) > minDate && item3.data.attendance === 3) {
            if(item3.data.client === this.state.item.data.uid || item3.data.uid === this.state.item.data.uid) {
              tmp.push(item3)
            }
          }
          
        }
        for(var item4 of global.archiveTraining) {
          if((item4.data.client === this.state.item.data.uid || item4.data.uid === this.state.item.data.uid) && this.state.dateRange === 'all') {
            tmp.push(item4)
          }
        }
      }

      // Other events
      if(this.state.item !== null && (this.state.show === 'all' || this.state.show === 'events')) {
        for(var item5 of global.events) {
          if(parseInt(Moment(item5.data.date, 'DD/MM/YYYY HH:mm').format('X')) < parseInt(Moment().format('X')) && parseInt(Moment(item5.data.date, 'DD/MM/YYYY HH:mm').format('X')) > minDate) {
            if(item5.data.clients !== undefined) {
              if(item5.data.clients.indexOf(this.state.id) !== -1) {
                tmp2.push(item5)
              }
            }
          }
          if(parseInt(Moment(item5.data.date, 'DD/MM/YYYY HH:mm').format('X')) > parseInt(Moment().format('X'))) {
            if(item5.data.clients !== undefined) {
              if(item5.data.clients.indexOf(this.state.id) !== -1) {
                tmp3.push(item5)
              }
            }
          }
        }
      }

      var tmp11 = []
      var tmp21 = []
      var tmp31 = []
      var tmp4 = []
      if(global.loc !== '') {
        for(var t1 of tmp) {
          if(t1.data.location === global.loc) {
            tmp11.push(t1)
          }
        }
        for(var t2 of tmp2) {
          if(t2.data.location === global.loc) {
            tmp21.push(t2)
          }
        }
        for(var t3 of tmp3) {
          if(t3.data.location === global.loc) {
            tmp31.push(t3)
          }
        }
        for(var sess of global.sessions) {
          if(sess.data.location === global.loc) {
            tmp4.push(sess)
          }
        }
      } else {
        tmp11 = tmp
        tmp21 = tmp2
        tmp31 = tmp3
        tmp4 = global.sessions
      }
      
      this.setState({
        sessions: tmp11,
        events: tmp2,
        eventsFuture: tmp31,
        allsessions: tmp4,
        sessionsTraining: global.sessionsTraining,
        archiveTraining: global.archiveTraining
      }, () => {
        this.getGraph()
      })
    }
  }


  getSessionsAttended(type, display) {
    var label = ''
    var num = 0
    var time = 0
    for(var item of this.state.sessions) {
      if(item.data.attendance === 3 && type === 'attended') {
        num++
        time += item.data.duration
      }
      if(item.data.attendance !== 3 && type === 'unattended') {
        num++
        time += item.data.duration
      }
    }
    if(this.state.sessions.length > 0 && type === 'attended') {
      label = num+' '+lang.t('clients:label.attended-s')
    }
    if(this.state.sessions.length > 0 && type === 'unattended') {
      label = num+' '+lang.t('clients:label.notattended-s')
    }
    if(display === 'time') {
      label = ''
      if(num > 0) {
        label = '('+Cal.getDuration(time)+')'
      }
    }
    return label
  }



  // Overview Calculations ------------------------------------------------------------



  setDateRange(event) {
    this.setState({
        dateRange: event
    }, () => {
        this.configureData()
    });
  }


  showSessions(event) {
     this.setState({
        show: event.target.value
    }, () => {
        this.configureData()
    });
  }


  getPrevSessions(type) {
    var count = 0
    if(this.state.item !== null) {
      if(this.state.item.data.prevSessions !== undefined && type === 'sessions') {
        count = this.state.item.data.prevSessions
      } else if(this.state.item.data.prevSessionsGroup !== undefined && type === 'group') {
        count = this.state.item.data.prevSessionsGroup
      }
    }
    return count
  }



  // Sidebar stuff ------------------------------------------------------------



  getDayClass(item, index) {
    var label = 'weekday interactive pending lft'
    var text = ''
    for(var sess of global.sessions) {
      if(global.loc === '' || sess.data.location === global.loc) {
        var clients = []
        if(sess.data.clients !== undefined) {
          clients = sess.data.clients
        }
        if(sess.data.client === this.state.id || clients.indexOf(this.state.id) !== -1) {
          var sd = Moment(sess.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
          var time = Moment(sess.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
          if(sess.data.timestamp !== undefined) {
            sd = Moment(sess.data.timestamp, 'X').format('DD/MM/YYYY')
            time = Moment(sess.data.timestamp, 'X').locale(lang.language).format('LT')
          }
          var name = lang.t('clients:label.11session')
          if(sess.data.group) {
            name = lang.t('clients:label.groupsession')
            if(sess.data.client !== "") {
              name = sess.data.client
            }
            if(sess.data.availability) {
              name = lang.t('clients:label.available11session')
            }
          }
          if(sd === item) {
            label = 'weekday interactive done lft'
            if(sess.data.attendance === 4 && Moment(sd, 'DD/MM/YYYY') < Moment()) {
              label = 'weekday interactive fail lft'
            }
            if(Moment(sd, 'DD/MM/YYYY') > Moment()) {
              label = 'weekday interactive active lft'
            }
            text += name+" "+time+"\n"
          }
        }
      }
    }
    for(var ev of global.events) {
      if(global.loc === '' || ev.data.location === global.loc) {
        var clients2 = []
        if(ev.data.clients !== undefined) {
          clients2 = ev.data.clients
        }
        if(clients2.indexOf(this.state.id) !== -1) {
          var sd2 = Moment(ev.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
          var time2 = Moment(sess.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
          if(ev.data.timestamp !== undefined) {
            sd2 = Moment(ev.data.timestamp, 'X').format('DD/MM/YYYY')
            time2 = Moment(sess.data.timestamp, 'X').locale(lang.language).format('LT')
          }
          if(sd2 === item) {
            label = 'weekday interactive done lft'
            if(Moment(sd2, 'DD/MM/YYYY') > Moment()) {
              label = 'weekday interactive active lft'
            }
            text += ev.data.name+" "+time2+"\n"
          }
        }
      }
    }
    if(text === '') {
      return (
        <div className={label} key={item}>{index+1}</div>
      )
    } else {
      return <div className={label} key={item}>{index+1}<div className="tooltip">{text}</div></div>
    }
  }


  setMonth(type) {
    var tmp = Moment(this.state.month, 'MM/YYYY')
    if(type === 'prev') {
      if(Moment(this.state.month, 'MM/YYYY') > Moment().add(-1, 'months')) {
        tmp = Moment(this.state.month, 'MM/YYYY').add(-1, 'months')
      }
    } else {
      if(Moment(this.state.month, 'MM/YYYY') < Moment().add(1, 'months')) {
        tmp = Moment(this.state.month, 'MM/YYYY').add(1, 'months')
      }
    }
    this.setState({
      month: tmp.format('MM/YYYY')
    })
  }


  getGraph() {
    var start = Moment().add(-30, 'days').startOf('week')
    var end = Moment().add(1, 'week').startOf('week')
    var now = parseInt(Moment().format('X'))
    

    if(this.state.dateRange === '7days') {
      start = Moment().add(-7, 'days').startOf('week')
    }
    if(this.state.dateRange === '60days') {
      start = Moment().add(-60, 'days').startOf('week')
    }
    if(this.state.dateRange === 'month') {
      var mnth = Moment().format('MM/YYYY')
      start = Moment('01/'+mnth, 'DD/MM/YYYY').startOf('week')
    }
    if(this.state.dateRange === 'week') {
      start = Moment().startOf('week')
    }

    var weeks = end.diff(start, 'weeks')

    var tmp = []
    var tmpg = []
    var tmpp = []
    var totalg = 0
    var totalp = 0

    for(var i=0; i<parseInt(weeks); i++) {
      var num = 0
      var numg = 0
      var nump = 0

      for(var item of global.sessions) {
        var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY').format('X'))
        if(date >= Moment(start).format('X') && date >= Moment(start).add(7*i, 'days').format('X') && date < Moment(start).add(7*i+7, 'days').format('X') && date < now) {
          if(item.data.group) {
            if(item.data.clients !== undefined) {
              if(item.data.clients.indexOf(this.state.id) !== -1) {
                numg++
                totalg++
                num++
              }
            }
          } else {
            if(item.data.client === this.state.id) {
              nump++
              totalp++
              num++
            }
          }
        }
      }

      tmp.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": num,
      })
      tmpg.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numg,
      })
      tmpp.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": nump,
      })
    }

    var tmpData = [{
      "id": "Total",
      "color": global.themeColors[2],
      data: tmp
    },
    {
      "id": "Group",
      "color": global.themeColors[0],
      data: tmpg
    },
    {
      "id": "1:1",
      "color": global.themeColors[1],
      data: tmpp
    }]

    this.setState({
      data: tmpData
    })
  }



  // Modal stuff ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalSessions: false,
    })
  }


  updateAdded() {
    var val1 = 0
    var val2 = 0
    if(this.state.value1 !== '') {
      val1 = parseInt(this.state.value1)
    }
    if(this.state.value2 !== '') {
      val2 = parseInt(this.state.value2)
    }
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id).update({
      prevSessions: val1,
      prevSessionsGroup: val2
    }).then(() => {
      this.hideModals()
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.sessionsupdated'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderGraph() {
    return (
      <div>
        <h3>{lang.t('clients:label.weeklysessions')}</h3>
        <div className="sv-30"></div>
        <WidgetChartGraph data={this.state.data}/>
        <div className="clear mb-50"></div>
      </div>
    )
  }


  renderCalendar() {
    if(this.state.item !== null) {
      var d1 = Moment('01/'+this.state.month, 'DD/MM/YYYY').format('d')
      var d2 = Moment('01/'+this.state.month, 'DD/MM/YYYY').endOf('month').format('D')
      var list1 = []
      var list2 = []
      if(parseInt(d1) === 0) {
        d1 = 7
      }
      for(var i1=1; i1<parseInt(d1); i1++) {
        list1.push('')
      }
      for(var i2=1; i2<(parseInt(d2)+1); i2++) {
        list2.push(Moment(i2+'/'+this.state.month, 'D/MM/YYYY').format('DD/MM/YYYY'))
      }
      return (
        <div className="calmini">
          <div className="calmini-header mb-20">
            <div className="arrow lft" onClick={() => this.setMonth('prev')}>‹</div>
            <div className="arrow rgt" style={{float:'right'}} onClick={() => this.setMonth('next')}>›</div>
            <h3 className="lft">{Moment(this.state.month, 'MM/YYYY').locale(lang.language).format('MMMM YYYY')}</h3>
            <div className="clear"></div>
          </div>
          {list1.map(item => (
            <div className="weekday lft" key={item}></div>
          ))}
          {list2.map((item,index) => (
            <div>{this.getDayClass(item, index)}</div>
          ))}
          <div className="clear mb-50"></div>
        </div>
      )
    }
  }


  renderFilters() {
    return (
      <div className="listheader clear">
        <h3 className="lft mt-5">{lang.t('header:title.sessionhistory')}</h3>
        <select className="light rgt" onChange={event => this.showSessions(event)} value={this.state.show}>
          <option value='all'>{lang.t('clients:label.showall')}</option>
          <option value='sessions'>{lang.t('clients:label.11sessionsonly')}</option>
          <option value='group'>{lang.t('clients:label.groupsessionsonly')}</option>
          <option value='training'>{lang.t('clients:label.trainingsessionsonly')}</option>
          <option value='events'>{lang.t('clients:label.eventsonly')}</option>
        </select>
        <div className="clear"></div>
        <h3 className="subline">{this.getSessionsAttended('attended', 'total')} {this.getSessionsAttended('attended', 'time')} {this.state.sessions.length > 0 ? '-' : ''} {this.getSessionsAttended('unattended', 'total')} {this.getSessionsAttended('unattended', 'time')}</h3>
      </div>
    )
  }


  renderSessionType(item) {
    if(item.data.client !== global.currentClient && !item.data.group) {
      return ['training','tertiary']
    } else {
      if(item.data.group && item.data.availability === undefined) {
        return ['group','primary']
      } else {
        return ['session','secondary']
      } 
    }
  }


  renderSessionTypeText(item) {
    if(item.data.client !== global.currentClient && !item.data.group) {
      return lang.t('clients:label.trainingsession')
    } else {
      if(item.data.group) {
        if(item.data.availability === undefined) {
          if(item.data.client === '') {
            return lang.t('clients:label.groupsession')
          } else {
            return item.data.client
          }
        } else {
          return lang.t('clients:label.available11session')
        }
      } else {
        return lang.t('clients:label.11session')
      } 
    }
  }


  renderAttendance(item) {
    if(item.data.attendance === 0 || item.data.attendance === 3) {
      return (
        <span className="pill green">{lang.t('clients:label.attended')}</span>
      )
    } else if(item.data.attendance === 1 || item.data.attendance === 4) {
      return (
        <span className="pill red">{lang.t('clients:label.notattended')}</span>
      )
    } else {
      if(item.data.group) {
        return (
          <span className="pill">{lang.t('clients:label.confirmattendance')}</span>
        )
      } else {
        return (
          <span className="pill">{lang.t('clients:label.confirmattendance')}</span>
        )
      }
    }
  }


  renderUpcoming() {
    var list = []
    var list2 = this.state.eventsFuture
    for(var item of this.state.allsessions) {
      if(!item.data.group && item.data.client === this.state.id && Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment() && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(30, 'days')) {
        list.push(item)
      }
      if(item.data.group && item.data.clients !== undefined && Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment() && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(30, 'days')) {
        if(item.data.clients.indexOf(this.state.id) !== -1) {
          list.push(item)
        }
      }
    }
    if(list.length > 0 || list2.length > 0) {
      list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div>
          <div className="listheader clear">
            <h3>{lang.t('header:title.upcomingsessions')}</h3>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <ListIcon 
              key={item.id}
              icon={this.renderSessionType(item)[0]}
              color={this.renderSessionType(item)[1]}
              link={!item.data.group ? '/calendar/session/'+item.id : (item.data.availability === undefined ? '/calendar/group-session/'+item.id : '/calendar/availability/'+item.id)}
              columns={[{text: Cal.getSpecialDate(item, false), width: 5}, {text: this.renderSessionTypeText(item), width: 3}, {text: (item.data.group ? lang.t('clients:label.bookedin') : ''), width: 2}]}
            />
          ))}
          {list2.map(item => (
            <ListIcon 
              key={item.id}
              icon={' event'}
              color='quarternary'
              link={'/calendar/event/'+item.id}
              columns={[{text: Cal.getSpecialDate(item, false), width: 5}, {text: item.data.title+' ('+lang.t('clients:label.event')+')', width: 3}, {text: lang.t('clients:label.bookedin'), width: 2}]}
            />
          ))}
        </div>
      )
    }
  }


  renderSessions() {
    var list = this.state.sessions
    list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
    var list2 = this.state.events
    list2.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
    return (
      <div>
        {list.map(item => (
          <ListIcon 
            key={item.id}
            icon={this.renderSessionType(item)[0]}
            color={this.renderSessionType(item)[1]}
            link={!item.data.group ? '/calendar/session/'+item.id : (item.data.availability === undefined ? '/calendar/group-session/'+item.id : '/calendar/availability/'+item.id)}
            columns={[{text: Cal.getSpecialDate(item, false), width: 5}, {text: this.renderSessionTypeText(item), width: 3}, {text: this.renderAttendance(item), width: 2}]}
          />
        ))}
        {list2.map(item => (
          <ListIcon 
            key={item.id}
            icon={' event'}
            color='quarternary'
            link={'/calendar/event/'+item.id}
            columns={[{text: Cal.getSpecialDate(item, false), width: 5}, {text: item.data.title+' ('+lang.t('clients:label.event')+')', width: 3}, {text: this.renderAttendance(item), width: 2}]}
          />
        ))}
      </div>
    )
  }


  getPastTime() {
    var label = lang.t('clients:empty.sessions.title1')
    if(this.state.dateRange === '7days') {
      label = lang.t('clients:empty.sessions.title2')
    } else if(this.state.dateRange === 'month') {
      label = lang.t('clients:empty.sessions.title3')
    } else if(this.state.dateRange === '60days') {
      label = lang.t('clients:empty.sessions.title4')
    } else if(this.state.dateRange === 'week') {
      label = lang.t('clients:empty.sessions.title5')
    }
    return label
  }


  renderBottom() {
    if(this.state.sessions.length === 0 && this.state.events.length === 0) {
      return (
        <div className="clear col-12">
          {this.renderUpcoming()}
          {this.renderFilters()}
          <div className="empty large">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('calendar')}
            </div>
            <div className="data">
              <h3 className="mb-20">{lang.t('clients:empty.sessions.title')} {this.getPastTime()}</h3>
              <p className="mb-20">{lang.t('clients:empty.sessions.text')}</p>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="clear col-9 withsidebar">
          {this.renderUpcoming()}
          {this.renderFilters()}
          {this.renderSessions()}
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderSidebar() {
    if(this.state.sessions.length !== 0 || this.state.events.length !== 0) {
      return (
        <div className="col-3">
          {this.renderCalendar()}
          {this.renderGraph()}
        </div>
      )
    }
  }


  renderButtonBefore() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <button className="btn tertiary small edit rgt" onClick={() => this.setState({showModalSessions: true})}>{lang.t('common:button.edit')}</button>
      )
    }
  }


  renderBefore() {
    var sessions = this.getPrevSessions('sessions')
    var group = this.getPrevSessions('group')
    var label = lang.t('clients:label.beforeptmate1')
    if(sessions > 0 && group === 0) {
      label = sessions+lang.t('clients:label.beforeptmate2')
      if(sessions === 1) {
        label = lang.t('clients:label.beforeptmate3')
      }
    } else if(sessions === 0 && group > 0) {
      label = group+lang.t('clients:label.beforeptmate4')
      if(group === 1) {
        label = lang.t('clients:label.beforeptmate5')
      }
    } else if(sessions > 0 && group > 0) {
      label = sessions+lang.t('clients:label.beforeptmate6')+group+lang.t('clients:label.beforeptmate7')
    }
    return label
  }


  renderActions() {
    var item1 = {id: 'clientdetails', link: '/clients/'+this.state.id+'/details'}
    var item2 = {id: '', link: ''}
    if(HelperBusiness.getRoleIncl('trainer')) {
      item1 = {id: 'cal11', link: '/calendar/'+this.state.id+'/new-session'}
      item2 = {id: 'rec11client', link: ROUTES.NEWRECSESSION}
    }
    return (
      <WidgetActions
        item1={item1}
        item2={item2}
        item3={{id: '', link: ''}}
      />
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('assistant,assistant2,trainer,admin,cs') && this.state.item !== null) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            {HelperClients.getFamily(this.state.item)}
            <div className="col-9">
              <WidgetOverview
                item1={{id: 'sessions', value: Cal.getAmountClient(global.sessions, global.archive, 'sessions', this.state.dateRange, this.state.id), prev: Cal.getDifferenceClient(global.sessions, false, this.state.dateRange, global.currentClient, 'num')}}
                item2={{id: 'group', value: Cal.getAmountClient(global.sessions, global.archive, 'group', this.state.dateRange, this.state.id), prev: Cal.getDifferenceClient(global.sessions, true, this.state.dateRange, global.currentClient, 'num')}}
                item3={{id: 'training', value: Cal.getAmountClient(global.sessionsTraining, this.state.archiveTraining, 'sessions', this.state.dateRange, this.state.item.data.uid), prev: Cal.getDifferenceClient(global.sessionsTraining, false, this.state.dateRange, this.state.item.data.uid, 'num')}}
                item4={{id: '', value: ''}}
              />
            </div>
            {this.renderActions()}
            {this.renderBottom()}
            {this.renderSidebar()}
            <div className="sv-40 clear"></div>
            <div className={(this.state.sessions.length === 0 && this.state.events.length === 0) ? "col-12" : "col-9 withsidebar"}>
              <div className="box list simple highlight">
                <div className="col-9">
                  <p><strong>{this.renderBefore()}</strong></p>
                </div>
                {this.renderButtonBefore()}
              </div>
              <div className="sv-20 clear"></div>
              <p className="small center secondary">{lang.t('clients:label.sessioninfo')}</p>
              <div className="sv-40 clear"></div>
            </div>
          </div>
          <Header client={this.state.item} link={global.currentPage} active='sessions' selector={true} updateSelect={(event) => this.setDateRange(global.dateRange)}/>
          <Navigation active='clients' />
          <ModalForm type={'prevsessions'} show={this.state.showModalSessions} onHide={() => this.hideModals()} value1={this.state.value1} updateValue1={(event) => this.setState({value1: event})} value2={this.state.value2} updateValue2={(event) => this.setState({value2: event})} clickMainButton={() => this.updateAdded()}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <ListEmpty id='sessions-locked' type='simple'/>
          </div>
          <Header client={this.state.item} link={global.currentPage} active='sessions' selector={true} updateSelect={() => this.setState({dateRange: global.dateRange})}/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','modals','messaging'])(withRouter(withAuthorization(condition)(ClientsSessionsPage)));