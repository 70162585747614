import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import HelperClients from '../../helper/Clients';
import HelperBilling from '../../helper/Billing';
import HelperBusiness from '../../helper/Business';
import HelperEmpty from '../../helper/Empty';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/clientdetails';
import WidgetActions from '../../components/Widgets/actions';
import WidgetCard from '../../components/Widgets/card';
import ListHeader from '../../components/List/header';
import ListIcon from '../../components/List/icon';
import ListEmpty from '../../components/List/empty';
import Loader from '../../components/Form/loader';
import ModalPayment from '../../components/Modals/payment';
import ModalCard from '../../components/Modals/card';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalTrial from '../../components/Modals/trial';
import ModalItems from '../../components/Modals/items';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsBillingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      name: 'Client',
      payments: global.payments,
      products: global.products,
      debits: [],
      dateRange: global.dateRange,
      show: 'all',
      showModal: false,
      hidden: 'hidden',
      value1: 0,
      value2: 0,
      value3: 0,
      value4: 0,
      valuep1: 0,
      valuep2: 0,
      valuep3: 0,
      valuep4: 0,
      // Sessions numbers
      oltype: '11',
      values1: [],
      values2: [],
      subs: [],
      account: '',
      cards: [],
      expired: [],
      month: Moment().format('MM/YYYY'),
      // Modals
      currentPayment: null,
      loading: false,
      margin: 0,
      deletingBilling: false,
      stripe: global.userStripeConnect,
      hasTrial: false,
      trialDate: Moment().add(7, 'days').format('X'),
      showModalFee: false,
      hiddenFee: 'hidden',
      showModalPayment: false,
      showModalCard: false,
      showModalBilling: false,
      showModalTrial: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Billing - Client Details - PT Mate', global.currentPage)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.configureData()
      if(this.state.id === '') {
        this.getEmpty()
      }
    })
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.getEmpty())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('imageLoaded', (event) => this.getClient())
    EventEmitter.subscribe('logLoaded', (event) => {
      this.configureLog()
      this.configureData()
    })
    this.getEmpty()

    // Check for fee paymnet
    EventEmitter.subscribe('paymentsLoaded', (event) => {
      this.configureData()
      if(this.state.loading) {
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.paymentsuccessful'));
        this.setState({
          loading: false
        })
      }
    })
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false
    })
  }


  getEmpty() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      global.currentClient = arr[arr.length-2]
      this.getClient()
    }
  }


  getClient() {
    if(this._isMounted) {
      var tmp1 = 0
      var tmp1a = 0
      var tmp2 = 0
      var tmp2a = 0
      var tmp3 = []
      var id = ''
      var item = null
      var name = ''
      var cards = []
      var hastrial = false
      var trialdate =  Moment().add(7, 'days').format('X')
      for(var client of global.clients) {
        if(client.id === global.currentClient) {
          if(client.credits.length > 0) {
            for(var cred of client.credits) {
              if(!cred.data.group && cred.data.sessionsTotal > cred.data.sessionsPaid) {
                tmp1 += cred.data.sessionsTotal
                tmp1a += cred.data.sessionsPaid
              } else if(cred.data.group && cred.data.sessionsTotal > cred.data.sessionsPaid) {
                tmp2 += cred.data.sessionsTotal
                tmp2a += cred.data.sessionsPaid
              }
            }
          }
          if(client.subscriptions !== undefined) {
            for(var sub of client.subscriptions) {
              tmp3.push(sub)
              if(sub.data.name === lang.t('clients:label.freetrial')) {
                hastrial = true
                trialdate = Moment(sub.data.next, 'X')
              }
            }
          }
          if(client.data.cardId !== undefined && client.data.cardId !== '') {
            cards.push({id: client.data.cardId, data: {brand: client.data.cardBrand, last4: client.data.cardLast4}})
          }
          id = client.id
          item = client
          name = client.data.name
        }
      }

      if(item !== null) {
        this.setState({
          id: id,
          item: item,
          name: name,
          value1: tmp1,
          value2: tmp1a,
          value3: tmp2,
          value4: tmp2a,
          debits: tmp3,
          active: true,
          account: '',
          cards: cards,
          hasTrial: hastrial,
          trialDate: trialdate
        }, () => {
          this.configureData()
          global.linkCli = '/clients/'+id+'/billing';
        });
      }
    }
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var minDate = Moment().add(-31, 'days').format('X')
      if(this.state.dateRange === '7days') {
        minDate = Moment().add(-8, 'days').format('X')
      } else if(this.state.dateRange === 'month') {
        var dt = parseInt(Moment().format('D'))
        minDate = Moment().add(-(dt+1), 'days').format('X')
      } else if(this.state.dateRange === 'week') {
        minDate = Moment().startOf('week').format('X')
      } else if(this.state.dateRange === '60days') {
        minDate = Moment().add(-90, 'years').format('X')
      }
      for(var item of global.payments) {
        if(this.state.dateRange === '60days') {
          if(item.data.client === this.state.id) {
            tmp.push(item)
          }
        } else {
          if(item.data.client === this.state.id && Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') > minDate) {
            tmp.push(item)
          }
        }
      }
      this.setState({
        payments: tmp,
        products: global.products,
        stripe: global.userStripeConnect,
      })
    }
  }


  configureLog() {
    if(this._isMounted) {
      for(var item of global.userLog) {
        if(item.data.title === 'debiterror' || item.data.title === 'debitcancelerror' || item.data.title === 'cardnewerror' || item.data.title === 'carddeleteerror') {
          EventEmitter.dispatch('showMessageError', item.data.message);
          Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
          this.setState({
            loading: false,
          })
        }
        if(item.data.title === 'cardcreated' || item.data.title === 'carddeleted') {
          EventEmitter.dispatch('showMessage', lang.t('messaging:message.carddetailsupdated'));
          Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
          this.setState({
            loading: false,
          })
        }
        if(this.state.loading) {
          if(item.data.title === 'chargeerror') {
            EventEmitter.dispatch('showMessageError', item.data.message);
            Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
            this.setState({
              loading: false,
            })
          }
          if(item.data.title === 'charge' || item.data.type === 'charge') {
            EventEmitter.dispatch('showMessage',lang.t('messaging:message.paymentsuccessful'));
            this.setState({
              loading: false,
            })
          }
        }
      }
    }
  }



  // Overview Calculations ------------------------------------------------------------



  setDateRange() {
    this.setState({
        dateRange: global.dateRange
    }, () => {
        this.configureData()
    });
  }


  setFamilyAccount(event) {
    var client = event
    if(event === 'all') {
      client = ''
    }
    this.setState({
      account: client
    }, () => {
      this.getSessions()
  });
  }


  getSessions(group) {
    var count = 0
    var status = lang.t('clients:label.available')
    var style = ''
    var add = 0
    var family = false

    if(this.state.item !== null) {
      if(this.state.item.data.linked !== undefined) {
        if(this.state.item.data.linked.length > 0) {
          family = true
        }
      }

      var valid = ["", this.state.account, undefined]
        if(family) {
          if(this.state.account === "") {
            valid = ["", undefined]
          } else {
            valid = [this.state.account]
          }
        }

      if(this.state.item !== null) {
        add = HelperBilling.getClientSessionDebit(this.state.item, group, this.state.account, true, [])
        if(add === undefined) {
          add = 0
        }
        if(this.state.item.credits !== null) {
          for(var item of this.state.item.credits) {
            if(item.data.group === group && valid.indexOf(item.data.account) !== -1) {
              count += item.data.sessionsPaid-item.data.sessionsTotal
            }
          }
        }
        if(this.state.item.creditse !== null) {
          for(var item2 of this.state.item.creditse) {
            if(item2.data.group === group && Moment(item2.data.expires, 'X') > Moment() && valid.indexOf(item2.data.account) !== -1) {
              count += item2.data.sessionsPaid-item2.data.sessionsTotal
            }
          }
        }

        count += add
      }
    }
    if(count === 1) {
      style = 'yellow'
    }
    if(count > 1) {
      style = 'green'
    }
    if(count < 0) {
      count = -count
      status = lang.t('clients:label.unpaid')
      style = 'red'
    }
    if(isNaN(count)) {count = 0}
    return [count, status, style]
  }


  getBilling(item) {
    var label = item.data.billing
    if(label === "week") {
      for(var prod of global.products) {
        if(prod.id === item.data.plan) {
          label = prod.data.billing
        }
      }
    }
    return lang.t('clients:label.per'+label)
  }



  // Sessions modal ------------------------------------------------------------



  showModal(type) {
    var tmp1 = 0
    var tmp2 = 0
    var tmp3 = 0
    var tmp4 = 0
    var tmpp1 = 0
    var tmpp2 = 0
    var tmpp3 = 0
    var tmpp4 = 0
    var tmpe1 = []
    var tmpe2 = []
    var tmps = []

    for(var item of this.state.item.credits) {
      if(item.data.account === '' || item.data.account  === undefined) {
        if(!item.data.group) {
          tmp1 += item.data.sessionsTotal
          tmp2 += item.data.sessionsPaid
        } else {
          tmp3 += item.data.sessionsTotal
          tmp4 += item.data.sessionsPaid
        }
      }
      if(item.data.account === this.state.account && this.state.account !== '') {
        if(!item.data.group) {
          tmpp1 += item.data.sessionsTotal
          tmpp2 += item.data.sessionsPaid
        } else {
          tmpp3 += item.data.sessionsTotal
          tmpp4 += item.data.sessionsPaid
        }
      }
    }
    for(var item2 of this.state.item.creditse) {
      if(Moment(item2.data.expires, 'X') > Moment()) {
        if(item2.data.account === '' || item2.data.account  === undefined || (item2.data.account === this.state.account && this.state.account !== '')) {
          if(!item2.data.group) {
            tmpe1.push({
              id: item2.id, data: {sessionsTotal: item2.data.sessionsTotal, sessionsPaid: item2.data.sessionsPaid, account: item2.data.account, group: item2.data.group, expires: item2.data.expires}
            })
          } else {
            tmpe2.push({
              id: item2.id, data: {sessionsTotal: item2.data.sessionsTotal, sessionsPaid: item2.data.sessionsPaid, account: item2.data.account, group: item2.data.group, expires: item2.data.expires}
            })
          }
        }
      }
    }
    for(var sub of this.state.item.subscriptions) {
      if((sub.data.account === '' || sub.data.account  === undefined || (sub.data.account === this.state.account && this.state.account !== '')) && sub.data.status !== 'trialing') {
        if((type === '11' && !sub.data.group) || (type === 'group' && sub.data.group) || sub.data.is11) {
          tmps.push(sub)
        }
      }
    }
    this.setState({
      showModal: true,
      value1: tmp1,
      value2: tmp2,
      value3: tmp3,
      value4: tmp4,
      valuep1: tmpp1,
      valuep2: tmpp2,
      valuep3: tmpp3,
      valuep4: tmpp4,
      oltype: type,
      values1: tmpe1,
      values2: tmpe2,
      subs: tmps,
    })
    setTimeout(() => {
      this.setState({hidden: ''});
    }, 100);
  }


  hideModal() {
    this.setState({hidden: 'hidden'})
    setTimeout(() => {
      this.setState({showModal: false});
    }, 500);
  }


  closeModal() {
    this.hideModal()
  }


  getMemberName() {
    var label = lang.t('modals:clientbilling.allfamilymembers')
    for(var item of global.clients) {
      if(item.id === this.state.account) {
        label = item.data.name
      }
    }
    if(this.state.item.data.linked === undefined) {
      label = this.state.item.data.name
    }
    return label
  }


  getListFamily(item) {
    var label = ''
    if(this.state.item.data.linked !== undefined) {
      if(this.state.item.data.linked.length > 0) {
        label = lang.t('modals:clientbilling.forallfamilymembers')
        if(item.data.account !== '' && item.data.account !== undefined) {
          label = lang.t('modals:clientbilling.for')+' '+this.getMemberName()
        }
      }
    }
    return label
  }


  renderSubInfo(item) {
    var sessions = item.data.sessions
      if(this.state.oltype === '11' && item.data.is11 && item.data.is11 !== undefined) {
        sessions = item.data.sessions11
      }
      if(sessions === 0) {
        return lang.t('modals:clientbilling.unlimitedsessions')
      } else {
        return sessions+' '+(sessions === 1 ? lang.t('modals:clientbilling.session') :  lang.t('modals:clientbilling.sessions'))+' '+lang.t('modals:per'+item.data.billing)
      }
  }


  renderDebitUsed(item) {
    if(item.data.sessions !== 0 && item.data.sessions !== undefined) {
      return (
        <span> ({(item.data.is11 && this.state.oltype === '11') ? item.data.done11 : item.data.done} used)</span>
      )
    }
  }


  renderModalDebits() {
    var list = []
    for(var sub of this.state.subs) {
      if(this.state.item.data.linked !== undefined) {
        if(sub.data.account === this.state.account) {
          list.push(sub)
        }
      } else {
        list.push(sub)
      }
    }
    if(list.length > 0) {
      return (
        <div className="pt-20">
          <h4 className="mb-10">{lang.t('modals:clientbilling.activememberships')}</h4>
          <div>
            {list.map((item, index) => (
              <div className="list bare" key={item.id}>
                <div className="icon secondary">
                  <div className="inner refresh"></div>
                </div>
                <div className="main">
                  <h4>{item.data.name}</h4>
                  <p>{this.renderSubInfo(item)} {this.getListFamily(item)}{this.renderDebitUsed(item)}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        </div>
      )
    }
  }


  getClass(item, type) {
    var label = 'section'
    if(type === 'pack') {
      if(this.state.item.data.linked !== undefined) {
        if(this.state.item.data.linked.length > 0 && item.data.account !== this.state.account) {
          label = 'none'
        }
      } 
    }
    return label
  }


  renderModalList() {
    var list = this.state.values1
    if(this.state.oltype === 'group') {
      list = this.state.values2
    }
    if(list.length > 0) {
      return (
        <div>
          <h4 className="pt-10 mb-20">{lang.t('modals:clientbilling.sessionpacks')}</h4>
          {list.map((item, index) => (
            <div key={item.id} className={this.getClass(item, 'pack')}>
              <p className="mb-10"><strong>{item.data.sessionsPaid} {lang.t('modals:clientbilling.sessions')}</strong></p>
              <div className="form">
                <div className="col-6">
                  <label style={{marginTop: 0}}>{lang.t('modals:clientbilling.expirydate')}</label>
                  <input type="date" value={Moment(item.data.expires, 'X').format('YYYY-MM-DD')} name={index} onChange={this.onChangeDate}/>
                </div>
                <div className="col-6">
                  <label style={{marginTop: 0}}>{lang.t('modals:clientbilling.sessionsused')}</label>
                  <input type="number" value={item.data.sessionsTotal} name={index} onChange={this.onChangeExpiry}/>
                </div>
              </div>
              <div className="clear sv-20"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderMemberPack() {
    if(this.state.item.data.linked === undefined || this.state.item.data.linked.length === 0) {
      // keep empty
    } else {
      if(this.state.oltype === '11') {
        if(this.state.account !== '') {
          return (
            <div>
              <div className="clear"></div>
              <div className="form">
                <div className="col-6">
                  <label style={{marginTop: 0}}>{lang.t('modals:clientbilling.available')}</label>
                  <input type="number" value={this.state.valuep2} name="valuep2" onChange={this.onChange}/>
                </div>
                <div className="col-6">
                  <label style={{marginTop: 0}}>{lang.t('modals:clientbilling.used')}</label>
                  <input type="number" value={this.state.valuep1} name="valuep1" onChange={this.onChange}/>
                </div>
              </div>
            </div>
          )
        }
      } else {
        if(this.state.account !== '') {
          return (
            <div>
              <div className="clear"></div>
              <div className="form">
                <div className="col-6">
                  <label style={{marginTop: 0}}>{lang.t('modals:clientbilling.available')}</label>
                  <input type="number" value={this.state.valuep4} name="valuep4" onChange={this.onChange}/>
                </div>
                <div className="col-6">
                  <label style={{marginTop: 0}}>{lang.t('modals:clientbilling.used')}</label>
                  <input type="number" value={this.state.valuep3} name="valuep3" onChange={this.onChange}/>
                </div>
              </div>
            </div>
          )
        }
      }
    }
  }


  getTotal() {
    var label = lang.t('modals:clientbilling.noavailablesessions')
    var style = ''
    var v1 = 0
    var v2 = 0
    var add = 0
    var add1 = 0
    var add2 = 0
    if(this.state.oltype === '11') {
      add = HelperBilling.getClientSessionDebit(this.state.item, false, this.state.account, true, [])
      v1 = parseInt(this.state.value1)+parseInt(this.state.valuep1)
      v2 = parseInt(this.state.value2)+parseInt(this.state.valuep2)+add
      if(this.state.item.data.linked !== undefined) {
        v1 = parseInt(this.state.valuep1)
        v2 = parseInt(this.state.valuep2)+add
      }
      for(var i1 of this.state.values1) {
        v1 += parseInt(i1.data.sessionsTotal)
        v2 += parseInt(i1.data.sessionsPaid)
      }
      // Family accounts
      if(this.state.item.data.linked !== undefined) {
        var adda1 = HelperBilling.getClientSessionDebit(this.state.item, false, '', true, [])
        if(this.state.account === '') {
          v1 = parseInt(this.state.value1)
          v2 = parseInt(this.state.value2)+add
        } else {
          v1 = parseInt(this.state.valuep1)
          v2 = parseInt(this.state.valuep2)+add
          add1 = parseInt(this.state.value1)
          add2 = parseInt(this.state.value2)+adda1
        }
        for(var i11 of this.state.values1) {
          if(i11.data.account === this.state.account) {
            v1 += parseInt(i11.data.sessionsTotal)
            v2 += parseInt(i11.data.sessionsPaid)
          }
          if(i11.data.account === '' && this.state.account !== '') {
            add1 += parseInt(i11.data.sessionsTotal)
            add2 += parseInt(i11.data.sessionsPaid)
          }
        }
      }
    } else {
      add = HelperBilling.getClientSessionDebit(this.state.item, true, this.state.account, true, [])
      v1 = parseInt(this.state.value3)+parseInt(this.state.valuep3)
      v2 = parseInt(this.state.value4)+parseInt(this.state.valuep4)+add
      for(var i2 of this.state.values2) {
        v1 += parseInt(i2.data.sessionsTotal)
        v2 += parseInt(i2.data.sessionsPaid)
      }
      // Family accounts
      if(this.state.item.data.linked !== undefined) {
        var adda2 = HelperBilling.getClientSessionDebit(this.state.item, true, '', true, [])
        if(this.state.account === '') {
          v1 = parseInt(this.state.value3)
          v2 = parseInt(this.state.value4)+add
        } else {
          v1 = parseInt(this.state.valuep3)
          v2 = parseInt(this.state.valuep4)+add
          add1 = parseInt(this.state.value3)
          add2 = parseInt(this.state.value4)+adda2
        }
        for(var i21 of this.state.values2) {
          if(i21.data.account === this.state.account) {
            v1 += parseInt(i21.data.sessionsTotal)
            v2 += parseInt(i21.data.sessionsPaid)
          }
          if(i21.data.account === '' && this.state.account !== '') {
            add1 += parseInt(i21.data.sessionsTotal)
            add2 += parseInt(i21.data.sessionsPaid)
          }
        }
      }
    }
    var total = v1-v2
    var additional = add2-add1
    if(v1 > v2) {
      label = total+' '+(total === 1 ? lang.t('modals:clientbilling.unpaidsession') : lang.t('modals:clientbilling.unpaidsessions'))
      style = 'red'
    }
    if(v2 > v1) {
      label = -total+' '+(total === -1 ? lang.t('modals:clientbilling.sessionavailable') : lang.t('modals:clientbilling.sessionsavailable'))
      style = 'green'
    }
    if(total === -1) {
      style = 'yellow'
    }
    return [label, style, additional]
  }


  renderOLBase() {
    if(this.state.item.data.linked === undefined || this.state.account === '') {
      if(this.state.oltype === '11') {
        return (
          <div className="form-item">
            <div className="col-6">
              <label>{lang.t('modals:clientbilling.available')}</label>
              <input type="number" value={this.state.value2} name="value2" onChange={this.onChange}/>
            </div>
            <div className="col-6">
              <label>{lang.t('modals:clientbilling.used')}</label>
              <input type="number" value={this.state.value1} name="value1" onChange={this.onChange}/>
            </div>
          </div>
        )
      } else {
        return (
          <div className="form-item">
            <div className="col-6">
              <label>{lang.t('modals:clientbilling.available')}</label>
              <input type="number" value={this.state.value4} name="value4" onChange={this.onChange}/>
            </div>
            <div className="col-6">
              <label>{lang.t('modals:clientbilling.used')}</label>
              <input type="number" value={this.state.value3} name="value3" onChange={this.onChange}/>
            </div>
          </div>
        )
      }
    }
  }


  renderModal() {
    if(this.state.showModal) {
      if(this.state.oltype === '11') {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box clients">
              <h2 className="mb-10 lft">{lang.t('modals:items.11sessions')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hideModal()}>{lang.t('common:button.close')}</button>
              <p className="clear mb-20"><strong>{lang.t('modals:clientbilling.availableto')} {this.getMemberName()}</strong></p>
              <div className="scroll">
              <div className={'infobox mb-20 '+this.getTotal(false)[1]}>
                  <p style={{margin:'0px'}}><strong>{lang.t('modals:clientbilling.total')}: {this.getTotal(false)[0]}</strong><span style={{display: (this.getTotal(false)[2] < 1 ? 'none' : 'inline')}}><br/>+ {this.getTotal(false)[2]} {this.getTotal(false)[2] === 1 ? lang.t('modals:clientbilling.session') : lang.t('modals:clientbilling.sessions')} {lang.t('modals:clientbilling.availabletoall')}</span></p>
                </div>
                <h4 className="pt-10">{lang.t('modals:clientbilling.general')}</h4>
                <p className="small mb-20">{lang.t('modals:clientbilling.general.text')}</p>
                {this.renderOLBase()}
                {this.renderMemberPack()}
                <div className="clear sv-20"></div>
                {this.renderModalList()}
                {this.renderModalDebits()}
              </div>
              <button className="btn primary" onClick={() => this.updateUnpaid()}>{lang.t('modals:clientbilling.button')}</button>
            </div>
          </div>
        )
      } else {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box clients">
              <h2 className="lft mb-10">{lang.t('modals:items.groupsessions')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hideModal()}>{lang.t('common:button.close')}</button>
              <p className="clear mb-20"><strong>{lang.t('modals:clientbilling.availableto')} {this.getMemberName()}</strong></p>
              <div className="scroll">
                <div className={'infobox mb-20 '+this.getTotal(true)[1]}>
                  <p><strong>{lang.t('modals:clientbilling.total')}: {this.getTotal(true)[0]}</strong></p>
                  <p style={{display: (this.getTotal(true)[2] < 1 ? 'none' : 'inline')}}>+ {this.getTotal(true)[2]} {this.getTotal(true)[2] === 1 ? lang.t('modals:clientbilling.session') : lang.t('modals:clientbilling.sessions')} {lang.t('modals:clientbilling.availabletoall')}</p>
                </div>
                <h4 className="pt-10">{lang.t('modals:clientbilling.general')}</h4>
                <p className="small mb-20">{lang.t('modals:clientbilling.general.text')}</p>
                {this.renderOLBase()}
                {this.renderMemberPack()}
                <div className="clear sv-20"></div>
                {this.renderModalList()}
                {this.renderModalDebits()}
              </div>
              <button className="btn primary" onClick={() => this.updateUnpaid()}>{lang.t('modals:clientbilling.button')}</button>
            </div>
          </div>
        )
      }  
    }
  }


  onChange = event => {
    this.setState({[event.target.name]: event.target.value });
  };


  onChangeExpiry = event => {
    var tmp = this.state.values1
    if(this.state.oltype === 'group') {
      tmp = this.state.values2
    }
    tmp[parseInt(event.target.name)].data.sessionsTotal = event.target.value
    if(this.state.oltype === '11') {
      this.setState({values1: tmp });
    } else {
      this.setState({values2: tmp });
    }
  };


  onChangeDate = event => {
    var tmp = this.state.values1
    if(this.state.oltype === 'group') {
      tmp = this.state.values2
    }
    tmp[parseInt(event.target.name)].data.expires = Moment(event.target.value, 'YYYY-MM-DD').format('X')
    if(this.state.oltype === '11') {
      this.setState({values1: tmp });
    } else {
      this.setState({values2: tmp });
    }
  };


  updateUnpaid() {
    this.updateUnpaidExpiry()
    var id1 = ''
    var ids1 = []
    var id2 = ''
    var ids2 = []
    var id3 = ''
    var id4 = ''
    if(this.state.item.credits.length > 0) {
      for(var item of this.state.item.credits) {
        if(item.data.account === '' || item.data.account === undefined) {
          if(!item.data.group) {
            id1 = item.id
            ids1.push(item.id)
          } else {
            id2 = item.id
            ids2.push(item.id)
          }
        }
        if(item.data.account === this.state.account && this.state.account !== '') {
          if(!item.data.group) {
            id3 = item.id
          } else {
            id4 = item.id
          }
        }
      }
    }

    if(ids1.length > 1) {
      for(var d1 of ids1) {
        if(d1 !== id1) {
          Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/'+d1).remove()
        }
      }
    }
    if(ids2.length > 1) {
      for(var d2 of ids2) {
        if(d2 !== id2) {
          Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/'+d2).remove()
        }
      }
    }

    if(id1 !== '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/'+id1).update({
        sessionsTotal: parseInt(this.state.value1),
        sessionsPaid: parseInt(this.state.value2)
      });
    } else {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/').push({
        type: 'sessions',
        group: false,
        sessionsTotal: parseInt(this.state.value1),
        sessionsPaid: parseInt(this.state.value2)
      });
    }
    
    if(id2 !== '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/'+id2).update({
        sessionsTotal: parseInt(this.state.value3),
        sessionsPaid: parseInt(this.state.value4)
      }).then(() => {
        this.hideModal()
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.sessionsupdated'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/').push({
        type: 'sessions',
        group: true,
        sessionsTotal: parseInt(this.state.value3),
        sessionsPaid: parseInt(this.state.value4)
      }).then(() => {
        this.hideModal()
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.sessionsupdated'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }

    // Family members
    if(this.state.account !== '' && this.state.item.data.linked !== undefined) {
      if(id3 !== '') {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/'+id3).update({
          sessionsTotal: parseInt(this.state.valuep1),
          sessionsPaid: parseInt(this.state.valuep2)
        });
      } else {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/').push({
          account: this.state.account,
          type: 'sessions',
          group: false,
          sessionsTotal: parseInt(this.state.valuep1),
          sessionsPaid: parseInt(this.state.valuep2)
        });
      }
      if(id4 !== '') {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/'+id4).update({
          sessionsTotal: parseInt(this.state.valuep3),
          sessionsPaid: parseInt(this.state.valuep4)
        });
      } else {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/').push({
          account: this.state.account,
          type: 'sessions',
          group: true,
          sessionsTotal: parseInt(this.state.valuep3),
          sessionsPaid: parseInt(this.state.valuep4)
        });
      }
    }
  }


  updateUnpaidExpiry() {
    var tmp = this.state.values1
    if(this.state.oltype === 'group') {
      tmp = this.state.values2
    }
    for(var item of tmp) {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.id+'/credits/'+item.id).update({
        expires: parseInt(item.data.expires),
        sessionsTotal: parseInt(item.data.sessionsTotal),
      })
    }
  }


  refundCash() {
    Firebase.database().ref('/payments/'+global.uid+'/'+this.state.currentPayment.id).remove().then(() => {
      this.hideModals()
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.paymentmarkedasrefunded'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    Firebase.database().ref('/activity/'+global.uid).push({
      type: 'cashrefunded',
      data: this.state.id+','+this.state.currentPayment.data.amount,
      date: Moment().format('DD/MM/YYYY HH:mm')
    })
  }


  removePack(id) {
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/credits/'+id).remove().then(() => {
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.sessionpackremoved'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // No show fee modal ------------------------------------------------------------



  showModalFee() {
    this.setState({
      showModalFee: true,

    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenFee: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalFee() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenFee: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({
        showModalFee: false, 
      });
    }, 500);
  }


  renderFeeActions() {
    if(global.userStripeConnect === '' || global.userStripeConnect === undefined) {
      return (
        <div>
          <p className="clear mb-30">{lang.t('modals:fee.text1')}</p>
          <Link to={ROUTES.ACCOUNTBILLING} className="btn primary">{lang.t('modals:fee.button.enable')}</Link>
          <div className="sv-20"></div>
          <button className="btn tertiary width-12" onClick={() => this.markFeePaid()}>{lang.t('modals:fee.button')}</button>
        </div>
      )
    } else {
      if(this.state.item.data.cardId === undefined || this.state.item.data.cardId === '') {
        return (
          <div>
            <p className="clear mb-30">{lang.t('modals:fee.text2')}</p>
            <button className="btn primary" onClick={() => this.addMethodFee()}>{lang.t('modals:fee.button.add')}</button>
            <div className="sv-20"></div>
            <button className="btn tertiary width-12" onClick={() => this.markFeePaid()}>{lang.t('modals:fee.button')}</button>
          </div>
        )
      } else {
        return (
          <div>
            <p className="clear mb-30">{lang.t('modals:fee.text3a')} ({this.state.item.data.cardBrand} {lang.t('modals:fee.text3b')} {this.state.item.data.cardLast4}) {lang.t('modals:fee.text3c')}</p>
            <button className="btn primary" onClick={() => this.makePayment()}>{lang.t('modals:fee.process')}</button>
            <p style={{fontSize: '11px', textAlign: 'center', paddingTop: '5px'}}>{lang.t('modals:fee.processingfee')}: {global.stripeFee}</p>
            <div className="sv-20"></div>
            <button className="btn tertiary width-12" onClick={() => this.markFeePaid()}>{lang.t('modals:fee.button')}</button>
          </div>
        )
      }
    }
  }


  renderModalFee() {
    if(this.state.showModalFee) {
      var amount = 0
      for(var item of this.state.item.noshow) {
        amount += item.data.amount
      }
      return (
        <div className={'overlay '+this.state.hiddenFee}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:fee.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalFee()}>{lang.t('common:button.close')}</button>
            <label className="clear">{lang.t('modals:fee.totalfees')} ({this.state.item.noshow.length} session{this.state.item.noshow.length === 1 ? lang.t('clients:label.session-s') : lang.t('clients:label.sessions-s')})</label>
            <p className="mb-20" style={{fontSize: '20px'}}>{global.curSym}{amount.toFixed(2)}</p>
            {this.renderFeeActions()}
          </div>
        </div>
      )
    }
  }


  addMethodFee() {
    this.hideModalFee()
    setTimeout(() => {
      this.setState({showModalCard: true})
    }, 550);
  }


  markFeePaid() {
    this.hideModalFee()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/noshow/').remove().then((data)=>{
      EventEmitter.dispatch('showMessage', lang.t('messaging:messge.noshowfeesresolved'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  makePayment() {
    this.hideModalFee()
    this.setState({
      loading: true,
    })
    var email = this.state.item.data.email
    if(email === '' || email === undefined) {
      email = 'noemail@ptmate.net'
    }
    var amount = 0
    for(var item of this.state.item.noshow) {
      amount += item.data.amount
    }

    var fee = amount*0.5 //includes *100 calculation
    var callFunction = Firebase.functions().httpsCallable('connectedPayment');
    callFunction({type: 'pay', account: global.userStripeConnect, amount: parseInt(amount*100), fee: fee, pack: lang.t('clients:label.noshowfee'), customer: this.state.item.data.customer, client: this.state.item.id, date: Moment().format('DD/MM/YYYY HH:mm'), currency: global.userCurrency, stype: 'fee', email: email, ptype: 'fee', uid: global.uid}).then(function(result) {
      //var sanitizedMessage = result.data.text;
    });
  }



  // Modals ------------------------------------------------------------



  showModalPayment(item) {
    this.setState({
      showModalPayment: true,
      currentPayment: item
    })
  }


  showModalExpired() {
    var list = []
    for(var item of this.state.item.creditse) {
      if(Moment(item.data.expires).format('X') < Moment().format('X')) {
        list.push(item)
      }
    }
    this.setState({
      showModalExpired: true,
      expired: list,
    })
  }


  hideModals() {
    this.setState({
      showModalPayment: false,
      showModalCard: false,
      showModalBilling: false,
      showModalTrial: false,
      showModalExpired: false,
    })
  }


  createCard(values) {
    this.hideModals()
    var email = this.state.item.data.email
    if(email === '' || email === undefined) {
      email = 'noemail@ptmate.net'
    }
    if(this.state.item.data.cardId !== undefined && this.state.item.data.cardId !== '') {
      var callFunction = Firebase.functions().httpsCallable('connectedManageClientCard');
        callFunction({type: 'delete', card: this.state.item.data.cardId, trainer: global.uid, account: global.userStripeConnect, client: this.state.id, uid: global.uid, customer: this.state.item.data.customer}).then(function(result) {
          //var sanitizedMessage = result.data.text;
      });
    }
    if(this.state.item.data.customer !== '' && this.state.item.data.customer !== undefined) {
      var callFunction2 = Firebase.functions().httpsCallable('connectedManageClientCard');
      callFunction2({type: 'create', customer: this.state.item.data.customer, name: values[0], client: this.state.id, card: values[1], month: values[2], year: values[3], cvc: values[4], account: this.state.stripe, uid: global.uid, user: global.uid}).then(function(result) {
        //var sanitizedMessage = result.data.text;
      });
    } else {
      var callFunction3 = Firebase.functions().httpsCallable('connectedManageClientCard');
      callFunction3({type: 'account', clientname: this.state.item.data.name, email: email, name: values[0], client: this.state.id, card: values[1], month: values[2], year: values[3], cvc: values[4], account: global.userStripeConnect, uid: global.uid, user: global.uid}).then(function(result) {
        //var sanitizedMessage = result.data.text;
      });
    }
    this.setState({
      loading: true,
      message: lang.t('messaging:message.cardadded')
    })
  }


  deleteCard(card) {
    this.hideModals()
    this.setState({
      loading: true,
      message: lang.t('messaging:message.carddeleted')
    })
    var callFunction = Firebase.functions().httpsCallable('connectedManageClientCard');
    callFunction({type: 'clear', card: card, trainer: global.uid, account: global.userStripeConnect, client: this.state.item.id, uid: global.uid, customer: this.state.item.data.customer}).then(function(result) {
       //var sanitizedMessage = result.data.text;
    });
  }


  deleteBilling() {
    this.hideModals()
    var callFunction3 = Firebase.functions().httpsCallable('connectedDeleteCustomer');
    callFunction3({type: 'billing', account: global.userStripeConnect, customer: this.state.item.data.customer, client: this.state.id}).then(function(result) {});
    global.message = lang.t('messaging:message.billingdetailsdeleted')
    this.setState({
      deletingBilling: true
    })
  }


  createTrial() {
    this.hideModals()
    var edate = this.state.trialDate
    if(Moment(edate, 'X') < Moment()) {
      edate = Moment().add(1, 'days').format('X')
    }
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/subscriptions/trial').update({
      billing: '',
      date: Moment().format('DD/MM/YYYY HH:mm'),
      name: lang.t('clients:label.freetrial'),
      group: true,
      price: 0,
      status: 'active',
      id: 'trial',
      plan: '',
      product: '',
      next: parseInt(edate)
    }).then(() => {
      var message = (this.state.hasTrial ? lang.t('messaging:message.trialupdated') : lang.t('messaging:message.trialsetup'))
      EventEmitter.dispatch('showMessage', message);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  cancelTrial() {
    this.hideModals()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/subscriptions/trial').remove()
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.trialcanceled'));
  }



  // Display stuff ------------------------------------------------------------



  renderInvoiceOverdue(item) {
    if(Moment(item.data.due, 'X') < Moment().add(-1, 'day')) {
      return <span className="pill red">{lang.t('clients:label.overdue')}</span>
    } else {
      return <span className="pill">{lang.t('clients:label.open')}</span>
    }
  }


  renderInvoices() {
    if(this.state.item !== null) {
      var list = []
      for(var item of global.invoices) {
        if(item.data.client === this.state.item.id && item.data.status === 'open') {
          list.push(item)
        }
      }
      if(list.length > 0) {
        return (
          <div>
            <div className="listheader">
              <ListHeader id='unpaidinvoices' number={list.length}/>
              <div className="clear"></div>
            </div>
            {list.map(item => (
              <ListIcon
                key={item.id}
                icon='invoice'
                color='secondary'
                link={'/billing/invoice/'+item.id}
                columns={[{text: lang.t('clients:label.invoice')+' '+item.data.number, width: 4}, {text: global.curSym+item.data.price.toFixed(2), width: 3}, {text: this.renderInvoiceOverdue(item), width: 3}]}
              />
            ))}
            <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.INVOICENEW)}>{lang.t('clients:button.createanotherinvoice')}</button>
          </div>
        )
      }
    }
  }


  renderDebitStatus(item) {
    if(item.data.pause !== undefined && item.data.pause !== '') {
      return (
        <span className="pill yellow">{lang.t('clients:label.paused')}</span>
      )
    } else {
      if(item.data.status === 'past_due') {
        return (
          <span className="pill yellow">{lang.t('clients:label.paymentissue')}</span>
        )
      } else if(item.data.status === 'unpaid') {
        return (
          <span className="pill red">{lang.t('clients:label.issue')}</span>
        )
      } else if(item.data.status === 'canceled') {
        return (
          <span className="pill red">{lang.t('clients:label.canceled')}</span>
        )
      } else {
        var start = Moment().add(-1, 'years').format('X')
        if(item.data.start !== undefined) {
          start = Moment(item.data.start, 'X')
        }
        if(Moment(start, 'X').isAfter(Moment())) {
          return (
            <span className="pill">{lang.t('clients:label.starts')} {Moment(start, 'X').locale(lang.language).format('ddd, D MMM YYYY')}</span>
          )
        } else if(item.data.end !== undefined && item.data.end !== '') {
          if(Moment(item.data.end, 'X').isBefore(Moment())) {
            return (
              <span className="pill red">{lang.t('clients:label.canceled')} {Moment(item.data.end, 'X').locale(lang.language).format('ddd, D MMM YYYY')}</span>
            )
          }
        } else {
          return lang.t('clients:label.active')
        }
      }
    }
  }


  renderDebitLimit(item) {
    var label = ''
    if(!item.data.is11 || item.data.is11 === undefined) {
      if(item.data.sessions !== undefined && item.data.sessions !== 0) {
        label = '('+item.data.sessions+')'
      }
    }
    return label
  }


  renderDebitType(item) {
    var label = lang.t('clients:label.11sessions')
    if(item.data.group) {
      label = lang.t('clients:label.groupsessions')
      if(item.data.is11) {
        label = lang.t('clients:label.groupand11')
      }
    }
    return label
  }


  renderDebits() {
    if(this.state.debits.length > 0) {
      var list1 = []
      var list2 = []
      for(var deb of this.state.debits) {
        if(deb.data.id === 'trial') {
          list2.push(deb)
        } else {
          list1.push(deb)
        }
      }
      return (
        <div>
          <div className="listheader">
            <ListHeader id='debits' number={this.state.debits.length}/>
            <div className="clear"></div>
          </div>
          {list1.map(item => (
            <ListIcon
              key={item.id}
              icon='refresh'
              color='primary'
              link={'/billing/direct-debits/'+this.state.id+'/'+item.id}
              columns={[{text: <span>{global.curSym+(item.data.price/100).toFixed(2)+' '+this.getBilling(item)}<span>{this.renderCreditFamily(item)}</span></span>, width: 4}, {text: this.renderDebitType(item)+' '+this.renderDebitLimit(item), width: 3}, {text: this.renderDebitStatus(item), width: 3}]}
            />
          ))}
          {list2.map(item2 => (
            <ListIcon
              key={item2.id}
              icon='waiting'
              color='primary'
              clickElement={() => this.setState({showModalTrial: true})}
              button='Update'
              columns={[{text: lang.t('clients:label.freetrial'), width: 7}, {text: this.renderExpiry(item2), width: 3}]}
            />
          ))}
          <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.DEBITNEW)}>{lang.t('clients:button.setupanothermembership')}</button>
        </div>
      )
    }
  }


  renderExpiry(item) {
    var date = item.data.expires
    var title1 = lang.t('clients:label.expire')
    var title2 = lang.t('clients:label.expired')
    if(item.id === 'trial') {
      date = item.data.next
      title1 = lang.t('clients:label.ends')
      title2 = lang.t('clients:label.ended')
    }
    if(Moment(date, 'X') > Moment()) {
      if(Moment(date, 'X') < Moment().add(2, 'days')) {
        return (
          <span>{title1} {Moment(date, 'X').locale(lang.language).format('D MMM YYYY')} <span className="pill yellow">{title1} {lang.t('clients:label.soon')}</span></span>
        )
      } else {
        return (
          <span>{title1} {Moment(date, 'X').locale(lang.language).format('D MMM YYYY')}</span>
        )
      }
      
    } else {
      return (
        <span>{title2} {Moment(date, 'X').locale(lang.language).format('D MMM YYYY')} <span className="pill red">{title2}</span></span>
      )
    }
  }


  renderCreditFamily(item) {
    if(this.state.item.data.linked !== undefined) {
      if(this.state.item.data.linked.length > 0) {
        var label = lang.t('clients:button.allfamilymembers')
        if(item.data.account !== undefined && item.data.account !== '') {
          for(var cl of global.clients) {
            if(cl.id === item.data.account) {
              label = cl.data.name
            }
          }
        }
        return <span style={{fontWeight: '400'}}><br/>{label}</span>
      }
    } else {
      return ''
    }
  }


  renderPackRemove(item) {
    if(item.id !== 'trial') {
      if(Moment(item.data.expires, 'X') < Moment()) {
        return (
          <button className="btn tertiary close small rgt" onClick={() => this.removePack(item.id)}>{lang.t('common:button.remove')}</button>
        )
      }
    }
  }


  renderCreditName(item) {
    var label = lang.t('clients:label.groupsessions')
    if(!item.data.group) {
      label = lang.t('clients:label.11sessions')
    }
    if(item.data.name !== undefined && item.data.name !== '') {
      label = item.data.name
    }
    return label
  }


  renderCreditsList() {
    var list = []
    for(var item of this.state.item.creditse) {
      if(Moment(item.data.expires).format('X') > Moment().add(-3, 'days').format('X')) {
        list.push(item)
      }
    }
    if(list.length > 0) {
      return (
        <div>
          {list.map(item => (
            <div className='box list simple highlight mb-10' key={item.id}>
              <div className="col-4">
                <div className='icon primary'>
                  <div className='inner pack'></div>
                </div>
                <p className="lft"><strong>{this.renderCreditName(item)}</strong>{this.renderCreditFamily(item)}</p>
                <div className="clear"></div>
              </div>
              <div className="col-3">
                <p>{item.data.sessionsTotal === 0 ? lang.t('clients:label.unused') : (item.data.sessionsTotal)+' '+lang.t('clients:label.of')+' '+item.data.sessionsPaid+' '+lang.t('clients:label.used')}</p>
              </div>
              <div className="col-4">
                <p>{this.renderExpiry(item)}</p>
              </div>
              <div className="col-1">
                {this.renderPackRemove(item)}
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    } else {
      return (
        <div className="empty large">
          <h3 className="mb-10">{lang.t('clients:empty.packs.title')}</h3>
          <p>{lang.t('clients:empty.packs.text')}</p>
        </div>
      )
    }
  }


  renderCredits() {
    if(this.state.item !== null) {
      var list = []
      for(var item of this.state.item.creditse) {
        if(Moment(item.data.expires).format('X') > Moment().add(-3, 'days').format('X')) {
          list.push(item)
        }
      }
      if(this.state.item.creditse.length > 0) {
        return (
          <div className="clear">
            <div className="listheader">
              <ListHeader id='exppacks' number={list.length}/>
              <div className="clear"></div>
            </div>
            {this.renderCreditsList()}
            <div className="sv-10"></div>
            <button className="btn tertiary width-12" onClick={() => this.showModalExpired()}>{lang.t('clients:button.viewexpiredpacks')}</button>
          </div>
        )
      }
    }
  }


  renderPaymentName(item) {
    var label = item.data.name
    if(item.data.subscription !== undefined && item.data.subscription !== '' && this.state.item !== null) {
      for(var sub of this.state.products) {
        if(sub.id === item.data.subscription) {
          label = sub.data.name
        }
      }
    }
    if(item.data.name.indexOf('Invoice ') !== -1 || item.data.name.indexOf('Subscription ') !== -1) {
      label = lang.t('clients:label.membership')
    }
    return label
  }


  renderPaymentDate(item) {
    var label = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')
    if(item.data.timestamp !== undefined) {
      label = Moment(item.data.timestamp, 'X').locale(lang.language).format('D MMM YYYY')
    }
    return label
  }


  getPastTime() {
    var label = lang.t('clients:empty.sessions.title1')
    if(this.state.dateRange === '7days') {
      label = lang.t('clients:empty.sessions.title2')
    } else if(this.state.dateRange === 'month') {
      label = lang.t('clients:empty.sessions.title3')
    } else if(this.state.dateRange === '60days') {
      label = lang.t('clients:empty.sessions.title4')
    } else if(this.state.dateRange === 'week') {
      label = lang.t('clients:empty.sessions.title5')
    }
    return label
  }


  getPaymentIcon(item) {
    var label = 'dollar'
    if(item.data.fee === undefined || item.data.fee !== 0) {
      label = 'card'
    }
    return label
  }


  renderPayments() {
    if(this.state.payments.length === 0) {
      return (
        <div>
          <div className="listheader">
            <ListHeader id='payments' number={this.state.payments.length}/>
            <div className="clear"></div>
          </div>
          <ListEmpty id='nopaymentsclient' type='simple'/>
        </div>
      )
    } else {
      var list = this.state.payments
      list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div>
          <div className="listheader">
            <ListHeader id='payments' number={this.state.payments.length}/>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <ListIcon
              key={item.id}
              icon={this.getPaymentIcon(item)}
              color='secondary'
              clickElement={() => this.showModalPayment(item)}
              columns={[{text: global.curSym+(item.data.amount/100).toFixed(2), width: 4}, {text: this.renderPaymentDate(item), width: 3, w1280: 5}, {text: this.renderPaymentName(item), width: 3, w1280: 0}]}
            />
          ))}
          <button className="inline-add list mb-10" onClick={() => this.props.history.push('/billing/payment/'+this.state.id+'/sessions')}>{lang.t('clients:button.processanotherpayment')}</button>
          <div className="sv-30"></div>
        </div>
      )
    }
  }


  getFamilyName(item) {
    var label = lang.t('clients:label.client')
    for(var cl of global.clients) {
      if(cl.id === item) {
        label = cl.data.name
      }
    }
    return label
  }


  renderSelectFamily() {
    if(this.state.item !== null) {
      if(this.state.item.data.linked !== undefined) {
        if(this.state.item.data.linked.length > 0) {
          return (
            <div className="col-3 info large">
              <label className={this.state.account === '' ? 'interactive active' : 'interactive'} onClick={() => this.setFamilyAccount('')}>{lang.t('clients:button.allfamilymembers')}</label>
              <label className={this.state.account === this.state.item.id ? 'interactive active' : 'interactive'} onClick={() => this.setFamilyAccount(this.state.item.id)}>{this.state.item.data.name}</label>
              {this.state.item.data.linked.map(item => (
                <label key={item} className={this.state.account === item ? 'interactive active' : 'interactive'} onClick={() => this.setFamilyAccount(item)}>{this.getFamilyName(item)}</label>
              ))}
            </div>
          )
        }
      }
    }
  }


  renderOV1Button() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <button className="btn tertiary small lft ml-10" onClick={() => this.showModal('11')}>{lang.t('clients:button.details')}</button>
      )
    }
  }


  renderOverviewBilling1() {
    if(global.userChargeSessions) {
      return (
        <div className='info large col-3'>
          <label className="lft">{lang.t('clients:label.11sessions')}</label>
          {this.renderOV1Button()}
          <p className="clear">{this.getSessions(false)[0]}</p>
          <span className={'pill '+this.getSessions(false)[2]}>{this.getSessions(false)[1]}</span>
        </div>
      )
    } else {
      return (
        <div className='info large col-6'>
          <label>{lang.t('clients:label.availablesessions')}</label>
          <p className="clear">{lang.t('clients:label.availablesessions.text')}</p>
        </div>
      )
    }
  }


  renderOV2Button() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <button className="btn tertiary small lft ml-10" onClick={() => this.showModal('group')}>{lang.t('clients:button.details')}</button>
      )
    }
  }


  renderOverviewBilling2() {
    if(global.userChargeSessions) {
      return (
        <div className='info large col-3'>
          <label className="lft">{lang.t('clients:label.groupsessions')}</label>
          {this.renderOV2Button()}
          <p className="clear">{this.getSessions(true)[0]}</p>
          <span className={'pill '+this.getSessions(true)[2]}>{this.getSessions(true)[1]}</span>
        </div>
      )
    }
  }


  renderNoshow() {
    if(this.state.item !== null) {
      if(this.state.item.noshow.length > 0) {
        var list = this.state.item.noshow
        return (
          <div className="mb-50">
            <h3>{lang.t('header:title.noshow')}</h3>
            <div className="clear sv-10"></div>
            {list.map(item => (
              <div className="list bare" key={item.id} onClick={() => this.showModalFee()}>
                <div className="icon secondary">
                  <div className="inner dollar"></div>
                </div>
                <div className="main">
                  <h4>{item.data.session}</h4>
                  <p>{global.curSym}{item.data.amount.toFixed(2)}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
            <button className="btn tertiary width-12 edit small mt-20" onClick={() => this.showModalFee()}>{lang.t('common:button.manage')}</button>
          </div>
        )
      }
    }
  }


  renderDeleteBilling() {
    if(this.state.item.data.customer !== '' && this.state.active && (HelperBusiness.getRoleIncl('trainer,admin'))) {
      return (
        <button className="btn tertiary close rgt small" onClick={() => this.setState({showModalBilling: true})}>{lang.t('clients:button.deletecarddetails')}</button>
      )
    }
  }


  renderCardButton() {
    if(this.state.active && global.userStripeConnect !== '' && global.userStripeConnect !== undefined && (HelperBusiness.getRoleIncl('trainer,admin'))) {
      return (
        <button className="btn tertiary width-12 edit small" onClick={() => this.setState({showModalCard: true})}>{lang.t('clients:button.addacard')}</button>
      )
    }
  }


  renderCardUpdate() {
    if(this.state.active && global.userStripeConnect !== '' && global.userStripeConnect !== undefined && (HelperBusiness.getRoleIncl('trainer,admin'))) {
      return (
        <button className="btn tertiary width-12 edit small mt-20" onClick={() => this.setState({showModalCard: true})}>{lang.t('common:button.update')}</button>
      )
    }
  }


  renderMethod() {
    if(this.state.item !== null) {
      if(this.state.item.data.cardId !== undefined) {
        if(this.state.item.data.cardBrand !== '') {
          return (
            <div className="mb-50">
              <h3 className="lft">{lang.t('header:title.card')}</h3>
              {this.renderDeleteBilling()}
              <div className="clear mb-20"></div>
              <WidgetCard id={this.state.item.id}/>
              {this.renderCardUpdate()}
            </div>
          )
        } else {
          return (
            <div className="mb-50">
              <h3>{lang.t('header:title.card')}</h3>
              <div className="clear empty small mb-20">
                {HelperEmpty.renderImage('card')}
                <h4>{lang.t('clients:label.nocardprovided')}</h4>
              </div>
              {this.renderCardUpdate()}
            </div>
          )
        }
      } else {
        return (
          <div className="mb-50">
            <h3>{lang.t('header:title.card')}</h3>
            <div className="clear empty small mb-20">
              {HelperEmpty.renderImage('card')}
              <h4>{lang.t('clients:label.nocardprovided')}</h4>
            </div>
            {this.renderCardButton()}
          </div>
        )
      }
    }
  }


  setMonth(type) {
    var tmp = Moment(this.state.month, 'MM/YYYY')
    if(type === 'prev') {
      if(Moment(this.state.month, 'MM/YYYY') > Moment().add(-60, 'days')) {
        tmp = Moment(this.state.month, 'MM/YYYY').add(-1, 'months')
      }
    } else {
      if(Moment(this.state.month, 'MM/YYYY') < Moment().add(12, 'months')) {
        tmp = Moment(this.state.month, 'MM/YYYY').add(1, 'months')
      }
    }
    this.setState({
      month: tmp.format('MM/YYYY')
    })
  }


  getDayClass(item, index) {
    var label = 'weekday interactive pending lft'
    var text = ''
    for(var pay of global.payments) {
      var pd = Moment(pay.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
      if(pay.data.timestamp !== undefined) {
        pd = Moment(pay.data.timestamp, 'X').format('DD/MM/YYYY')
      }
      if(pd === item && pay.data.client === this.state.item.id) {
        label = 'weekday interactive done lft'
        if(pay.data.name.indexOf('Invoice') !== -1 || pay.data.name.indexOf('Subscription') !== -1) {
          text += lang.t('clients:label.membership')+' - '+global.curSym+(pay.data.amount/100).toFixed(2)+'\n'
        } else {
          text += pay.data.name+' - '+global.curSym+(pay.data.amount/100).toFixed(2)+'\n'
        }
      }
    }
    for(var pack of this.state.item.creditse) {
      if(Moment(pack.data.expires, 'X').format('DD/MM/YYYY') === item && Moment(pack.data.expires, 'X') > Moment()) {
        label = 'weekday interactive fail lft'
        var type = lang.t('clients:label.11sessions-s')
        if(pack.data.group !== undefined) {
          type = lang.t('clients:label.groupsessions-s')
        }
        text += lang.t('clients:label.packof')+' '+type+' '+lang.t('clients:label.expires')+'\n'
      }
    }
    for(var sub of this.state.item.subscriptions) {
      var d = 7
      var t = 'days'
      if(sub.data.billing === 'fortnight') {
        d = 14
      }
      if(sub.data.billing === 'month') {
        d = 1
        t = 'months'
      }
      for(var i=0; i<62; i++) {
        var sd = Moment(sub.data.next, 'X').add(i*d, t).format('DD/MM/YYYY')
        if(sd === item) {
          label = 'weekday interactive active lft'
          text += sub.data.name+' - '+(sub.data.price/100).toFixed(2)
        }
      }
    }
    if(text === '') {
      return (
        <div className={label} key={item}>{index+1}</div>
      )
    } else {
      return <div className={label} key={item}>{index+1}<div className="tooltip">{text}</div></div>
    }
  }


  renderCalendar() {
    if(this.state.item !== null) {
      var d1 = Moment('01/'+this.state.month, 'DD/MM/YYYY').format('d')
      var d2 = Moment('01/'+this.state.month, 'DD/MM/YYYY').endOf('month').format('D')
      var list1 = []
      var list2 = []
      if(parseInt(d1) === 0) {
        d1 = 7
      }
      for(var i1=1; i1<parseInt(d1); i1++) {
        list1.push('')
      }
      for(var i2=1; i2<(parseInt(d2)+1); i2++) {
        list2.push(Moment(i2+'/'+this.state.month, 'D/MM/YYYY').format('DD/MM/YYYY'))
      }
      return (
        <div className="calmini">
          <div className="calmini-header mb-20">
            <div className="arrow lft" onClick={() => this.setMonth('prev')}>‹</div>
            <div className="arrow rgt" style={{float:'right'}} onClick={() => this.setMonth('next')}>›</div>
            <h3 className="lft">{Moment(this.state.month, 'MM/YYYY').locale(lang.language).format('MMMM YYYY')}</h3>
            <div className="clear"></div>
          </div>
          {list1.map(item => (
            <div className="weekday lft" key={item}></div>
          ))}
          {list2.map((item,index) => (
            <div>{this.getDayClass(item, index)}</div>
          ))}
          <div className="clear mb-50"></div>
        </div>
      )
    }
  }


  renderActions() {
    var item1 = {id: 'clientdetails', link: '/clients/'+this.state.id+'/details'}
    var item2 = {id: '', link: ''}
    var item3 = {id: '', link: ''}
    if(HelperBusiness.getRoleIncl('admin') && this.state.item !== null) {
      item1 = {id: 'newpayment', link: '/billing/payment/'+this.state.id+'/sessions'}
      item2 = {id: 'newdebit', link: ROUTES.DEBITNEW}
      item3 = {id: 'trial', link: ''}
      if(this.state.hasTrial) {
        item3 = {id: '', link: ''}
      }
    } else if(HelperBusiness.getRole('trainer') && this.state.item !== null) {
      item1 = {id: 'newpayment', link: '/billing/payment/'+this.state.id+'/sessions'}
    }
    return (
      <WidgetActions
        item1={item1}
        item2={item2}
        item3={item3} clickItem3={() => this.setState({showModalTrial: true})}
      />
    )
  }


  renderPrev() {
    var val = HelperBilling.getIncomeDifference(global.currentClient, this.state.dateRange)
    var diff = HelperBilling.getIncome(this.state.payments, this.state.item === null ? '' : this.state.item.id, this.state.dateRange)-val
    var badge = ''
    if(diff > 0) { badge = ' green'}
    if(diff < 0) { badge = ' red'}
    if(this.state.dateRange === '60days') {
      val = '-'
      badge = ''
    }
    if(val !== 'N/A' && val !== '-') {
      diff = diff.toFixed(2)
    }
    if(this.state.dateRange !== '60days') {
      return (
        <span className={'pill'+badge+(val === '-' ? ' none' : '')} title={lang.t('overview:changecompared')}>{diff > 0 ? '+' : ''}{global.curSym}{diff}</span>
      )
    }
  }


  renderOverview() {
    return (
      <div className="mt-20">
       
        <div className='info large col-3'>
          <label>{lang.t('clients:label.netincome')}</label>
          <p>{global.curSym}{HelperBilling.getIncome(this.state.payments, this.state.item === null ? '' : this.state.item.id, this.state.dateRange)}</p>
          {this.renderPrev()}
        </div>
        {this.renderOverviewBilling1()}
        {this.renderOverviewBilling2()}
        {this.renderSelectFamily()}
        <div className="clear"></div>
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs"> 
            {HelperClients.getFamily(this.state.item)}
            <div className="col-9">
              {this.renderOverview()}
            </div>
            {this.renderActions()}
            <div className="col-9 withsidebar">
              {this.renderDebits()}
              {this.renderCredits()}
              {this.renderInvoices()}
              {this.renderPayments()}
            </div>
            <div className="col-3 mt-50">
              {this.renderCalendar()}
              {this.renderNoshow()}
              {this.renderMethod()}
            </div>
            <div className="clear sv-80"></div>
          </div>
          <Header client={this.state.item} link={global.currentPage} active='billing' selector={true} updateSelect={() => this.setDateRange()}/>
          <Navigation active='clients' />
          {this.renderModal()}
          {this.renderModalFee()}
          <ModalPayment payment={this.state.currentPayment} show={this.state.showModalPayment} onHide={() => this.hideModals()}/>
          <ModalCard show={this.state.showModalCard} card={this.state.cards.length > 0 ? this.state.cards[0].id : ''} cards={this.state.cards} onHide={() => this.hideModals()} deleteCard={(event) => this.deleteCard(event)} clickMainButton={(event) => this.createCard(event)}/>
          <ModalMessageSimple type='deletebilling' show={this.state.showModalBilling} onHide={() => this.hideModals()} clickMainButton={() => this.deleteBilling()}/>
          <ModalTrial type={this.state.hasTrial ? 'update' : 'new'} show={this.state.showModalTrial} date={this.state.trialDate} onHide={() => this.hideModals()} onChange={(event) => this.setState({trialDate: event})} clickMainButton={() => this.createTrial()} clickSecondaryButton={() => this.cancelTrial()}/>
          <ModalItems show={this.state.showModalExpired} type={'packs'} title={lang.t('modals:items.title.expiredpacks')} items={this.state.expired} empty={lang.t('modals:items.empty.expiredpacks')} onHide={() => this.hideModals()}/>
          <Loader show={this.state.loading}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <ListEmpty id='billing-locked' type='simple'/>
          </div>
          <Header client={this.state.item} link={global.currentPage} active='billing' selector={true} updateSelect={() => this.setDateRange()}/>
          <Navigation active='clients' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','overview','modals','messaging'])(withRouter(withAuthorization(condition)(ClientsBillingPage)));