import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import lang from 'i18next';


const DataMessageSimple = [
  {
    id: 'loginclient',
    title: "modals:simple.data1.title",
    text: "modals:simple.data1.text",
    button: 'modals:simple.data1.button',
  },
  {
    id: 'clientlimit',
    title: 'modals:simple.data2.title',
    text: "modals:simple.data2.text",
    button: 'modals:simple.data2.button',
  },
  {
    id: 'clientrestricted',
    title: 'modals:simple.data3.title',
    text: "modals:simple.data3.text",
    button: 'modals:simple.data3.button',
  },
  {
    id: 'programlimit',
    title: 'modals:simple.data4.title',
    text: "modals:simple.data4.text",
    button: 'modals:simple.data4.button',
  },
  {
    id: 'benchmark',
    title: 'modals:simple.data5.title',
    text: "modals:simple.data5.text",
    button: 'modals:simple.data5.button',
  },
  {
    id: 'movement',
    title: 'modals:simple.data6.title',
    text: "modals:simple.data6.text",
    button: 'modals:simple.data6.button',
  },
  {
    id: 'planlimit',
    title: 'modals:simple.data7.title',
    text: "modals:simple.data7.text",
    button: 'modals:simple.data7.button',
  },
  {
    id: 'deletehabit',
    title: 'modals:simple.data8.title',
    text: "modals:simple.data8.text",
    button: 'modals:simple.data8.button',
  },
  {
    id: 'deleteexpense',
    title: 'modals:simple.data9.title',
    text: "modals:simple.data9.text",
    button: 'modals:simple.data9.button',
  },
  {
    id: 'confirmpay',
    title: 'modals:simple.data10.title',
    text: "modals:simple.data10.text",
    button: 'modals:simple.data10.button',
  },
  {
    id: 'confirmdebit',
    title: 'modals:simple.data11.title',
    text: "modals:simple.data11.text",
    button: 'modals:simple.data11.button',
  },
  {
    id: 'deleteproduct',
    title: 'modals:simple.data12.title',
    text: "modals:simple.data12.text",
    button: 'modals:simple.data12.button',
  },
  {
    id: 'deleteinvoice',
    title: 'modals:simple.data13.title',
    text: "modals:simple.data13.text",
    button: 'modals:simple.data13.button',
  },
  {
    id: 'stafflocked',
    title: 'modals:simple.data14.title',
    text: "modals:simple.data14.text",
    button: 'modals:simple.data14.button',
  },
  {
    id: 'stafflocked2',
    title: 'modals:simple.data15.title',
    text: "modals:simple.data15.text",
    button: 'modals:simple.data15.button',
  },
  {
    id: 'deleteform',
    title: 'modals:simple.data16.title',
    text: "modals:simple.data16.text",
    button: 'modals:simple.data16.button',
  },
  {
    id: 'deleteformpre',
    title: 'modals:simple.data17.title',
    text: "modals:simple.data17.text",
    button: 'modals:simple.data17.button',
  },
  {
    id: 'deleteresponse',
    title: 'modals:simple.data18.title',
    text: "modals:simple.data18.text",
    button: 'modals:simple.data18.button',
  },
  {
    id: 'preexercise',
    title: 'modals:simple.data19.title',
    text: "modals:simple.data19.text",
    button: 'modals:simple.data19.button',
  },
  {
    id: 'recurring',
    title: 'modals:simple.data20.title',
    text: "modals:simple.data20.text",
    button: 'modals:simple.data20.button',
  },
  {
    id: 'deleterec',
    title: 'modals:simple.data21.title',
    text: "modals:simple.data21.text",
    button: 'modals:simple.data21.button',
  },
  {
    id: 'updaterec',
    title: 'modals:simple.data22.title',
    text: "modals:simple.data22.text",
    button: 'modals:simple.data22.button',
  },
  {
    id: 'deleterecevent',
    title: 'modals:simple.data23.title',
    text: "modals:simple.data23.text",
    button: 'modals:simple.data23.button',
  },
  {
    id: 'updaterecevent',
    title: 'modals:simple.data24.title',
    text: "modals:simple.data24.text",
    button: 'modals:simple.data24.button',
  },
  {
    id: 'removerec',
    title: 'modals:simple.data25.title',
    text: "modals:simple.data25.text",
    button: 'modals:simple.data25.button',
  },
  {
    id: 'deletesession',
    title: 'modals:simple.data26.title',
    text: "modals:simple.data26.text",
    button: 'modals:simple.data26.button',
  },
  {
    id: 'sparksession',
    title: 'modals:simple.data27.title',
    text: "modals:simple.data27.text",
    button: 'modals:simple.data27.button',
  },
  {
    id: 'bookableevent',
    title: 'modals:simple.data28.title',
    text: "modals:simple.data28.text",
    button: 'modals:simple.data28.button',
  },
  {
    id: 'deleteevent',
    title: 'modals:simple.data29.title',
    text: "modals:simple.data29.text",
    button: 'modals:simple.data29.button',
  },
  {
    id: 'deleteclientgroup',
    title: 'modals:simple.data30.title',
    text: "modals:simple.data30.text",
    button: 'modals:simple.data30.button',
  },
  {
    id: 'confirmlinked',
    title: 'modals:simple.data31.title',
    text: "modals:simple.data31.text",
    button: 'modals:simple.data31.button',
  },
  {
    id: 'deleteprogram',
    title: 'modals:simple.data32.title',
    text: "modals:simple.data32.text",
    button: 'modals:simple.data32.button',
  },
  {
    id: 'deleteprogram2',
    title: 'modals:simple.data33.title',
    text: "modals:simple.data33.text",
    button: 'modals:simple.data33.button',
  },
  {
    id: 'deleteprograms',
    title: 'modals:simple.data34.title',
    text: "modals:simple.data34.text",
    button: 'modals:simple.data34.button',
  },
  {
    id: 'deleteplan',
    title: 'modals:simple.data35.title',
    text: "modals:simple.data35.text",
    button: 'modals:simple.data35.button',
  },
  {
    id: 'deleteplanclient',
    title: 'modals:simple.data36.title',
    text: "modals:simple.data36.text",
    button: 'modals:simple.data36.button',
  },
  {
    id: 'sendplan',
    title: "modals:simple.data37.title",
    text: "modals:simple.data37.text",
    button: 'modals:simple.data37.button',
  },
  {
    id: 'deleteweek',
    title: "modals:simple.data38.title",
    text: "modals:simple.data38.text",
    button: 'modals:simple.data38.button',
  },
  {
    id: 'deletepost',
    title: "modals:simple.data39.title",
    text: "modals:simple.data39.text",
    button: 'modals:simple.data39.button',
  },
  {
    id: 'deletereply',
    title: "modals:simple.data40.title",
    text: "modals:simple.data40.text",
    button: 'modals:simple.data40.button',
  },
  {
    id: 'branding',
    title: "modals:simple.data41.title",
    text: "modals:simple.data41.text",
    button: 'modals:simple.data41.button',
  },
  {
    id: 'canceldebit',
    title: "modals:simple.data42.title",
    text: "modals:simple.data42.text",
    button: 'modals:simple.data42.button',
  },
  {
    id: 'resumedebit',
    title: "modals:simple.data43.title",
    text: "modals:simple.data43.text",
    button: 'modals:simple.data43.button',
  },
  {
    id: 'goback',
    title: "modals:simple.data44.title",
    text: "modals:simple.data44.text",
    button: 'modals:simple.data44.button',
  },
  {
    id: 'sessionfull',
    title: "modals:simple.data45.title",
    text: "modals:simple.data45.text",
    button: 'modals:simple.data45.button',
  },
  {
    id: 'habitslocked',
    title: "modals:simple.data46.title",
    text: "modals:simple.data46.text",
    button: 'modals:simple.data46.button',
  },
  {
    id: 'deletelog',
    title: "modals:simple.data47.title",
    text: "modals:simple.data47.text",
    button: 'modals:simple.data47.button',
  },
  {
    id: 'deletebilling',
    title: "modals:simple.data48.title",
    text: "modals:simple.data48.text",
    button: 'modals:simple.data48.button',
  },
  {
    id: 'makeactive',
    title: "modals:simple.data49.title",
    text: "modals:simple.data49.text",
    button: 'modals:simple.data49.button',
  },
  {
    id: 'makeinactive',
    title: "modals:simple.data50.title",
    text: "modals:simple.data50.text",
    button: 'modals:simple.data50.button',
  },
  {
    id: 'sendemail',
    title: "modals:simple.data51.title",
    text: "modals:simple.data51.text",
    button: 'modals:simple.data51.button',
  },
  {
    id: 'deleteemail',
    title: "modals:simple.data52.title",
    text: "modals:simple.data52.text",
    button: 'modals:simple.data52.button',
  },
  {
    id: 'emailinfo',
    title: "modals:simple.data53.title",
    text: "modals:simple.data53.text",
    button: 'modals:simple.data53.button',
  },
  {
    id: 'emaillocked',
    title: "modals:simple.data54.title",
    text: "modals:simple.data54.text",
    button: 'modals:simple.data54.button',
  },
  {
    id: 'welcomelocked',
    title: "modals:simple.data55.title",
    text: "modals:simple.data55.text",
    button: 'modals:simple.data55.button',
  },
  {
    id: 'emailsettingsinfo',
    title: "modals:simple.data56.title",
    text: "modals:simple.data56.text",
    button: 'modals:simple.data56.button',
  },
  {
    id: 'emailsettingslocked',
    title: "modals:simple.data57.title",
    text: "modals:simple.data57.text",
    button: 'modals:simple.data57.button',
  },
  {
    id: 'sessionreminder',
    title: "modals:simple.data58.title",
    text: "modals:simple.data58.text",
    button: 'modals:simple.data58.button',
  },
  {
    id: 'sessionreminder11',
    title: "modals:simple.data59.title",
    text: "modals:simple.data59.text",
    button: 'modals:simple.data59.button',
  },
  {
    id: 'nostock',
    title: "modals:simple.data60.title",
    text: "modals:simple.data60.text",
    button: 'modals:simple.data60.button',
  },
  {
    id: 'nostock2',
    title: "modals:simple.data61.title",
    text: "modals:simple.data61.text",
    button: 'modals:simple.data61.button',
  },
  {
    id: 'deletelocation',
    title: 'modals:simple.data62.title',
    text: "modals:simple.data62.text",
    button: 'modals:simple.data62.button',
  },
  {
    id: 'debitstock',
    title: 'modals:simple.data63.title',
    text: "modals:simple.data63.text",
    button: 'modals:simple.data63.button',
  },
]

class ModalMessageSimple extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      var: props.var
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      var: props.var,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  clickMainButton() {
    this.hide()
    this.props.clickMainButton()
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataMessageSimple) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(this.state.show && data !== null) {
      text = lang.t(data.text)
      text = text.replace('$var', this.state.var)
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t(data.title)}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-30">{text}</p>
            <button className="btn primary" onClick={() => this.clickMainButton()}>{lang.t(data.button)}</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalMessageSimple);