import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import HelperBusiness from '../../helper/Business';
import HelperEmpty from '../../helper/Empty';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListClient from '../../components/List/client';
import ListEmpty from '../../components/List/empty';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalForm from '../../components/Modals/form';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class FillDocumentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      sections: [],
      client: null,
      editing: false,
      histMode: 'list',
      histItem: '',
      histValues: ['', ''],
      error: false,
      showModalNote: false,
      showModalDelete: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Form Response - PT Mate', ROUTES.ADMINDOC)
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('formsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var form = null
      var client = null
      var arr = this.props.location.pathname.split('/')
      for(var item of global.forms) {
        if(item.id === arr[arr.length-1]) {
          form = item
        }
      }

      for(var item2 of global.clients) {
        if(item2.id === arr[arr.length-3]) {
          console.log(item2.forms.length)
          client = item2
          for(var item3 of item2.forms) {
            console.log(item3.id)
            if(item3.id === arr[arr.length-1]) {
              form = item3
            }
          }
        }
      }

      var sections = []
      if(form !== null) {
        for(var sec of form.sections) {
          if(sec.data.type !== 'yesno' && sec.data.type !== 'selection' && sec.data.type !== 'rating') {
            sections.push({
              id: sec.id,
              data: {
                label: sec.data.label,
                type: sec.data.type,
                response: sec.data.response,
                mandatory: (sec.data.mandatory === undefined ? false : sec.data.mandatory),
                seq: sec.data.seq
              }
            })
          }
          if(sec.data.type === 'yesno') {
            sections.push({
              id: sec.id,
              data: {
                label: sec.data.label,
                type: sec.data.type,
                response: sec.data.response,
                seq: sec.data.seq,
                answer1: sec.data.answer1,
                answer2: sec.data.answer2,
                detail: sec.data.detail,
                mandatory: (sec.data.mandatory === undefined ? false : sec.data.mandatory),
              }
            })
          }
          if(sec.data.type === 'selection') {
            sections.push({
              id: sec.id,
              data: {
                label: sec.data.label,
                type: sec.data.type,
                response: sec.data.response,
                seq: sec.data.seq,
                options: sec.data.options,
                multiple: sec.data.multiple,
                mandatory: (sec.data.mandatory === undefined ? false : sec.data.mandatory),
              }
            })
          }
          if(sec.data.type === 'rating') {
            sections.push({
              id: sec.id,
              data: {
                label: sec.data.label,
                type: sec.data.type,
                response: sec.data.response,
                seq: sec.data.seq,
                num: sec.data.num,
                mandatory: (sec.data.mandatory === undefined ? false : sec.data.mandatory),
              }
            })
          }
        }
      }

      if(!this.state.editing) {
        this.setState({
          item: form,
          sections: sections,
          clients: global.clients,
          client: client
        })
      }
    }
  }


  updateMode(mode, item) {
    var title = ''
    var desc = ''
    for(var hist of this.state.item.history) {
      if(hist.id === item) {
        title = hist.data.title
        desc = hist.data.desc
      }
    }
    this.setState({
      histMode: mode,
      histItem: item,
      histValues: [title, desc]
    })
  }


  onChangeHistory = event => {
    var tmp = this.state.histValues
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({histValues: tmp});
  };


  createHistory() {
    if(this.state.histValues[0] !== '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/forms/'+this.state.item.id+'/notes').push({
        date: Moment().format('DD/MM/YYYY HH:mm'),
        title: this.state.histValues[0],
        desc: this.state.histValues[1]
      })
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.notecreated'));
      this.setState({
        histMode: 'list',
        histValues: ['', '']
      }) 
    }
  }



  // Modals ------------------------------------------------------------



  showModalHistory(item) {
    if(this.state.histMode !== 'form') {
      this.updateMode('edit', item)
      this.setState({
        showModalNote: true,
      })
    }
  }


  hideModals() {
    this.setState({
      showModalNote: false,
      showModalDelete: false,
    })
  }


  updateNotes(event, num) {
    var tmp = this.state.histValues
    tmp[num] = event
    this.setState({histValues: tmp});
  }


  updateHistory() {
    if(this.state.histValues[0] !== '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/forms/'+this.state.item.id+'/notes/'+this.state.histItem).update({
        title: this.state.histValues[0],
        desc: this.state.histValues[1]
      })
      this.hideModals()
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.noteupdated'));
    }
  }


  deleteHistory() {
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/forms/'+this.state.item.id+'/notes/'+this.state.histItem).remove()
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.notedeleted'));
    this.hideModals()
    this.setState({
      histMode: 'list'
    })
  }


  deleteForm() {
    this.hideModals()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/forms/'+this.state.item.id).remove()
    .then((data)=>{
      global.message = 'Response successfully deleted'
      var url = (global.linkCli === '' ? '/admin/documents/form/'+this.state.item.id : global.linkCli)
      this.props.history.push(url)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Interactive stuff ------------------------------------------------------------



  onChangeField(value, item) {
    var tmp = this.state.sections
    for(var sec of tmp) {
      if(sec.id === item.id) {
        sec.data.response = value
      }
    }
    this.setState({
      sections: tmp
    })
  }


  onChangeDetails(value, item) {
    var tmp = this.state.sections
    for(var sec of tmp) {
      if(sec.id === item.id) {
        sec.data.detail = value
      }
    }
    this.setState({
      sections: tmp
    })
  }


  onChangeSelection(value, item) {
    var tmp = this.state.sections
    var arr = item.data.response.split(',')
    var exists = false
    if(arr.length > 0) {
      for(var i=arr.length-1; i>-1; i--) {
        if(arr[i] === String(value)) {
          arr.splice(i, 1)
          exists = true
        }
        if(arr[i] === '') {
          arr.splice(i, 1)
        }
      }
    }
    if(!exists) {
      if(item.data.multiple) {
        arr.push(String(value))
      }
      else {
        arr = [String(value)]
      }
    }
    var final = ''
    for(var a of arr) {
      final += ','+a
    }
    final = final.substring(1)
    for(var sec of tmp) {
      if(sec.id === item.id) {
        sec.data.response = final
      }
    }
    this.setState({
      sections: tmp
    })
  }


  getSelectionClass(value, item, type) {
    var label = ''
    if(type === 'static') {
      label = 'none'
    }
    var arr = item.data.response.split(',')
    if(arr.indexOf(String(value)) !== -1) {
      label = 'active'
      if(type === 'static') {
        label = ''
      }
    }
    return label
  }


  updateResponse() {
    var passed = true
    for(var sc of this.state.sections) {
      if(sc.data.mandatory && sc.data.response === '') {
        passed = false
      }
    }
    if(passed) {
      this.setState({
        editing: false,
        error: false,
      }, () => {
        var assembled = {
          name: this.state.item.data.name,
          version: this.state.item.data.version,
          pre: this.state.item.data.pre,
          uid: this.state.item.data.uid,
          date: parseInt(Moment().format('X')),
          sections: [],
          notes: [],
        }
        for(var his of this.state.item.history) {
          assembled.notes[his.id] = {
            date: his.data.date,
            desc: his.data.desc,
            title: his.data.title,
          }
        }

        for(var item of this.state.sections) {
          if(item.data.type !== 'yesno' && item.data.type !== 'selection' && item.data.type !== 'rating') {
            assembled.sections[item.id] = {
              type: item.data.type,
              label: item.data.label,
              response: item.data.response,
              mandatory: item.data.mandatory,
              seq: item.data.seq,
            }
          } else if(item.data.type === 'yesno') {
            assembled.sections[item.id] = {
              type: item.data.type,
              label: item.data.label,
              response: item.data.response,
              answer1: item.data.answer1,
              answer2: item.data.answer2,
              detail: item.data.detail,
              mandatory: item.data.mandatory,
              seq: item.data.seq
            }
          } else if(item.data.type === 'selection') {
            assembled.sections[item.id] = {
              type: item.data.type,
              label: item.data.label,
              response: item.data.response,
              options: item.data.options,
              multiple: item.data.multiple,
              mandatory: item.data.mandatory,
              seq: item.data.seq
            }
          } else if(item.data.type === 'rating') {
            assembled.sections[item.id] = {
              type: item.data.type,
              label: item.data.label,
              response: item.data.response,
              num: item.data.num,
              mandatory: item.data.mandatory,
              seq: item.data.seq
            }
          }
        }

        Firebase.database().ref('clients/'+global.uid+'/'+this.state.client.id+'/forms/'+this.state.item.id).set(
          assembled
        ).then((data)=>{
          if(this.state.histMode === 'form') {
            this.createHistory()
          } else {
            EventEmitter.dispatch('showMessage', lang.t('messaging:message.responseupdated'));
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      })
    } else {
      this.setState({
        error: true
      })
    }
  }


  cancelEditing() {
    this.setState({
      editing: false
    }, () => {
      this.configureData()
    })
  }



  // Display stuff ------------------------------------------------------------



  renderText(item) {
    if(this.state.editing) {
      return (
        <input type="text" value={item.data.response} onChange={(event) => this.onChangeField(event.target.value, item)} />
      )
    } else {
      return (
        <p>{item.data.response === '' ? '-' : item.data.response}</p>
      )
    }
  }


  getResponse(value) {
    var label = lang.t('admin:label.forms.yes')
    if(value === '1') {
      label = lang.t('admin:label.forms.no')
    }
    return label
  }


  renderYesNo(item) {
    if(this.state.editing) {
      return (
        <div className="radios">
          <button className={item.data.response === '0' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.onChangeField('0', item)}>{lang.t('admin:label.forms.yes')}</button>
          <button className={item.data.response === '1' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.onChangeField('1', item)}>{lang.t('admin:label.forms.no')}</button>
        </div>
      )
    } else {
      return (
        <p>{item.data.response === '' ? '-' : this.getResponse(item.data.response)}</p>
      )
    }
  }


  renderYesNoDetail(item) {
    if(this.state.editing) {
      if(item.data.answer1 && !item.data.answer2 && item.data.response === '0') {
        return (
          <div>
            <div className="space-10"></div>
            <label>{lang.t('admin:label.forms.providedetails')}</label>
            <input type="text" value={item.data.detail} onChange={(event) => this.onChangeDetails(event.target.value, item)} />
          </div>
        )
      } else if(!item.data.answer1 && item.data.answer2 && item.data.response === '1') {
        return (
          <div>
            <div className="space-10"></div>
            <label>{lang.t('admin:label.forms.providedetails')}</label>
            <input type="text" value={item.data.detail} onChange={(event) => this.onChangeDetails(event.target.value, item)} />
          </div>
        )
      } else if(item.data.answer1 && item.data.answer2 && item.data.response !== '') {
        return (
          <div>
            <div className="space-10"></div>
            <label>{lang.t('admin:label.forms.providedetails')}</label>
            <input type="text" value={item.data.detail} onChange={(event) => this.onChangeDetails(event.target.value, item)} />
          </div>
        )
      }
    } else {
      if(item.data.detail !== '') {
        return (
          <p>{item.data.detail}</p>
        )
      }
    }
  }


  renderRate(item) {
    if(this.state.editing) {
      var per = (100/item.data.num)+'%'
      var list = []
      for(var i=0; i<item.data.num; i++) {
        list.push(i+1)
      }
      return (
        <div className="radios">
          {list.map((num) => (
            <button className={item.data.response === String(num) ? 'radio lft active' : 'radio lft'} style={{width: per}} onClick={() => this.onChangeField(String(num), item)}>{num}</button>
          ))}
        </div>
      )
    } else {
      return (
        <p>{item.data.response === '' ? '-' : item.data.response+' of '+item.data.num}</p>
      )
    }
  }


  renderSelection(item) {
    if(this.state.editing) {
      return (
        <div className="clear">
          {item.data.options.map((option, index) => (
            <div className={'checkbox clear mt-10 inlist '+this.getSelectionClass(index, item, 'edit')} onClick={() => this.onChangeSelection(index, item)}>
              <div className="check">
                <div className="inner"></div>
              </div>
              <p className="ml-10 lft">{option}</p>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    } else {
      if(item.data.response !== '') {
        return (
          <ul className="selection">
          {item.data.options.map((option, index) => (
            <li className={'default '+this.getSelectionClass(index, item, 'static')}>
              {option}
            </li>
          ))}
        </ul>
        )
      } else {
        return (
          <div>
            <div className="clear sv-10"></div>
            <p>-</p>
          </div>
        )
      }
    }
  }


  renderQuestion(item) {
    if(item.data.type === 'header') {
      return (
        <div>
          <h4 className="mb-30">{item.data.label}</h4>
          <div className="clear"></div>
        </div>
      )
    } else if(item.data.type === 'paragraph') {
      return (
        <div>
          <p>{item.data.label}</p>
          <div className="clear sv-30"></div>
        </div>
      )
    } else if(item.data.type === 'text') {
      return (
        <div>
          <label>{item.data.label}{item.data.mandatory ? '*' : ''}</label>
          {this.renderText(item)}
          <div className="clear sv-30"></div>
        </div>
      )
    } else if(item.data.type === 'yesno') {
      return (
        <div>
          <label>{item.data.label}{item.data.mandatory ? '*' : ''}</label>
          {this.renderYesNo(item)}
          {this.renderYesNoDetail(item)}
          <div className="clear sv-30"></div>
        </div>
      )
    } else if(item.data.type === 'selection') {
      return (
        <div>
          <label>{item.data.label}{item.data.mandatory ? '*' : ''}</label>
          <span className={item.data.multiple ? 'pill' : 'none'}>{lang.t('admin:label.forms.multipleselection')}</span>
          {this.renderSelection(item)}
          <div className="clear sv-30"></div>
        </div>
      )
    } else if(item.data.type === 'rating') {
      return (
        <div>
          <label>{item.data.label}{item.data.mandatory ? '*' : ''}</label>
          {this.renderRate(item)}
          <div className="clear sv-30"></div>
        </div>
      )
    }
  }


  renderButtonDelete() {
    if(!this.state.item.data.pre) {
      return (
        <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})}>{lang.t('admin:button.deletethisresponse')}</button>
      )
    }
  }


  renderButton() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      if(this.state.editing) {
        return (
          <div>
            <button className="btn primary" onClick={() => this.updateResponse()}>{lang.t('common:button.savechanges')}</button>
            <div className="clear sv-20"></div>
            <button className="btn tertiary width-12 close" onClick={() => this.cancelEditing()}>{lang.t('common:button.cancel')}</button>
          </div>
        )
      } else {
        if(this.state.histMode === 'list') {
          return (
            <div>
              <button className="btn secondary width-12" onClick={() => this.setState({editing: true})}>{lang.t('admin:button.editresponse')}</button>
              <div className="clear sv-30"></div>
              {this.renderButtonDelete()}
            </div>
          )
        }
      }
    }
  }


  renderTop() {
    if(this.state.item.data.pre) {
      return (
        <div className="infobox green mb-10">
          <p>{lang.t('admin:label.preexercise')}</p>
        </div>
      )
    }
  }


  renderNoteForm() {
    if(this.state.histMode === 'form') {
      return (
        <div>
          <h4 className="mb-20">{lang.t('admin:button.addanote')}</h4>
          <div className="clear"></div>
          <div className="form-item">
            <label>{lang.t('admin:form.title')}*</label>
            <input type="text" value={this.state.histValues[0]} name="0" onChange={this.onChangeHistory}/>
          </div>
          <div className="form-item">
            <label>{lang.t('admin:form.description')}</label>
            <textarea value={this.state.histValues[1]} name="1" onChange={this.onChangeHistory}></textarea>
          </div>
          <button className="btn primary mb-20" onClick={() => this.updateResponse()}>{lang.t('admin:button.createnote')}</button>
          <button className="btn tertiary close width-12" onClick={() => this.updateMode('list', '')}>{lang.t('common:button.cancel')}</button>
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderNotes() {
    if(this.state.item !== null) {
      if(this.state.item.history.length === 0) {
        return (
          <div className="empty small">
            {HelperEmpty.renderImage('notes')}
            <h4>{lang.t('admin:label.nonotesyet')}</h4>
          </div>
        )
      } else {
        var list = this.state.item.history
        list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
        return (
          <div>
            {list.map(item => (
              <div className="list bare" key={item.id} onClick={() => this.showModalHistory(item.id)}>
                <div className="main">
                  <h4>{item.data.title}</h4>
                  <p>{item.data.desc}</p>
                  <p>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('llll')}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderHistoryLink() {
    if(this.state.histMode !== 'form' && !this.state.editing) {
      return (
        <button className="btn tertiary small add rgt" onClick={() => this.updateMode('form', '')} style={{marginTop: 10}}>{lang.t('admin:button.addanote')}</button>
      )
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <div className="inline-message red mb-20">
          <strong>{lang.t('admin:error.filldocument')}</strong>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      if(this.state.item === null) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content"></div>
            <Header title={lang.t('admin:label.response')} link={ROUTES.ADMINDOC}/>
            <Navigation active='clients' />
          </div>
        )
      } else {
        var list = this.state.sections
        list.sort((a, b) => a.data.seq - b.data.seq)
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content">
              {this.renderTop()}
              <div className="clear"></div>
              <div className="col-8 mt-20 withsidebar">
                <div className="box question">
                  {list.map((item) => (
                    <div>
                      {this.renderQuestion(item)}
                    </div>
                  ))}
                  <div className="clear sv-20"></div>
                  {this.renderError()}
                  {this.renderButton()}
                  <div className="clear sv-20"></div>
                </div>
              </div>
  
              <div className="col-4 mt-20">
                <h3 className="mb-20">{lang.t('admin:label.clientresponse')}</h3>
                <ListClient client={this.state.client} type=' static noborder'/>
                <div className="sv-40"></div>
                <div className="info">
                  <label>{lang.t('admin:label.response')}</label>
                  <p>{this.state.item.data.date === undefined ? lang.t('admin:label.notyetcompleted') : lang.t('admin:label.completedon')+' '+Moment(this.state.item.data.date, 'X').locale(lang.language).format('D MMM YYYY')}<br/><span style={{fontSize: 11}}>{lang.t('admin:label.version')} {this.state.item.data.version}</span></p>
                </div>
                <h3 className="lft mt-20 mb-20">{lang.t('admin:label.notes')}</h3>
                {this.renderHistoryLink()}
                <div className="clear"></div>
                {this.renderNoteForm()}
                {this.renderNotes()}
                <div className="clear"></div>
              </div>
              <div className="sv-40 clear"></div>
            </div>
            <Header title={this.state.item.data.name} link={global.linkCli === '' ? '/admin/documents/form/'+this.state.item.id : global.linkCli}/>
            <Navigation active='clients' />
            <ModalMessageSimple type='deleteresponse' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteForm()}/>
            <ModalForm type={'editnote'} show={this.state.showModalNote} onHide={() => this.hideModals()} value1={this.state.histValues[0]} updateValue1={(event) => this.updateNotes(event, 0)} value2={this.state.histValues[1]} updateValue2={(event) => this.updateNotes(event, 1)} clickMainButton={() => this.updateHistory()} clickSecondary={() => this.deleteHistory()}/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='forms-locked' type='simple'/>
          </div>
          <Header title={lang.t('admin:title.form')} link={'/overview'}/>
          <Navigation active='admin' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['admin','common','header','messaging'])(withRouter(withAuthorization(condition)(FillDocumentPage)));