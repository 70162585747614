import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import HelperEmpty from '../../helper/Empty';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import ListClient from '../../components/List/client';
import InfoData from '../../components/Form/info';
import InputToggle from '../../components/Form/toggle';
import InputDate from '../../components/Form/input-date';
import Loader from '../../components/Form/loader';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalForm from '../../components/Modals/form';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class DirectDebitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      client: '',
      item: null,
      clientItem: null,
      clients: global.clients,
      clientsInactive: global.clientsInactive,
      payments: [],
      dateRange: global.dateRange,
      showOverlay: false,
      hidden: 'hidden',
      margin: 0,
      loading: false,
      pauseDate: '',
      showOverlayFamily: false,
      hiddenFamily: 'hidden',
      mode: '',
      token: '',
      attempts: 0,
      next: Moment(),
      checking: false,
      linked: [],
      account: '',
      cancel: Moment().format('YYYY-MM-DD'),
      cdelayed: false,
      showModalCancel: false,
      showModalPause: false,
      showModalResume: false,
      showModalFamily: false,
      hiddenCancel: 'hidden',
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Membership Details - PT Mate')
    this._isMounted = true

    EventEmitter.subscribe('clientsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => this.configureData())
    EventEmitter.subscribe('tokenLoaded', (event) => this.setState({token: global.clientToken}))
    this.getClients()
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false
    })
  }


  getClients() {
    if(this._isMounted) {
      this.setState({
        clients: global.clients,
        clientsInactive: global.clientsInactive
      }, () => {
        if(this.state.loading && this.state.mode === 'cancel') {
          global.message = lang.t('messaging:message.membershipcanceled')
          var link = global.currentPage
          if(global.linkCli !== '') {
            link = global.linkCli
          }
          this.props.history.push(link)
        } else if(this.state.loading && this.state.mode === 'cancel2') {
          EventEmitter.dispatch('showMessage', lang.t('messaging:message.cancellationdateset'));
          this.setState({
            loading: false
          })
        } else {
          this.configureData();
        }
        if(this.state.loading && this.state.mode === 'pause') {
          EventEmitter.dispatch('showMessage', lang.t('messaging:message.membershippaused'));
          this.setState({
            loading: false
          })
        }
        if(this.state.loading && this.state.mode === 'resume') {
          EventEmitter.dispatch('showMessage', lang.t('messaging:message.membershipresumed'));
          this.setState({
            loading: false
          })
        }
      });
    }
  }


  configureData() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      var item = null
      var citem = null
      var next = this.state.next
      var attempts = this.state.attempts
      var checking = this.state.checking
      var linked = []
      var end = Moment()
      var cend = false
      for(var client of this.state.clients) {
        if(client.id === arr[arr.length-2]) {
          if(global.currentClient === '') {
            global.currentClient = client.id
          }
          citem = client
          for(var sub of client.subscriptions) {
            if(sub.id === arr[arr.length-1]) {
              item = sub
              if(item.data.end !== undefined) {
                end = Moment(item.data.end, 'X').format('YYYY-MM-DD')
                cend = true
              }
            }
          }
          if(client.data.uid !== '') {
            Connector.getClientToken(client.data.uid)
          }
          if(client.data.linked !== undefined) {
            linked.push({value: '', name: lang.t('billing:label.family')})
            linked.push({value: this.state.client, name: this.getFamilyName(this.state.client)})
            for(var li of client.data.linked) {
              linked.push({value: li, name: this.getFamilyName(li)})
            }
          }
        }
      }
      for(var client2 of this.state.clientsInactive) {
        if(client2.id === arr[arr.length-2]) {
          if(global.currentClient === '') {
            global.currentClient = client.id
          }
          citem = client2
          for(var sub2 of client2.subscriptions) {
            if(sub2.id === arr[arr.length-1]) {
              item = sub2
              if(item.data.end !== undefined) {
                end = Moment(item.data.end, 'X').format('YYYY-MM-DD')
                cend = true
              }
            }
          }
        }
      }
      for(var log of global.userLog) {
        if(log.data.sub === item.id && item !== null) {
          attempts = log.data.title
          next = log.data.message
          Firebase.database().ref('/log/'+global.uid+'/'+log.id).remove()
        }
      }
      this.setState({
        id: arr[arr.length-1],
        client: arr[arr.length-2],
        clientItem: citem,
        item: item,
        attempts: attempts,
        next: Moment(next, 'X'),
        checking: checking,
        linked: linked,
        cancel: end,
        cdelayed: cend
      }, () => {
        this.configurePayments()
        // Check for attempts
        if(item !== null && !this.state.checking) {
          if(this.state.next !== Moment() && item.data.status !== 'active') {
            this.setState({
              checking: true
            })
            var callFunction = Firebase.functions().httpsCallable('getInvoice');
            callFunction({uid: global.uid, sub: item.id, account: global.userStripeConnect}).then(function(result) {});
          }
        }
      });
    }
  }


  configurePayments() {
    var minDate = Moment().add(-46, 'days').format('X')
    var tmp = []
    for(var item of global.payments) {
      var sdate = Moment('01/01/1900', 'DD/MM/YYYY HH:mm').format('X')
      if(this.state.item !== null) {

        sdate = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(-1, 'days').format('X')
      }

      var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(item.data.timestamp !== undefined) {
        date = Moment(item.data.timestamp, 'X').format('X')
      }

      if(item.data.client === this.state.client && date > minDate && date >= sdate) {
        if(item.data.name.indexOf('Subscription') !== -1 ||item.data.name.indexOf('Invoice') !== -1) {
          tmp.push(item)
        }
        
      }
    }
    this.setState({
      payments: tmp
    })
  }


  configureLog() {
    for(var item of this.state.log) {
      if(item.data.title === 'cardnewerror' || item.data.title === 'carddeleteerror') {
        EventEmitter.dispatch('showMessageError', item.data.message);
        Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
        this.setState({
          loading: false,
          valuesCard: ['', '', '', '', ''],
          errorCard: [false, false, false, false, false],
        })
        
      }
      if(item.data.title === 'cardcreated' || item.data.title === 'carddeleted') {
        EventEmitter.dispatch('showMessage', this.state.message);
        Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
        this.setState({
          loading: false,
          valuesCard: ['', '', '', '', ''],
          errorCard: [false, false, false, false, false],
        })
      }
    }
  }


  getBilling() {
    var label = this.state.item.data.billing
    if(label === "week") {
      for(var prod of global.products) {
        if(prod.id === this.state.item.data.plan) {
          label = prod.data.billing
        }
      }
    }
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  setBackLink() {
    var link = global.currentPage
    if(global.linkCli !== '') {
      link = global.linkCli
    }
    return link
  }


  configureIncome() {
    var count = 0
    for(var item of this.state.payments) {
      count += item.data.amount
    }
    return (count/100).toFixed(2)
  }


  getNextPayment(type) {
    var label = '-'
    var start = parseInt(Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('X'))
    if(this.state.item.data.start !== undefined && this.state.item.data.start !== 0) {
      start = this.state.item.data.start
    }
    var add = parseInt(Moment().add(7, 'days').format('X'))-parseInt(Moment().format('X'))
    if(this.state.item.data.billing === 'fortnight') {
      add = parseInt(Moment().add(14, 'days').format('X'))-parseInt(Moment().format('X'))
    } else if(this.state.item.data.billing === 'month') {
      add = parseInt(Moment().add(1, 'month').format('X'))-parseInt(Moment().format('X'))
    }

    if(Moment(start, 'X').format('X') > Moment().format('X')) {
      label = Moment(start, 'X').locale(lang.language).format('D MMMM YYYY')
    } else {
      var date = start
      while (date < parseInt(Moment().format('X'))) {
        date += add
        label = Moment(date, 'X').locale(lang.language).format('D MMMM YYYY')
      }
    }

    if(this.state.item.data.next !== undefined) {
      label = Moment(this.state.item.data.next, 'X').locale(lang.language).format('D MMMM YYYY')
    }

    if(this.state.item.data.pause !== '' && this.state.item.data.pause !== undefined && type === 'all') {
      label = Moment(this.state.item.data.pause, 'X').locale(lang.language).format('D MMMM YYYY')
    }
    if(this.state.item.data.end !== undefined) {
      if(this.state.item.data.end === this.state.item.data.next) {
        label = '-'
      }
    }
    return label
  }


  getSub() {
    var callFunction = Firebase.functions().httpsCallable('getClientSubscription');
    callFunction({account: global.userStripeConnect, sub: this.state.id, client: this.state.client, uid: global.uid}).then(function(result) {});
  }


  removeDebit() {
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client+'/subscriptions/'+this.state.item.id).remove()
    var link = global.currentPage
    if(global.linkCli !== '') {
      link = global.linkCli
    }
    this.props.history.push(link)
  }



  // Cancellation modal ------------------------------------------------------------



  showModalCancel() {
    this.setState({
      showModalCancel: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenCancel: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalCancel() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenCancel: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalCancel: false});
      this.hideModals();
    }, 500);
  }


  renderModalDate() {
    if(this.state.cdelayed) {
      return (
        <InputDate label={lang.t('modals:canceldebit.date.label')} value={Moment(this.state.cancel, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' min={Moment().format('YYYY-MM-DD')} onChange={(event) => this.setState({cancel: event})}/>
      )
    }
  }


  renderModalWarning() {
    var show = false
    var diff = Moment(this.state.cancel, 'YYYY-MM-DD').diff(Moment(this.state.item.data.next, 'X'), 'days')+1
    var base = 7
    var date = Moment()
    if(this.state.item.data.billing === 'fortnight') {
      base = 14
    }
    if(diff % base !== 0) {
      show = true
      var num = parseInt(diff/base)*base+base
      date = Moment(this.state.item.data.next, 'X').add(num, 'days')
    }
    if(this.state.item.data.billing === 'month') {
      show = false
      if(Moment(this.state.item.data.next, 'X').format('DD') !== Moment(this.state.cancel, 'YYYY-MM-DD').format('DD')) {
        show = true
        diff = Moment(this.state.cancel, 'YYYY-MM-DD').diff(Moment(this.state.item.data.next, 'X'), 'months')+1
        date = Moment(this.state.item.data.next, 'X').add(diff, 'months')
      }
    }
    var label = lang.t('modals:canceldebit.text1')+" "+global.curSym+(this.state.item.data.price/100).toFixed(2)+". "+lang.t('modals:canceldebit.text2')+" "+Moment(date).locale(lang.language).format('D MMM YYYY')+"."
    if(Moment(this.state.cancel, 'YYYY-MM-DD').format('X') < Moment(this.state.item.data.next, 'X').format('X')) {
      label = lang.t('modals:canceldebit.text3')+' '+Moment(this.state.item.data.next, 'X').locale(lang.language).format('D MMM YYYY')+"."
      show = true
    }
    if(!this.state.cdelayed) {
      show = false
    }
    if(show) {
      return (
        <div className="infobox yellow mb-20">
          <p className="small">{label}</p>
        </div>
      )
    }
  }


  renderModalCancel() {
    if(this.state.showModalCancel) {
      return (
        <div className={'overlay '+this.state.hiddenCancel}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:canceldebit.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalCancel()}>Close</button>
            <p className="clear mb-20">{lang.t('modals:canceldebit.text')}</p>
            <InputToggle label={lang.t('modals:canceldebit.date')} value={this.state.cdelayed} onChange={(event) => this.setState({cdelayed: event})} text={lang.t('modals:canceldebit.date.text')}/>
            {this.renderModalDate()}
            {this.renderModalWarning()}
            <div className="sv-20"></div>
            <button className="btn primary" onClick={() => this.cancelDebit()}>{this.state.cdelayed ? lang.t('modals:canceldebit.button.date') : lang.t('modals:canceldebit.button')}</button>
          </div>
        </div>
      )
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalCancel: false,
      showModalPause: false,
      showModalResume: false,
      showModalFamily: false,
    })
  }


  pauseDebit() {
    if(this.state.pauseDate !== '') {
      this.hideModals()
      this.setState({
        loading: true,
        mode: 'pause'
      })
      var resume = Moment(this.state.pauseDate, 'YYYY-MM-DD').format('X')
      var callFunction = Firebase.functions().httpsCallable('connectedUpdateSubscription');
      callFunction({type: "pause", account: global.userStripeConnect, resume: resume, id: this.state.id, client: this.state.client, uid: global.uid}).then(function(result) {});
    }
  }


  resumeDebit() {
    this.hideModals()
      this.setState({
        loading: true,
        mode: 'resume'
      })
    var callFunction = Firebase.functions().httpsCallable('connectedUpdateSubscription');
    callFunction({type: "unpause", account: global.userStripeConnect, id: this.state.id, client: this.state.client, uid: global.uid}).then(function(result) {});
  }


  updateFamily() {
    this.hideModals()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client+'/subscriptions/'+this.state.item.id).update({
      account: this.state.account
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.changessaved'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  updateCancel(event) {
    var cdel = false
    if(event !== Moment().format('YYYY-MM-DD')) {
      cdel = true
    }
    this.setState({
      cancel: event,
      cdelayed: cdel
    })
  }


  cancelDebit() {
    if(this.state.client !== undefined && this.state.client !== '') {
      if(this.state.item.data.status === 'deleted') {
        Firebase.database().ref('/clients/'+global.uidUser+'/'+this.state.client+'/subscriptions/'+this.state.id).remove()
        global.message = lang.t('messaging:message.membershipremoved')
        var link = global.currentPage
        if(global.linkCli !== '') {
          link = global.linkCli
        }
        this.props.history.push(link)
      } else {
        this.hideModalCancel()
        if(this.state.cdelayed) {
          var ds2 = Moment(this.state.cancel, 'YYYY-MM-DD').format('DD/MM/YYYY')
          var cdate = Moment(ds2+' 01:30', 'DD/MM/YYYY HH:mm').format('X')
          var callFunction = Firebase.functions().httpsCallable('connectedCancelSubscriptionDelayed');
          callFunction({account: global.userStripeConnect, client: this.state.client, id: this.state.id, enddate: parseInt(cdate), uid: global.uid}).then(function(result) {});
          //this.hideModals()
          this.setState({
            loading: true,
            mode: 'cancel2'
          })
        } else {
          console.log('cancelling here')
          console.log(this.state.client)
          console.log(global.userStripeConnect)
          var desc = 'You cancelled the membership '+this.state.item.data.name+' ('+global.curSym+(this.state.item.data.price/100).toFixed(2)+' per '+this.getBilling()+').'
          var callFunction2 = Firebase.functions().httpsCallable('connectedCancelSubscription');
          callFunction2({account: global.userStripeConnect, client: this.state.client, subscription: this.state.id, desc: desc, date: Moment().format('DD/MM/YYYY HH:mm'), uid: global.uid}).then(function(result) {});
          //this.hideModals()
          this.setState({
            loading: true,
            mode: 'cancel'
          })
        }
      }
    }
  }



  // Display stuff ------------------------------------------------------------



  renderAttempt() {
    var label = lang.t('billing:error.stripe.title')
    if(this.state.next.format('D/M/YYYY HH:mm') !== Moment().format('D/M/YYYY HH:mm')) {
      label = lang.t('billing:error.stripe.text1')+" "+this.state.attempts+" time"+(this.state.attempts === 1 ? lang.t('billing:error.stripe.text2a') : lang.t('billing:error.stripe.text2b'))+" "+this.state.next.locale(lang.language).format('dddd, D MMM')+'.'
    }
    return label
  }


  renderStatus() {
    if(this.state.item.data.status === 'past_due') {
      return (
        <div className="infobox yellow mb-20">
          <strong>{lang.t('billing:error.failed.title')}</strong>
          <p>{this.renderAttempt()} {lang.t('billing:error.failed.text')}<br/><br/><Link to={'/clients/'+this.state.client+'/details'} className="tertiary">{lang.t('billing:button.reviewpaymentmethod')}</Link></p>
        </div>
      )
    } else if(this.state.item.data.status === 'unpaid') {
      return (
        <div className="infobox red mb-20">
          <strong>{lang.t('billing:error.expired.title')}</strong>
          <p>{lang.t('billing:error.expired.text')}<br/><br/><Link to={'/clients/'+this.state.client+'/details'} className="btn tertiary small">{lang.t('billing:button.reviewpaymentmethod')}</Link></p>
        </div>
      )
    } else if(this.state.item.data.status === 'deleted') {
      return (
        <div className="infobox red mb-20">
          <strong>{lang.t('billing:error.notfound.title')}</strong>
          <p>{lang.t('billing:error.notfound.text')}</p>
        </div>
      )
    } else if(this.state.item.data.status === 'canceled') {
      return (
        <div className="infobox yellow mb-20">{lang.t('billing:label.canceled')}</div>
      )
    } else {
      if(this.state.item.data.pause !== '' && this.state.item.data.pause !== undefined) {
        return (
          <div className="infobox yellow mb-20">{lang.t('billing:label.paused')}</div>
        )
      } else {
        var start = Moment().add(-1, 'years').format('X')
        if(this.state.item.data.start !== undefined) {
          start = Moment(this.state.item.data.start, 'X')
        }
        if(Moment(start, 'X').isAfter(Moment())) {
          return (
            <div className="infobox primary mb-20">{lang.t('billing:label.starts')} {Moment(start, 'X').locale(lang.language).format('ddd, D MMM YYYY')}</div>
          )
        } else if(this.state.item.data.end !== undefined && this.state.item.data.end !== '') {
          if(Moment(this.state.item.data.end, 'X').isBefore(Moment())) {
            return (
              <div className="infobox yellow mb-20">{lang.t('billing:label.canceled')} {Moment(this.state.item.data.end, 'X').locale(lang.language).format('ddd, D MMM YYYY')}</div>
            )
          }
        } else {
          return (
            <div className="infobox green mb-20">{lang.t('billing:label.active')}</div>
          )
        }
      }
    }
  }


  renderMethod() {
    if(this.state.clientItem.data.cardBrand !== '' && this.state.clientItem.data.cardBrand !== undefined) {
      return (
        <p>{this.state.clientItem.data.cardBrand} ending {this.state.clientItem.data.cardLast4}</p>
      )
    } else {
      return (
        <p className="warning">{lang.t('billing:label.nopaymentmethod')}</p>
      )
    }
  }


  renderNextLabel() {
    var label = lang.t('billing:label.nextpaymentest')
    if(this.state.item.data.start !== 0 && this.state.item.data.start !== undefined && this.state.item.data.start !== '') {
      if(parseInt(Moment(this.state.item.data.start, 'X').format('X')) > parseInt(Moment().format('X'))) {
        label = lang.t('billing:label.firstpayment')
      }
    }
    if(this.state.item.data.next !== undefined) {
      label = lang.t('billing:label.nextpayment')
    }
    if(this.state.item.data.pause !== '' && this.state.item.data.pause !== undefined) {
      label = lang.t('billing:label.pauseduntil')
    }
    return label
  }


  renderPause() {
    if(this.state.item.data.status !== 'deleted' && HelperBusiness.getRoleIncl('admin')) {
      if(this.state.item.data.pause === '' || this.state.item.data.pause === undefined) {
        return <button className="btn secondary mb-30" onClick={() => this.setState({showModalPause: true})}>{lang.t('billing:button.pausemembership')}</button>
      } else {
        return <button className="btn secondary mb-30" onClick={() => this.setState({showModalResume: true})}>{lang.t('billing:button.resumemembership')}</button>
      }
    }
  }


  renderPaymentDate(item) {
    var label = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')
    if(item.data.timestamp !== undefined) {
      label = Moment(item.data.timestamp, 'X').locale(lang.language).format('D MMM YYYY')
    }
    return label
  }


  renderLimit(group) {
    var label = lang.t('billing:label.unlimited')
    if(group) {
      if(this.state.item.data.sessions !== undefined && this.state.item.data.sessions !== 0) {
        label = this.state.item.data.sessions
      }
    } else {
      if(this.state.item.data.is11) {
        if(this.state.item.data.sessions11 !== undefined && this.state.item.data.sessions11 !== 0) {
          label = this.state.item.data.sessions11
        }
      } else {
        if(this.state.item.data.sessions !== undefined && this.state.item.data.sessions !== 0) {
          label = this.state.item.data.sessions
        }
      }
    }
    return label
  }


  renderDebitType() {
    var label = this.renderLimit(false)+' '+lang.t('billing:form.type.11sessions')+' '
    if(this.state.item.data.group) {
      label = this.renderLimit(true)+' '+lang.t('billing:form.type.groupsessions-s')+' '
      if(this.state.item.data.is11) {
        label = this.renderLimit(true)+' '+lang.t('billing:form.type.groupand')+' '+this.renderLimit(false)+' '+lang.t('billing:form.type.11sessions')+' '
      }
    }
    return label
  }


  getReceiptURL(item) {
    if(global.showHabits) {
      return 'https://ptmate.app/billing/receipt/'+item.id
    } else {
      return item.data.receipt
    }
  }


  renderPayments() {
    if(this.state.payments.length === 0) {
      return (
        <div className="empty small">
          {HelperEmpty.renderImage('card')}
          <h4>{lang.t('billing:label.nopayments')}</h4>
        </div>
        
      )
    } else {
      var list = this.state.payments
      list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <ul className="linklist clear">
          {list.map((item) => (
            <div className="list bare" key={item.id}>
              <a href={this.getReceiptURL(item)} target="_blank" rel="noopener noreferrer">
                <div className="icon secondary">
                  <div className="inner card"></div>
                </div>
                <div className="main">
                  <h4>{global.curSym}{(item.data.amount/100).toFixed(2)}</h4>
                  <p>{this.renderPaymentDate(item)}</p>
                </div>
                <div className="clear"></div>
              </a>
            </div>
          ))}
        </ul>
      )
    }
  }


  renderEnd() {
    if(this.state.item.data.end !== undefined) {
      return (
        <InfoData label={lang.t('billing:label.automaticallyends')} value={Moment(this.state.item.data.end, 'X').locale(lang.language).format('D MMMM YYYY')} small={true}/>
      )
    }
  }


  getFamilyName(id) {
    var label = 'Client'
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }


  renderFamily() {
    for(var item of global.clients) {
      if(item.id === this.state.client && item.data.linked !== undefined) {
        if(item.data.linked.length > 0) {
          return <InfoData label={lang.t('billing:label.assignedto')} value={(this.state.item.data.account === undefined || this.state.item.data.account === '') ? lang.t('billing:label.family') : this.getFamilyName(this.state.item.data.account)} small={true} button={lang.t('billing:button.change')} clickButton={() => this.setState({showModalFamily: true})}/>
        }
      }
    }
  }


  renderDone() {
    var label = ''
    if(this.state.item.data.done !== undefined) {
      if(this.state.item.data.done > 0) {
        label = '('+this.state.item.data.done
        if(this.state.item.data.done11 !== undefined) {
          if(this.state.item.data.done11 > 0) {
            label += ' / '+this.state.item.data.done11
          }
        }
        label += ' '+lang.t('billing:label.done')+')'
      }
    }
    return label
  }


  renderButtons() {
    if(HelperBusiness.getRoleIncl('admin')) {
      if(this.state.item.data.status === 'canceled') {
        return (
          <button className="btn secondary" onClick={() => this.removeDebit()}>{lang.t('billing:button.removethismembership')}</button>
        )
      } else {
        return (
          <div>
            {this.renderPause()}
            <button className="btn tertiary close width-12" onClick={() => this.showModalCancel()}>{this.state.item.data.end !== undefined ? lang.t('billing:button.setcancellationdate') : lang.t('billing:button.cancelmembership')}</button>
          </div>
        )
      }
    }
  }


  renderGST() {
    if(global.spaceGST !== 0) {
      var label = '-'
      var mul = (this.state.item.data.price/100)/11
      var num = mul/10*global.spaceGST
      label = global.curSym+num.toFixed(2)
      return <span>GST incl.: {label}<br/></span>
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin,finance,cs')) {
      if(this.state.item !== null && this.state.item !== undefined) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-sidebar">
              <div className="col-6 mt-20">
                <div className="box">
                  {this.renderStatus()}
                  <ListClient client={this.state.clientItem} type='static border'/>
                  <div className="clear sv-20"></div>
                  <InfoData label={this.renderDebitType()+' '+this.renderDone()} value={this.state.item.data.name} small={true}/>
                  {this.renderFamily()}
                  <InfoData label={lang.t('billing:form.price')} value={global.curSym+(this.state.item.data.price/100).toFixed(2)+' per '+this.getBilling()} small={true}/>
                  <InfoData label={lang.t('billing:label.setup')} value={Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMMM YYYY')} small={true}/>
                  <InfoData label={this.renderNextLabel()} value={this.getNextPayment('all')} small={true}/>
                  {this.renderEnd()}
                  <div className="info">
                    <label>{lang.t('billing:label.paymentmethod')}</label>
                    {this.renderMethod()}
                  </div>
                  <div className="clear sv-20"></div>
                  {this.renderButtons()}
                </div>
              </div>
              <div className="col-6 mt-20">
                <div className="sidebar">
                  <h3 className="mb-20">{lang.t('header:title.info')}</h3>
                  <div className="infobox mb-40">
                    <p className="mb-20"><strong>{lang.t('billing:label.pricebreakdown')}</strong><br/>{lang.t('billing:label.gross')}: {global.curSym}{(this.state.item.data.price/100).toFixed(2)}<br/>{this.renderGST()}Stripe fees: {global.curSym}{((this.state.item.data.price/100)*0.0225+0.30).toFixed(2)}<br/>{lang.t('billing:label.afterfees')}: {global.curSym}{((this.state.item.data.price/100)*0.9775-0.30).toFixed(2)}</p>
                    <p><strong>{lang.t('billing:label.last45days')}</strong><br/>{lang.t('billing:label.totalincome')}: {global.curSym}{this.configureIncome()}<br/>{lang.t('billing:label.payments')}: {this.state.payments.length}</p>
                  </div>
                  <h3 className="mb-20">{lang.t('header:title.recentpayments')}</h3>
                  {this.renderPayments()}
                </div>
              </div>
              <div className="clear sv-40"></div>
              <button className="btn tertiary width-12 small mb-40" onClick={() => this.getSub()}>{lang.t('billing:button.confirmstatuswithstripe')}</button>
            </div>
            <Header title={lang.t('billing:label.membership')} link={this.setBackLink()}/>
            <Navigation active='billing' />
            <ModalForm type={'pausedebit'} show={this.state.showModalPause} onHide={() => this.hideModals()} value1={this.state.pauseDate} updateValue1={(event) => this.setState({pauseDate: event})} clickMainButton={() => this.pauseDebit()}/>
            <ModalMessageSimple type='resumedebit' show={this.state.showModalResume} onHide={() => this.hideModals()} clickMainButton={() => this.resumeDebit()}/>
            <ModalForm type={'assignfamily'} show={this.state.showModalFamily} onHide={() => this.hideModals()} value1={this.state.account} values1={this.state.linked} updateValue1={(event) => this.setState({account: event})} clickMainButton={() => this.updateFamily()}/>
            {this.renderModalCancel()}
            <Loader show={this.state.loading}/>
          </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-comments"></div>
            <Navigation active='billing' />
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-container">
            <ListEmpty id='debits-locked' type='simple'/>
          </div>
          <Header title={lang.t('billing:label.membership')} link={global.currentPage}/>
          <Navigation active='billing' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['billing','common','header','modals','messaging'])(withRouter(withAuthorization(condition)(DirectDebitPage)));