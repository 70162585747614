import React, { Component } from 'react';
import Firebase from 'firebase';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InputText from '../../components/Form/input-text';
import InputRadio from '../../components/Form/radio';
import InputToggle from '../../components/Form/toggle';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalMessageDouble from '../../components/Modals/message-double';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewDocumentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      pre: false,
      version: 1,
      sections: [],
      error: '',
      hidden: 'hidden',
      unsaved: true,
      saving: false,
      lock: false,
      showModalMessage: false,
      showModalSave: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }


  elementRefs = [];


  componentDidMount() {
    HelperPage.initialize('Form - PT Mate', ROUTES.ADMINDOC)
    this._isMounted = true
    
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('formsLoaded', (event) => this.configureData())
    this.configureData()

    var unblock = this.props.history.block(() => {
      if(this.state.unsaved) {
        unblock()
        this.setState({showModalSave: true})
        return false
      } else {
        return true
      }
    });
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() { 
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      if(arr[arr.length-1] === 'edit') {
        var item = null
        var lock = false
        var sections = []
        for(var form of global.forms) {
          if(form.id === arr[arr.length-2]) {
            item = form
            if(item.data.lock !== undefined) {
              lock = item.data.lock
            }
          }
        }
        if(item !== null) {
          for(var section of item.sections) {
            if(section.data.type !== 'yesno' && section.data.type !== 'selection' && section.data.type !== 'rating') {
              sections.push({
                label: section.data.label,
                type: section.data.type,
                mandatory: (section.data.mandatory === undefined ? false : section.data.mandatory)
              })
            } else if(section.data.type === 'yesno') {
              sections.push({
                label: section.data.label,
                type: section.data.type,
                answer1: section.data.answer1,
                answer2: section.data.answer2,
                mandatory: (section.data.mandatory === undefined ? false : section.data.mandatory)
              })
            } else if(section.data.type === 'selection') {
              sections.push({
                label: section.data.label,
                type: section.data.type,
                multiple: section.data.multiple,
                options: section.data.options,
                mandatory: (section.data.mandatory === undefined ? false : section.data.mandatory)
              })
            } else if(section.data.type === 'rating') {
              sections.push({
                label: section.data.label,
                type: section.data.type,
                num: section.data.num,
                mandatory: (section.data.mandatory === undefined ? false : section.data.mandatory)
              })
            }
          }
          this.setState({
            id: item.id,
            name: item.data.name,
            pre: item.data.pre,
            version: item.data.version+1,
            sections: sections,
            lock: lock,
          })
        }
      }
    }
  }


  checkCreateForm() {
    var message = ''
    if(this.state.name === '') {
      message = lang.t('admin:error.form1')
    }
    if(this.state.sections.length === 0) {
      message = lang.t('admin:error.form2')
    } else {
      for(var item of this.state.sections) {
        if(item.label === '') {
          message = lang.t('admin:error.form3')
        }
        if(item.type === 'selection' && item.options.length === 0) {
          message = lang.t('admin:error.form4')
        } else if(item.type === 'selection' && item.options.length > 0) {
          for(var opt of item.options) {
            if(opt === '') {
              message = lang.t('admin:error.form5')
            }
          }
        }
      }
    }
    if(message === '') {
      this.setState({
        unsaved: false,
        saving: false
      }, () => {
        this.createForm()
      })
    }
    this.setState({
      error: message,
      saving: true
    })
  }


  createForm() {
    var assembled = {
      name: this.state.name,
      version: this.state.version,
      pre: this.state.pre,
      uid: global.uid,
      lock: this.state.lock,
      sections: [],
    }

    var obj = Firebase.database().ref('/forms/'+global.uid).push()
    var seq = 0
    for(var item of this.state.sections) {
      var rf = obj.child('sections').push()
      if(this.state.id !== '') {
        rf = Firebase.database().ref('/forms/'+global.uid+'/'+this.state.id+'/sections/').push()
      }
      if(item.type !== 'yesno' && item.type !== 'selection' && item.type !== 'rating') {
        assembled.sections[rf.key] = {
          type: item.type,
          label: item.label,
          response: '',
          mandatory: item.mandatory,
          seq: seq
        }
      } else if(item.type === 'yesno') {
        assembled.sections[rf.key] = {
          type: item.type,
          label: item.label,
          answer1: item.answer1,
          answer2: item.answer2,
          response: '',
          detail: '',
          mandatory: item.mandatory,
          seq: seq,
        }
      } else if(item.type === 'selection') {
        assembled.sections[rf.key] = {
          type: item.type,
          label: item.label,
          options: item.options,
          multiple: item.multiple,
          response: '',
          mandatory: item.mandatory,
          seq: seq
        }
      } else if(item.type === 'rating') {
        assembled.sections[rf.key] = {
          type: item.type,
          label: item.label,
          num: item.num,
          response: '',
          mandatory: item.mandatory,
          seq: seq
        }
      }
      seq++
    }

    if(this.state.pre) {
      Firebase.database().ref('/spaces/'+global.uid).update({
        preExercise: obj.key
      })
    }
    
    if(this.state.id === '') {
      obj.set(
        assembled
      ).then((data)=>{
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.formcreated'));
        setTimeout(() => {
          this.props.history.push('/admin/documents')
          global.message = ''
        }, 1000);
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/forms/'+global.uid+'/'+this.state.id).set(
        assembled
      ).then((data)=>{
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.formupdated'));
        setTimeout(() => {
          this.props.history.push('/admin/documents/form/'+this.state.id)
          global.message = ''
        }, 1000);
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
    
  }



  // Interactive stuff ------------------------------------------------------------



  togglePre() {
    if(!this.state.pre && global.spacePreEx !== '') {
      this.setState({showModalMessage: true})
    }
    this.setState({
      pre: !this.state.pre
    })
  }


  createBaseElement(type) {
    var tmp = this.state.sections
    tmp.push({
      label: '',
      type: type,
      mandatory: false
    })
    this.setState({
      sections: tmp
    });
  }


  createYesNo() {
    var tmp = this.state.sections
    tmp.push({
      label: '',
      answer1: false,
      answer2: false,
      type: 'yesno',
      mandatory: false
    })
    this.setState({
      sections: tmp
    });
  }


  createSelection() {
    var tmp = this.state.sections
    tmp.push({
      label: '',
      multiple: false,
      options: [
        '',
      ],
      type: 'selection',
      mandatory: false
    })
    this.setState({
      sections: tmp
    });
  }


  createRating() {
    var tmp = this.state.sections
    tmp.push({
      label: '',
      num: 10,
      type: 'rating',
      mandatory: false
    })
    this.setState({
      sections: tmp
    });
  }


  onChange(value, index) {
    var tmp = this.state.sections
    tmp[index].label = value
    this.setState({sections: tmp});
  };


  onChangeMandatory(index) {
    var tmp = this.state.sections
    if(tmp[index].mandatory) {
      tmp[index].mandatory = false
    } else {
      tmp[index].mandatory = true
    }
    this.setState({sections: tmp});
  };


  onChangeYesNo(type, index) {
    var tmp = this.state.sections
    if(type === 'yes') {
      tmp[index].answer1 = !tmp[index].answer1
    }
    if(type === 'no') {
      tmp[index].answer2 = !tmp[index].answer2
    }
    this.setState({
      sections: tmp
    });
  }


  onChangeRating(value, index) {
    var tmp = this.state.sections
    tmp[index].num = parseInt(value)
    this.setState({
      sections: tmp
    });
  }


  addAnswer(index) {
    var tmp = this.state.sections
    tmp[index].options.push('')
    this.setState({
      sections: tmp
    });
  }


  onChangeAnswer(value, index, answer) {
    var tmp = this.state.sections
    tmp[index].options[answer] = value
    this.setState({sections: tmp});
  };


  onChangeMultiple(index) {
    var tmp = this.state.sections
    tmp[index].multiple = !tmp[index].multiple
    this.setState({
      sections: tmp
    });
  }


  deleteAnswer(index, answer) {
    var tmp = this.state.sections
    tmp[index].options.splice(answer, 1)
    this.setState({
      sections: tmp
    });
  }


  deleteElement(index) {
    var tmp = this.state.sections
    tmp.splice(index, 1)
    this.setState({
      sections: tmp
    });
  }


  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    var tmp = this.state.sections
    const res = Array.from(tmp);
    const [removed] = res.splice(result.source.index, 1);
    res.splice(result.destination.index, 0, removed);
    tmp = res

    this.setState({
      sections: tmp
    })
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalMessage: false,
      showModalSave: false,
    })
  }


  attemptSave() {
    this.hideModals()
    var unblock = this.props.history.block(() => {
      if(this.state.unsaved) {
        unblock()
        this.setState({showModalSave: true})
        return false
      } else {
        return true
      }
    });
    this.checkCreateForm()
  }


  cancelSave() {
    this.hideModals()
    this.props.history.push(ROUTES.ADMINDOC)
  }


  closeSave() {
    this.hideModals()
    var unblock = this.props.history.block(() => {
      if(this.state.unsaved) {
        unblock()
        this.setState({showModalSave: true})
        return false
      } else {
        return true
      }
    });
  }



  // Display stuff ------------------------------------------------------------



  renderElement(item, index) {
    if(item.type === 'header') {
      return (
        <div>
          <div className="sv-20"></div>
          <h3 className="lft">{lang.t('admin:form.sectionheader')}</h3>
          <button className="btn tertiary close small rgt" onClick={() => this.deleteElement(index)}>{lang.t('common:button.delete')}</button>
          <div className="clear sv-10"></div>
          <InputText label="Title*" value={item.label} error={(this.state.saving && item.label === '') ? true : false} onChange={(event) => this.onChange(event, index)}/>
        </div>
      )
    } else if(item.type === 'paragraph') {
      return (
        <div className="box">
          <h3 className="lft">{lang.t('admin:label.forms.paragraph')}*</h3>
          <button className="btn tertiary close small rgt" onClick={() => this.deleteElement(index)}>{lang.t('common:button.delete')}</button>
          <div className="clear sv-10"></div>
          <InputText label="" value={item.label} error={(this.state.saving && item.label === '') ? true : false} onChange={(event) => this.onChange(event, index)} info={lang.t('admin:form.paragraph.info')}/>
        </div>
      )
    } else if(item.type === 'text') {
      return (
        <div className="box">
          <h3 className="lft">{lang.t('admin:form.freetextquestion')}</h3>
          <button className="btn tertiary close small rgt" onClick={() => this.deleteElement(index)}>{lang.t('common:button.delete')}</button>
          <div className="clear sv-10"></div>
          <InputText label={lang.t('admin:form.question')+"*"} value={item.label} error={(this.state.saving && item.label === '') ? true : false} onChange={(event) => this.onChange(event, index)}/>
          <InputToggle label={lang.t('admin:form.mandatory')} value={item.mandatory} onChange={() => this.onChangeMandatory(index)} text={lang.t('admin:form.mandatory.text')}/>
        </div>
      )
    } else if(item.type === 'yesno') {
      return (
        <div className="box">
          <h3 className="lft">{lang.t('admin:form.yesnoquestion')}</h3>
          <button className="btn tertiary close small rgt" onClick={() => this.deleteElement(index)}>{lang.t('common:button.delete')}</button>
          <div className="clear sv-20"></div>
          <InputText label={lang.t('admin:form.question')+"*"} value={item.label} error={(this.state.saving && item.label === '') ? true : false} onChange={(event) => this.onChange(event, index)}/>
          <div className="clear sv-10"></div>
          <InputToggle label={lang.t('admin:form.yesno1')} value={item.answer1} onChange={() => this.onChangeYesNo('yes', index)} text={lang.t('admin:form.yesno1.text')}/>
          <InputToggle label='If NO, provide details' value={item.answer2} onChange={() => this.onChangeYesNo('no', index)} text={lang.t('admin:form.yesno2.text')}/>
          <InputToggle label={lang.t('admin:form.mandatory')} value={item.mandatory} onChange={() => this.onChangeMandatory(index)} text={lang.t('admin:form.mandatory.text')}/>
          <div className="clear"></div>
        </div>
      )
    } else if(item.type === 'selection') {
      return (
        <div className="box">
          <h3 className="lft">{lang.t('admin:form.selectionquestion')}</h3>
          <button className="btn tertiary close small rgt" onClick={() => this.deleteElement(index)}>{lang.t('common:button.delete')}</button>
          <div className="clear sv-20"></div>
          <InputText label={lang.t('admin:form.question')+"*"} value={item.label} error={(this.state.saving && item.label === '') ? true : false} onChange={(event) => this.onChange(event, index)}/>
          <InputToggle label={lang.t('admin:form.mandatory')} value={item.mandatory} onChange={() => this.onChangeMandatory(index)} text={lang.t('admin:form.mandatory.text')}/>
          <InputToggle label={lang.t('admin:form.multipleanswers')} value={item.multiple} onChange={() => this.onChangeMultiple(index)} text={lang.t('admin:form.multipleanswers.text')}/>

          <label>{lang.t('admin:form.answers')}</label>
          {item.options.map((item, ind) => (
            <div>
              <div className="lft" style={{width: 'calc(100% - 70px)'}}>
                <InputText label="" value={item} error={(this.state.saving && item === '') ? true : false} onChange={(event) => this.onChangeAnswer(event, index, ind)}/>
              </div>
              <button className="btn tertiary small rgt close" onClick={() => this.deleteAnswer(index, ind)}>{lang.t('common:button.delete')}</button>
              <div className="clear"></div>
            </div>
          ))}
          <button className="btn tertiary width-12" onClick={() => this.addAnswer(index)}>{lang.t('admin:button.addananswer')}</button>
        </div>
      )
    } else if(item.type === 'rating') {
      return (
        <div className="box">
          <h4 className="lft">{lang.t('admin:form.ratingquestion')}</h4>
          <button className="btn tertiary close small rgt" onClick={() => this.deleteElement(index)}>{lang.t('common:button.delete')}</button>
          <div className="clear sv-20"></div>
          <InputText label={lang.t('admin:form.question')+"*"} value={item.label} error={(this.state.saving && item.label === '') ? true : false} onChange={(event) => this.onChange(event, index)}/>
          <InputRadio
            label={lang.t('admin:form.answerratefrom')}
            value={item.num}
            clickElement={(event) => this.onChangeRating(event, index)}
            values={[{name: '1 to 3', value: 3}, {name: '1 to 5', value: 5}, {name: '1 to 10', value: 10}]}
          />
          <InputToggle label={lang.t('admin:form.mandatory')} value={item.mandatory} onChange={() => this.onChangeMandatory(index)} text={lang.t('admin:form.mandatory.text')}/>
        </div>
      )
    }
  }


  renderContent() {
    var list = this.state.sections
    return (
      <div>
        {list.map((item, index) => (
          <Draggable key={item.type+'-'+index} draggableId={item.type+'-'+index} index={index}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                {this.renderElement(item, index)}
                <div className="sv-30 clear"></div>
              </div>
            )}
          </Draggable>
        ))}
        
      </div>
    )
  }


  renderError() {
    if(this.state.error !== '') {
      return (
        <div className="inline-message red">
          <strong>{this.state.error}</strong>
        </div>
      )
    }
  }


  renderCancel() {
    if(this.state.id !== '') {
      return (
        <div>
          <div className="sv-20"></div>
          <Link to={'/admin/documents/form/'+this.state.id} className="btn tertiary close width-12">{lang.t('common:button.cancel')}</Link>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar mt-20">
            <div className="col-12">
              <InputText label="Form name" value={this.state.name} onChange={(event) => this.setState({name: event})}/>
              <div className="clear sv-10"></div>
              <InputToggle label={lang.t('admin:form.preexercise')} value={this.state.pre} onChange={() => this.togglePre()} text={lang.t('admin:form.preexercise.text')}/>
              <div className="clear sv-10"></div>
              <InputToggle label={lang.t('admin:form.clientscanedit')} value={!this.state.lock} onChange={() => this.setState({lock: !this.state.lock})} text={lang.t('admin:form.clientscanedit')}/>
              <div className="clear sv-20"></div>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId={'droppable'}>
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.renderContent()}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className="clear sv-20"></div>
              <h3>{lang.t('admin:form.add')}</h3>
              <div className="clear sv-20"></div>
              <div className="col-2">
                <button className="btn secondary small" onClick={() => this.createBaseElement('header')}>{lang.t('admin:button.sectionheader')}</button>
                <div className="sv-20"></div>
              </div>
              <div className="col-2">
                <button className="btn secondary small" onClick={() => this.createBaseElement('paragraph')}>{lang.t('admin:button.infoparagraph')}</button>
                <div className="sv-20"></div>
              </div>
              <div className="col-2">
                <button className="btn secondary small" onClick={() => this.createBaseElement('text')}>{lang.t('admin:button.freetext')}</button>
                <div className="sv-20"></div>
              </div>
              <div className="col-2">
                <button className="btn secondary small" onClick={() => this.createYesNo()}>{lang.t('admin:button.yesno')}</button>
                <div className="sv-20"></div>
              </div>
              <div className="col-2">
                <button className="btn secondary small" onClick={() => this.createSelection()}>{lang.t('admin:button.selection')}</button>
                <div className="sv-20"></div>
              </div>
              <div className="col-2">
                <button className="btn secondary small" onClick={() => this.createRating()}>{lang.t('admin:button.rating')}</button>
                <div className="sv-20"></div>
              </div>
              <div className="clear sv-40"></div>
              
              {this.renderError()}

              <div className="col-3">&nbsp;</div>
              <div className="col-6">
                <button className="btn primary" onClick={() => this.checkCreateForm()}>{this.state.id === '' ? lang.t('admin:button.createform') : lang.t('common:button.savechanges')}</button>
                {this.renderCancel()}
              </div>
              <div className="clear sv-40"></div>
            </div>
          </div>
          <Header title={this.state.id === '' ? lang.t('admin:title.newform') : lang.t('admin:title.editform')} link={(this.state.id === '' || this.state.id === 'draft') ? ROUTES.ADMINDOC : '/admin/documents/form/'+this.state.id}/>
          <Navigation active='admin' />
          <ModalMessageSimple type='preexercise' show={this.state.showModalMessage} onHide={() => this.hideModals()} clickMainButton={() => this.hideModals()}/>
          <ModalMessageDouble type='saveform' show={this.state.showModalSave} onHide={() => this.closeSave()} clickMainButton={() => this.attemptSave()} clickSecondaryButton={() => this.cancelSave()}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='forms-locked' type='simple'/>
          </div>
          <Header title={lang.t('admin:title.newform')} link={'/overview'}/>
          <Navigation active='admin' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['admin','common','header','messaging'])(withRouter(withAuthorization(condition)(NewDocumentPage)));