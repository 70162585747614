import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import InputText from '../../components/Form/input-text';
import InputFile from '../../components/Form/input-file';
import Select from '../../components/Form/select';
import InputRadio from '../../components/Form/radio';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ModalDocument extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      hidden: 'hidden',
      doc: props.doc ?? '',
      client: '',
      type: 'client',
      link: '',
      value: '',
      date: Moment().format('X'),
      error: [false, false, false],
      documents: props.documents ?? [],
      file: null,
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      show: props.show,
      doc: props.doc ?? '',
      documents: props.documents ?? []
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    var link = ''
    var val = ''
    var dat = Moment().format('X')
    var cl = ''
    if(this.state.doc !== '') {
      for(var item of this.state.documents) {
        if(item.id === this.state.doc) {
          val = item.data.name
          dat = item.data.date
          cl = item.client
          link = 'documents/'+global.uid+'/'+item.client+'/'+item.id+'.'+item.data.ext
          if(item.client === undefined) {
            cl = ''
            link = 'documents/'+global.uid+'/'+item.id+'.'+item.data.ext
          }
        }
      }
    }
    this.setState({
      show: true,
      date: dat,
      value: val,
      client: cl,
      link: link,
      error: [false, false, false]
    })
    if(link !== "") {
      Firebase.storage().ref().child(link).getDownloadURL().then((url) => {
        this.setState({
          link: url
        })  
      })
    }
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  setDocument(file) {
    var tmp = this.state.error
    tmp[1] = false
    if(file.size > 1000000) {
      tmp[1] = true
    }
    this.setState({
      file: file,
      date: Moment().format('X'),
      error: tmp
    })
  }


  uploadDocument() {
    var tmp = this.state.error
    tmp[0] = false
    tmp[2] = false
    if(this.state.value === '') {
      tmp[0] = true
    }
    if(this.state.client === '' && this.state.type === 'client') {
      tmp[2] = true
    }
    this.setState({
      error: tmp
    })
    if(!tmp[0] && !tmp[1] && !tmp[2]) {
      this.hide()
      this.props.clickUpload([this.state.type, this.state.value, this.state.file, this.state.client])
    }
  }

  updateDocument() {
    var tmp = this.state.error
    tmp[0] = false
    if(this.state.value === '') {
      tmp[0] = true
    }
    this.setState({
      error: tmp
    })
    if(!tmp[0]) {
      this.hide()
      this.props.clickUpdate([this.state.value, this.state.client])
    }
  }


  deleteDocument() {
    this.hide()
    this.props.clickDelete(this.state.client)
  }


  renderClientName(id, type) {
    var label = ''
    for(var item1 of global.clients) {
      if(item1.id === id) {
        label = item1.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    if(type === 'public') {
      label = lang.t('modals:docuemnt.publiclibraryfile')
    }
    return label
  }


  renderDocType() {
    if(this.state.client !== '') {
      return (
        <div>
          <label>Client</label>
          <p>{this.renderClientName(this.state.client)}</p>
        </div>
      )
    }
  }


  renderDocumentClient() {
    if(this.state.type === 'client') {
      var list = [{value: '', name: '- '+lang.t('modals:document.selectaclient')+' -'}]
      for(var item of global.clients) {
        list.push({value: item.id, name: item.data.name})
      }
      return <Select label={lang.t('modals:document.client.label')} value={this.state.client} values={list} error={this.state.error[2]} errormsg={lang.t('modals:document.selectaclient')} onChange={(event) => this.setState({client: event})}/>
    } else {
      return (
        <div className="form-item">
          <p className="small" style={{padding: 0}}>{lang.t('modals:document.info2')}</p>
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      if(this.state.doc === '') {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modals:document.title.upload')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
              <p className="clear mb-30">{lang.t('modals:document.info1')}</p>
              <InputRadio
                label=''
                value={this.state.type}
                clickElement={(event) => this.setState({type: event})}
                values={[{name: lang.t('modals:document.radio.label1'), value: 'client'}, {name: lang.t('modals:document.radio.label2'), value: 'public'}]}
              />
              {this.renderDocumentClient()}
              <InputText label={lang.t('modals:document.name.label')} value={this.state.value} error={this.state.error[0]} errormsg={lang.t('modals:document.name.error')} onChange={(event) => this.setState({value: event})}/>
              <InputFile label='File' error={this.state.error[1]} errormsg={lang.t('modals:document.file.error')} onChange={(event) => this.setDocument(event)}/>
              <label>{lang.t('modals:document.file.label')}</label>
              <input type="file" onChange={(e: any) => {this.setDocument(e.target.files[0]);}}/>
              <div className="sv-40"></div>
              <button className="btn primary" onClick={() => this.uploadDocument()}>{lang.t('modals:document.button.upload')}</button>
            </div>
          </div>
        )
      } else {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modals:document.title')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
              <div className="clear"></div>
              {this.renderDocType()}
              <InputText label={lang.t('modals:document.name.label')} value={this.state.value} error={this.state.error[0]} errormsg={lang.t('modals:document.name.error')} onChange={(event) => this.setState({value: event})}/>
              <button className="btn tertiary small" onClick={() => this.updateDocument()} style={{marginTop: 5}}>{lang.t('modals:document.button.update')}</button>
              <div className="clear sv-40"></div>
              <a href={this.state.link} target="_blank" rel="noopener noreferrer" className="btn primary">{lang.t('modals:document.button.viewdocument')}</a>
              <div className="sv-20"></div>
              <button className="btn tertiary width-12" onClick={() => this.deleteDocument()}>{lang.t('modals:document.button.delete')}</button>
            </div>
          </div>
        )
      }
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalDocument);